import { httpHelper } from '../../../common/HttpCommon'
import { RETURNING_REQUESTS_ROUTE } from '../../../common/RouteConstants'
import { ReturnMethod } from '../../data/Returning'
import {
    IReturningRequest,
    ReturningRequest,
} from '../../requests/data/ReturningRequest'
import { ReturningRequestService } from '../ReturningRequestService'

export class ReturningRequestServiceImpl implements ReturningRequestService {
    async approveRequest(requestId: string): Promise<IReturningRequest> {
        const request = await httpHelper.post<IReturningRequest>(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/approve`
        )
        return new ReturningRequest(request)
    }
    rejectRequest(
        requestId: string,
        rejectDescription: string
    ): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/reject`,
            { rejectDescription }
        )
    }
    confirmReturn(requestId: string): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/confirm-return`
        )
    }
    rejectReturn(
        requestId: string,
        rejectDescription: string
    ): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/reject-return`,
            { rejectDescription }
        )
    }
    confirmReturnMethod(
        requestId: string,
        returnMethod: ReturnMethod
    ): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/confirm-return-method`,
            { returnMethod }
        )
    }
    confirmRepair(requestId: string): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/confirm-repair`
        )
    }
    rejectRepair(requestId: string): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/reject-repair`
        )
    }
    chargeRefundFromSeller(requestId: string): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/refund/charge-from-seller`
        )
    }
    completeRequest(requestId: string): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/complete`
        )
    }

    async findRequest(requestId: string): Promise<IReturningRequest> {
        const request = await httpHelper.get<IReturningRequest>(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}?includeUserInfo=true&includeRefundInfo=true`
        )
        return new ReturningRequest(request)
    }

    async confirmBuyerShipmentDetails(
        requestId: string,
        addressId: string,
        deliveryOptionId: string,
        userId: string
    ): Promise<IReturningRequest> {
        return httpHelper.post(
            `${RETURNING_REQUESTS_ROUTE}/${requestId}/status/confirm-buyer-shipment-details`,
            { addressId, deliveryOptionId, userId }
        )
    }
}
