import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
interface CompleteRequestButtonProps {
    request: IReturningRequest
}

export const ChargeRefundFromSellerButton = ({
    request,
}: CompleteRequestButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function chargeFromSeller() {
        try {
            const response =
                await returningRequestService.chargeRefundFromSeller(
                    request.requestId
                )
            if (response) {
                success('Charged from seller successfully!')
                window.setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } else {
                danger('Error: could not complete the request!')
            }
        } catch (e: any) {
            danger(`Error: ${e.response.data.message}`)
        }
    }

    const sellerTotal = request.refund!!.sellerTotal
    const sellerTotalFormatted = sellerTotal.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
    })

    return (
        <ApproveActionButton
            buttonText="Charge Seller"
            modalTitle={`You are about to charge ${sellerTotalFormatted} from the seller`}
            modalText={`Are you sure you want to charge ${sellerTotalFormatted} from seller? We will remove the money from the seller's wallet and add it to Doji wallet.`}
            action={chargeFromSeller}
        />
    )
}
