import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import React from 'react'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import {
    CURRENCY_TRANSACTIONS_ROUTE,
    PRODUCT_TRANSACTIONS_ROUTE,
} from '../../../common/RouteConstants'
import Moment from 'react-moment'
import CurrencyTransaction from '../../data/CurrencyTransaction'
import ProductTransaction from '../../data/ProductTransaction'

export function TransactionsView(sale: SpecificSale): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    const createdColumn = (data: CurrencyTransaction | ProductTransaction) => {
        return (
            <div>
                <span className="text-muted fw-bold d-block fs-8">
                    Created at
                </span>
                <span className="text-dark fw-bolder d-block fs-7">
                    <Moment format="YYYY-MM-DD HH:mm">{data.created}</Moment>
                </span>
            </div>
        )
    }

    const idColumn = (data: CurrencyTransaction | ProductTransaction) => {
        return <span>{data.id}</span>
    }

    const userColumn = (data: CurrencyTransaction | ProductTransaction) => {
        return <div>{data.userId}</div>
    }
    const lockSourceIdColumn = (
        data: CurrencyTransaction | ProductTransaction
    ) => {
        return <div>{data.lockSourceId}</div>
    }
    const sourceTypeColumn = (
        data: CurrencyTransaction | ProductTransaction
    ) => {
        return <div>{data.sourceType}</div>
    }
    const IOColumn = (data: CurrencyTransaction | ProductTransaction) => {
        return <div>{data.transactionIO}</div>
    }
    const currencyColumn = (data: CurrencyTransaction) => {
        return <div>{data.currency}</div>
    }
    const amountColumn = (data: CurrencyTransaction | ProductTransaction) => {
        return <div>{data.amount}</div>
    }
    const descriptionColumn = (
        data: CurrencyTransaction | ProductTransaction
    ) => {
        return <div>{data.description}</div>
    }
    const productIdColumn = (data: ProductTransaction) => {
        return <div>{data.productId}</div>
    }

    const columnsToCurrencyTransactions = [
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Id',
            render: idColumn,
        },
        {
            header: 'User',
            render: userColumn,
        },
        {
            header: 'Source Type',
            render: sourceTypeColumn,
        },
        {
            header: 'Lock Source Id',
            render: lockSourceIdColumn,
        },
        {
            header: 'IO',
            render: IOColumn,
        },
        {
            header: 'Currency',
            render: currencyColumn,
        },
        {
            header: 'amount',
            render: amountColumn,
        },
        {
            header: 'Description',
            render: descriptionColumn,
        },
    ]

    const columnsToProductTransactions = [
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Id',
            render: idColumn,
        },
        {
            header: 'User',
            render: userColumn,
        },
        {
            header: 'Source Type',
            render: sourceTypeColumn,
        },
        {
            header: 'Lock Source Id',
            render: lockSourceIdColumn,
        },
        {
            header: 'IO',
            render: IOColumn,
        },
        {
            header: 'Product Id',
            render: productIdColumn,
        },
        {
            header: 'amount',
            render: amountColumn,
        },
        {
            header: 'Description',
            render: descriptionColumn,
        },
    ]

    renderRows.push(
        {
            column: [
                {
                    title: '',
                    content: (
                        <DynamicTable
                            title={'Currency Transactions'}
                            description={'List of Currency Transactions'}
                            resource={`${CURRENCY_TRANSACTIONS_ROUTE}/?page=1&pageSize=25&source=admin&sourceId=${sale.saleId}`}
                            columns={columnsToCurrencyTransactions}
                            filters={[]}
                        />
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: '',
                    content: (
                        <DynamicTable
                            title={'Product Transactions'}
                            description={'List of Product Transactions'}
                            resource={`${PRODUCT_TRANSACTIONS_ROUTE}/?page=1&pageSize=25&source=admin&sourceId=${sale.saleId}`}
                            columns={columnsToProductTransactions}
                            filters={[]}
                        />
                    ),
                },
            ],
        }
    )
    return renderRows
}
