import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { BooleanField } from '../../../common/components/fields/BooleanField'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { PAYMENT_METHODS_ROUTE } from '../../../common/RouteConstants'
import { PaymentMethod } from '../../data/PaymentMethod'
import IUser from '../../data/User'

const createdColumn = (data: PaymentMethod) => {
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}

const idColumn = (data: PaymentMethod) => {
    return (
        <>
            <Link to={`#`}>
                <span
                    className="fw-bold"
                    data-bs-toggle="tooltip"
                    data-bs-delay-hide="5000"
                    data-bs-placement="top"
                    title={data.paymentMethodId}
                >
                    {data?.paymentMethodId?.substr(0, 8)}..
                </span>
            </Link>
        </>
    )
}
const providerColumn = (data: PaymentMethod) => {
    return (
        <span className="text-dark fw-bolder d-block fs-7">
            {data.provider}
        </span>
    )
}
const typeColumn = (data: PaymentMethod) => {
    return <span className="text-dark fw-bolder d-block fs-7">{data.type}</span>
}

const cardColumn = (data: PaymentMethod) => {
    if (!data.card) return <></>
    return (
        <>
            <span className="text-dark fw-bolder d-block fs-7">
                {data.card.cardType}
            </span>
            <span className="text-muted fw-bold d-block fs-8">
                {data.card.maskedNumber}
            </span>
            <span className="text-muted fw-bold d-block fs-8">
                {`Exp: ${data.card.expirationMonth.length === 1 ? '0' : ''}${
                    data.card.expirationMonth
                }/${data.card.expirationYear}`}
            </span>
        </>
    )
}

const authenticatedColumn = (data: PaymentMethod) => {
    return <BooleanField condition={data.authenticated} />
}
const mainColumn = (data: PaymentMethod) => {
    return <BooleanField condition={data.main} />
}

const columns = [
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Type',
        render: typeColumn,
    },
    {
        header: 'Provider',
        render: providerColumn,
    },
    {
        header: 'Authenticated',
        render: authenticatedColumn,
    },
    {
        header: 'Card',
        render: cardColumn,
    },
    {
        header: 'Main',
        render: mainColumn,
    },
]

export function PaymentMethodsView(
    user: IUser,
    currentScreen: string
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title="Payment methods"
                        description="List of payment methods"
                        resource={`${PAYMENT_METHODS_ROUTE}/?userId=${user?.userId}`}
                        columns={columns}
                        filters={[]}
                        currentScreen={currentScreen}
                    />
                ),
            },
        ],
    })
    return renderRows
}
