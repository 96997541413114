import SpecificEvent from './SpecificEvent'

export function menuRules(
    event: SpecificEvent,
    pathname: string,
    isEditView: boolean
) {
    let list = []
    list.push({ title: 'SUMMARY', to: `${pathname}/summary` })

    if (event === null || event === undefined) return list

    list.push({ title: 'NOTIFICATION', to: `${pathname}/notification` })
    list.push({ title: 'PUSH', to: `${pathname}/push` })
    list.push({ title: 'EMAIL', to: `${pathname}/email` })
    list.push({ title: 'SMS', to: `${pathname}/sms` })

    return list
}
