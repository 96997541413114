import Moment from 'react-moment'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { ADDRESSES_ROUTE } from '../../../common/RouteConstants'
import { Address } from '../../data/Address'
import IUser from '../../data/User'

const createdColumn = (data: Address) => {
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}

const nameColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.name}</span>
)
const postCodeColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.postCode}</span>
)
const countryColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.country}</span>
)
const addressColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.address}</span>
)
const numberColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.number}</span>
)
const addressLine2Column = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">
        {data.addressLine2}
    </span>
)
const cityColumn = (data: Address) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.city}</span>
)

const columns = [
    {
        header: 'Name',
        render: nameColumn,
    },
    {
        header: 'Post Code',
        render: postCodeColumn,
    },
    {
        header: 'Country',
        render: countryColumn,
    },
    {
        header: 'Address',
        render: addressColumn,
    },
    {
        header: 'Number',
        render: numberColumn,
    },
    {
        header: 'Address line 2',
        render: addressLine2Column,
    },
    {
        header: 'City',
        render: cityColumn,
    },
    {
        header: 'Created',
        render: createdColumn,
    },
]

export function AddressesView(
    user: IUser,
    currentScreen: string
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title="Addresses"
                        description="List of addresses"
                        resource={`${ADDRESSES_ROUTE}/?userId=${user?.userId}`}
                        columns={columns}
                        filters={[]}
                        currentScreen={currentScreen}
                    />
                ),
            },
        ],
    })
    return renderRows
}
