import Sale from '../../../data/Sale'
import SpecificSale from '../../../data/SpecificSale'
import { httpHelper } from '../../../../common/HttpCommon'
import { SHIPMENT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { isMarkedSellerDelivered } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'

const SellerToDeliveredButton = (props: {
    sale: Sale | SpecificSale
    title?: string
}) => {
    const sale = props.sale
    const title = props.title
    const { success, danger } = useAlert()

    if (!isMarkedSellerDelivered(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={() => {
                    markAsDelivered()
                }}
            >
                <KTSVG
                    path={'/media/icons/duotune/ecommerce/ecm006.svg'}
                    className="svg-icon-1 me-1"
                />
                {title || 'Mark as Delivered'}
            </div>
        </div>
    )

    async function markAsDelivered() {
        let trackingNumber = ''

        if (sale.sellerShipmentDetails?.postLocationDetails)
            trackingNumber =
                sale.sellerShipmentDetails.postLocationDetails.shipmentId

        await httpHelper
            .put(`${SHIPMENT_ROUTE}/${trackingNumber}/status?status=DELIVERED`)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: Not marked as delivered!')
                else {
                    success('Shipment mark as delivered!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default SellerToDeliveredButton
