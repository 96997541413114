import { CustomRow, MenuItem } from '../data/CardUtil'
import { Link } from 'react-router-dom'
import React, { ReactElement } from 'react'
import './style/dynamic-card.css'

type Props = {
    title: string
    subtitle?: string
    menuItems?: Array<MenuItem>
    buttons?: Array<ReactElement>
    rows: Array<CustomRow>
    address?: string
    loading?: boolean
}
let buttonCss = 'buttons-component'
let menuItemsCss = 'menu-component'

function menuItemsComponent(props: Props) {
    if (!props.menuItems) return <></>

    return (
        <div
            className={`d-flex overflow-auto h-30px float-left ${menuItemsCss}`}
        >
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                {props.menuItems.map((menuItem) => {
                    const addressPage = menuItem.to.split('/')
                    const currentSubMenu =
                        addressPage[addressPage.length - 1].toString()
                    const className =
                        currentSubMenu === props.address
                            ? `nav-link text-active-primary me-6 active`
                            : `nav-link text-active-primary me-6`

                    return (
                        <li className="nav-item">
                            <Link className={className} to={menuItem.to}>
                                {menuItem.title}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

function renderButtons(props: Props) {
    if (!props?.buttons?.length) return <></>

    return (
        <div className={`card-toolbar position-relative ${buttonCss}`}>
            <Link
                to="#"
                className="btn btn-bg-white btn-sm show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
            >
                <span>
                    <i className="bi bi-three-dots fs-1" />
                </span>
            </Link>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                data-kt-menu="true"
            >
                {props.buttons.map((bt) => bt)}
            </div>
        </div>
    )
}

function renderRows(props: Props) {
    return props.rows.map((row) => {
        return !row?.column?.length ? (
            <></>
        ) : (
            <div className="row mb-7">
                <div className="d-flex flex-row align-items-center">
                    {row.column.map((column) => {
                        return (
                            <div
                                className={
                                    row.column.length === 1
                                        ? 'col-mb-7'
                                        : 'col-md-6'
                                }
                            >
                                <label
                                    className="col-md-3 fw-bold text-muted"
                                    style={{ float: 'left' }}
                                >
                                    {column.title}
                                </label>
                                {column.content instanceof String ? (
                                    <span
                                        className="col-md-9 fw-bolder fs-6 text-dark"
                                        style={{ float: 'left' }}
                                    >
                                        {column.content}
                                    </span>
                                ) : column.content === undefined ? (
                                    ''
                                ) : (
                                    column.content
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    })
}

export function DynamicCard(props: Props) {
    if (props.menuItems) configCss(props.menuItems?.length)
    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column mt-7 m-2 h-50px">
                        <span className="card-label fw-bolder fs-3 mb-1">
                            {props.title}
                        </span>
                        <span className="text-muted mt-1 fw-bold fs-7">
                            {props.subtitle}
                        </span>
                    </h3>
                    {renderButtons(props)}
                    <div className="card-body pt-9 pb-0">
                        {menuItemsComponent(props)}
                    </div>
                </div>

                <div className="card-body pt-9 pb-0">
                    <div className="flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-7 mb-4">
                            <div className="symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <div
                                    className="card mb-5 mb-xl-10"
                                    id="kt_profile_details_view"
                                >
                                    {props.loading ? (
                                        <div
                                            className="d-flex justify-content-center text-primary align-items-center"
                                            style={{ height: '300px' }}
                                        >
                                            <div
                                                className="spinner-border"
                                                role="status"
                                            />
                                        </div>
                                    ) : (
                                        <div className="card-body p-9">
                                            {renderRows(props)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function configCss(sizeItems: number) {
    if (sizeItems > 4 && sizeItems <= 7) {
        buttonCss = `buttons-component-${sizeItems}items`
        menuItemsCss = `menu-component-${sizeItems}items`
    }
}
