import { CustomRow } from '../../../../common/components/data/CardUtil'
import { BoldTextField } from '../../../../common/components/fields/BoldTextField'
import { DateField } from '../../../../common/components/fields/DateField'
import { TextField } from '../../../../common/components/fields/TextField'
import { ReturnTypeBadge } from '../../../component/badge/ReturnTypeBadge'
import { ReturningRequest } from '../../data/ReturningRequest'
import { RequestStatusBadge } from '../../../component/badge/RequestStatusBadge'
import { ReturnMethodBadge } from '../../../component/badge/ReturnMethodBadge'
import { CardLinkField } from '../../../../common/components/card/CardLinkField'
import { BooleanField } from '../../../../common/components/fields/BooleanField'

export function SummaryView(request: ReturningRequest): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []
    const address = request.buyerShipmentDetails?.address
        ? `${request.buyerShipmentDetails.address.street}, ${request.buyerShipmentDetails.address.postCode}, ${request.buyerShipmentDetails.address.countryCode}`
        : '-'

    renderRows.push(
        {
            column: [
                {
                    title: 'Request ID',
                    content: <BoldTextField>{request.requestId}</BoldTextField>,
                },
                {
                    title: 'Status',
                    content: (
                        <RequestStatusBadge status={request.currentStatus} />
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Created',
                    content: <DateField date={request.created} />,
                },
                {
                    title: 'Updated',
                    content: <DateField date={request.updated} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Reason',
                    content: (
                        <TextField>{request.returningReason.title}</TextField>
                    ),
                },
                {
                    title: 'Return Method',
                    content: (
                        <ReturnMethodBadge
                            returnMethod={request.returnMethod}
                        />
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Description',
                    content: <TextField>{request.textDescription}</TextField>,
                },
                {
                    title: 'User',
                    content: (
                        <CardLinkField
                            to={`/user/${request.userId}/summary`}
                        >{`${request.user.firstName} ${request.user.lastName}`}</CardLinkField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Type',
                    content: <ReturnTypeBadge type={request.type} />,
                },
                {
                    title: 'Sale ID',
                    content: (
                        <CardLinkField to={`/sale/${request.saleId}/summary`}>
                            {request.saleId}
                        </CardLinkField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Shipping Address',
                    content: <TextField>{address}</TextField>,
                },
                {
                    title: 'Confirmed Address',
                    content: (
                        <BooleanField
                            condition={request.userHasConfirmedAddress()}
                        />
                    ),
                },
            ],
        },
        {
            column: [
                request.rejectDetails
                    ? {
                          title: 'Reject Description',
                          content: (
                              <TextField>
                                  {request.rejectDetails.description}
                              </TextField>
                          ),
                      }
                    : { title: '', content: <div /> },
                { title: '', content: <></> },
            ],
        }
    )
    return renderRows
}
