import { CustomRow } from '../../../common/components/data/CardUtil'
import { IProduct } from '../../data/Product'
import { MediasView } from './MediasView'
import { OrdersView } from './OrdersView'
import { SalesView } from './SalesView'
import { SummaryView } from './SummaryView'

export const getRows = (
    currentPage: string,
    product?: IProduct
): CustomRow[] => {
    let rows: CustomRow[] = []
    if (!product) return rows
    switch (currentPage) {
        case 'summary':
            return SummaryView(product)
        case 'medias':
            return MediasView(product)
        case 'orders':
            return OrdersView(product)
        case 'sales':
            return SalesView(product)
    }
    return rows
}
