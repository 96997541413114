import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
interface ConfirmRepairButtonProps {
    request: IReturningRequest
}

export const ConfirmRepairButton = ({ request }: ConfirmRepairButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function confirm() {
        const response = await returningRequestService.confirmRepair(
            request.requestId
        )
        if (response) {
            success('repair confirmed!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not confirm the repair!')
        }
    }

    return (
        <ApproveActionButton
            buttonText="Confirm repair"
            modalTitle="You are about to confirm a Repair"
            modalText="Are you sure you want to confirm the repair?"
            action={confirm}
        />
    )
}
