export const LOGIN_ROUTE = '/public/v1/backoffice-login'
export const DASHBOARD_ROUTE = '/public/v1/dashboard'
export const SALE_ROUTE = 'admin/v1/sales'
export const SALE_ROUTE_V2 = 'admin/v2/sales'
export const LOST_PARCELS_ROUTE_V2 = 'admin/v1/sales/lost-parcel-warning'
export const RETURNING_ROUTE = 'admin/v1/returning/reasons'
export const RETURNING_REQUESTS_ROUTE = 'admin/v1/returning/requests'
export const CURRENCY_TRANSACTIONS_ROUTE = '/admin/v1/currency-transactions'
export const PRODUCT_TRANSACTIONS_ROUTE = '/admin/v1/product-transactions'
export const ORDER_ROUTE = '/admin/v1/orders'
export const USER_ROUTE = '/admin/v1/users'
export const ORDER_PERMISSION = 'ORDERS'
export const SALE_PERMISSION = 'SALES'
export const USER_PERMISSION = 'USERS'
export const EVENTS_PERMISSION = 'EVENTS'
export const STATEMENTS_PERMISSION = 'STATEMENTS'
export const PLATFORM_BALANCES_PERMISSION = 'PLATFORM-BALANCES'
export const PRODUCT_PERMISSION = 'PRODCUTS'
export const SPECIFIC_SALE = '/sales/:id/show'
export const DECOUPLE_ROUTE = '/admin/v1/decouple'
export const DECOUPLE_PRICES_ROUTE = '/admin/v1/decouple/prices'
export const APPROVAL_PAYMENT_ROUTE = '/admin/v1/decouple/approvals'
export const GRADE_ACCEPTANCE_ROUTE = '/admin/v1/sales/grade-acceptance'
export const SALE_REPORT_ROUTE = '/admin/v1/reports/sale'
export const SHIPMENT_ROUTE = '/admin/v1/shipping/shipments'
export const SHIPMENT_V2_ROUTE = '/admin/v2/shipping/shipments'
export const EVENT_ROUTE = '/admin/v1/event-configurations'
export const STATEMENT_ROUTE = '/admin/v1/statements'
export const UPDATE_PRODUCT_ROUTE = '/admin/v1/sales/update-product'
export const PLATFORM_BALANCES_ROUTE = '/admin/v1/platform-balances'
export const STATEMENT_DOWNLOAD_ROUTE = '/admin/v1/statements/reports'
export const SALE_CHANGES_HISTORY_ROUTE = '/admin/v1/sales/change-history'
export const PAYMENT_METHODS_ROUTE = '/admin/v1/payment-methods'
export const WALLETS_ROUTE = '/admin/v1/currency-wallets'
export const WITHDRAWALS_ROUTE = '/admin/v1/withdrawals'
export const ADDRESSES_ROUTE = '/admin/v1/addresses'
export const IMPORT_ORDERS_ROUTE = 'admin/v1/orders/import'
export const PRODUCTS_ROUTE = '/admin/v1/products'
export const BACKOFFICE_ROUTE = '/admin/v1/backoffice'
export const FRAUD_ANALYSIS_BASE_ROUTE = '/admin/v1/fraud-analysis/analysis'
export const PAYMENTS_ROUTE = '/admin/v1/payments'
