import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import React from 'react'
import { Link } from 'react-router-dom'
import { CardLinkField } from '../../../common/components/card/CardLinkField'
import { getSiteUrl } from '../../../Env'

const siteUrl = getSiteUrl()

const imageProductRow = (data: SpecificSale) => {
    return (
        <div className="col-md-5">
            <label className="col-md-3 ">
                <figure>
                    <img
                        alt="Product detail"
                        src={data.productDetail?.image}
                        width="200px"
                    />
                </figure>
            </label>
        </div>
    )
}
const ProductRow = (data: SpecificSale) => {
    return (
        <div className="col-md-10 ">
            <div className="row mb-7">
                <div className="col-md-12 ">
                    <label className="col-md-3 fw-bold text-muted">
                        ProductId
                    </label>
                    <span className="col-md-12 fw-bolder fs-6 text-dark">
                        <Link to={`/product/${data.productId}/summary`}>
                            {data.productId}
                        </Link>
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <div className="col-md-12 ">
                    <label className="col-md-3 fw-bold text-muted">
                        Link to VIP
                    </label>
                    <span className="col-md-12 fw-bolder fs-6 text-dark">
                        <CardLinkField
                            to={{
                                pathname: `${siteUrl}/product/${data.productDetail?.optimisedUrl}`,
                            }}
                        >
                            {data.productDetail?.optimisedUrl}
                        </CardLinkField>
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <div className="col-md-12 ">
                    <label className="col-md-3 fw-bold text-muted">Title</label>
                    <span className="col-md-12 fw-bolder fs-6 text-dark">
                        {data.productDetail?.title}
                    </span>
                </div>
            </div>

            <div className="row-md-7">
                <div className="col-md-12 ">
                    <label className="col-md-3 fw-bold text-muted">
                        Condition
                    </label>
                    <span className="col-md-12 fw-bolder fs-6 text-dark">
                        {data.productDetail?.grade.description}
                    </span>
                </div>
            </div>
        </div>
    )
}

export function ProductView(sale: SpecificSale): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: imageProductRow(sale),
            },
            {
                title: '',
                content: ProductRow(sale),
            },
        ],
    })
    return renderRows
}
