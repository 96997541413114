import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { saleColumns } from '../../../sale/component/Sales'
import { IProduct } from '../../data/Product'
import { filters } from '../../../sale/component/Sales'

export const SalesView = (product: IProduct): CustomRow[] => {
    const rows: CustomRow[] = [
        {
            column: [
                {
                    title: '',
                    content: (
                        <DynamicTable
                            description={'List of sales'}
                            columns={saleColumns}
                            resource={`admin/v2/sales?productId=${product.productId}`}
                            filters={filters}
                        />
                    ),
                },
            ],
        },
    ]
    return rows
}
