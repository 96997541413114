import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
interface ApproveRequestButtonProps {
    request: IReturningRequest
}

export const ApproveRequestButton = ({
    request,
}: ApproveRequestButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function approve() {
        const response = await returningRequestService.approveRequest(
            request.requestId
        )
        if (response) {
            success('Request approved!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not approve the request!')
        }
    }

    return (
        <ApproveActionButton
            action={approve}
            buttonText="Approve"
            modalText="Are you sure you want to approve this request?"
            modalTitle="You are about to approve a Return Request"
        />
    )
}
