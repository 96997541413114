import React, { useEffect, useState } from 'react'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { httpHelper } from '../../common/HttpCommon'
import { DECOUPLE_ROUTE, SALE_ROUTE } from '../../common/RouteConstants'
import Decouple from '../data/Decouple'
import { CustomRow } from '../../common/components/data/CardUtil'
import { Link } from 'react-router-dom'
import { DataField } from '../../common/components/fields/DataField'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import { Type } from '../../common/components/table/DynamicFilter'
import Sale from '../../sale/data/Sale'
import Moment from 'react-moment'
import { InstitutionalComponent } from '../../order/component/label/InstitutionalComponent'
import { SellerStatus } from '../../sale/component/label/SellerStatus'
import { BuyerStatus } from '../../sale/component/label/BuyerStatus'
import { ProductCondition } from '../../sale/component/label/Condition'
import DecoupleButton from '../../sale/component/buttons/DecoupleButton'
import CancelButton from '../../sale/component/buttons/CancelButton'
import AcceptGradeButton from '../../sale/component/buttons/AcceptGradeButton'
import DownloadSellerLabel from '../../sale/component/buttons/seller/DownloadSellerLabel'
import DownloadBuyerLabel from '../../sale/component/buttons/buyer/DownloadBuyerLabel'
import BuyerToCollectedButton from '../../sale/component/buttons/buyer/BuyerToCollectedButton'
import BuyerToDeliveredButton from '../../sale/component/buttons/buyer/BuyerToDeliveredButton'
import SellerToCollectedButton from '../../sale/component/buttons/seller/SellerToCollectedButton'
import SellerToDeliveredButton from '../../sale/component/buttons/seller/SellerToDeliveredButton'
import SellerToFailedButton from '../../sale/component/buttons/seller/SellerToFailedButton'

export function DecoupleView() {
    const [decouple, setDecouple] = useState<Decouple[]>(Array<Decouple>())

    async function fetchData() {
        // TODO: move this api call to a service
        await httpHelper
            .get<Decouple>(`${DECOUPLE_ROUTE}/${decoupleId}`)
            .then((resp) => {
                setDecouple([resp])
            })
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    const addressPage = window.location.pathname.split('/')
    const decoupleId = addressPage[2]

    const rows = getRows(decouple[0])

    const createdColumn = (data: Sale) => {
        return (
            <div>
                <span className="text-muted fw-bold d-block fs-8">
                    Created at
                </span>
                <span className="text-dark fw-bolder d-block fs-7">
                    <Moment format="YYYY-MM-DD HH:mm">{data.created}</Moment>
                </span>
            </div>
        )
    }

    const idColumn = (data: Sale) => {
        return (
            <>
                <Link to={`/sale/${data.saleId}/summary`}>
                    <span
                        className="fw-bold"
                        data-bs-toggle="tooltip"
                        data-bs-delay-hide="5000"
                        data-bs-placement="top"
                        title={data.saleId}
                    >
                        {data.saleId.substr(0, 8)}..
                    </span>
                </Link>
            </>
        )
    }

    const sellerColumn = (data: Sale) => {
        return (
            <div>
                <Link
                    to="#"
                    className="text-dark fw-bolder text-hover-primary d-block fs-6"
                >
                    {data.sellerDetail.fullName}
                    {InstitutionalComponent(data.isFromInstitutional)}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    {data.sellerDetail.email}
                </span>
                <SellerStatus status={data.sellerStatus} />
            </div>
        )
    }

    const buyerColumn = (data: Sale) => {
        return (
            <div>
                <Link
                    to="#"
                    className="text-dark fw-bolder text-hover-primary d-block fs-6"
                >
                    {data.buyerDetail.fullName}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    {data.buyerDetail.email}
                </span>
                <BuyerStatus status={data.buyerStatus} />
            </div>
        )
    }

    const productColumn = (data: Sale) => {
        return (
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px me-5">
                    <img src={data.productDetail.thumbnail.url} alt="" />
                </div>
                <div className="d-flex justify-content-start flex-column">
                    <Link
                        to="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                    >
                        {data.productDetail.title}
                    </Link>
                    <span className="text-muted fw-bold text-muted d-block fs-7">
                        <ProductCondition
                            status={data.productDetail?.grade.description}
                        />
                    </span>
                    <span className="text-dark fw-bolder fw-bold d-block fs-6">
                        {data.price}
                        <span className="text-muted fw-bold">
                            {' '}
                            {data.currency}
                        </span>
                    </span>
                </div>
            </div>
        )
    }
    const actionColumn = (data: Sale) => {
        return (
            <>
                <Link
                    to={'#'}
                    className="btn btn-bg-white btn-sm show menu-dropdown"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                >
                    <span>
                        <i className="bi bi-three-dots fs-5" />
                    </span>
                </Link>
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4"
                    data-kt-menu="true"
                >
                    <DecoupleButton sale={data} />
                    <CancelButton sale={data} />
                    <AcceptGradeButton sale={data} />
                    <DownloadSellerLabel sale={data} />
                    <DownloadBuyerLabel sale={data} />
                    <BuyerToCollectedButton
                        sale={data}
                        title={'Mark Buyer as Collected'}
                    />
                    <BuyerToDeliveredButton
                        sale={data}
                        title={'Mark Buyer as Delivered'}
                    />
                    <SellerToCollectedButton
                        sale={data}
                        title={'Mark Seller as Collected'}
                    />
                    <SellerToDeliveredButton
                        sale={data}
                        title={'Mark Seller as Delivered'}
                    />
                    <SellerToFailedButton sale={data} />
                </div>
            </>
        )
    }

    const columns = [
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Id',
            render: idColumn,
        },
        {
            header: 'Seller',
            render: sellerColumn,
        },
        {
            header: 'Buyer',
            render: buyerColumn,
        },
        {
            header: 'Product',
            render: productColumn,
        },
        {
            header: 'Action',
            render: actionColumn,
        },
    ]

    const filters = [
        {
            title: '',
            queryParam: '',
            type: Type.ENUM,
        },
    ]

    return (
        <>
            <DynamicCard
                title={`Decouple`}
                subtitle={`#${decoupleId}`}
                rows={rows}
                address={'Show'}
            />
            <DynamicTable
                title={'Sale'}
                description={'List of sales'}
                resource={`${SALE_ROUTE}/?page=1&pageSize=25&source=admin&decoupleId=${decoupleId}`}
                columns={columns}
                filters={filters}
            />
        </>
    )

    function getRows(decouple: Decouple) {
        let renderRows: Array<CustomRow> = []

        if (decouple === null || decouple === undefined) return renderRows

        renderRows.push(
            {
                column: [
                    {
                        title: 'Created',
                        content: DataField(decouple.created),
                    },
                    {
                        title: 'Updated',
                        content: DataField(decouple.updated),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Seller Sale',
                        content: (
                            <Link
                                to={`/sale/${decouple.newSellerSaleId}/summary`}
                                className="text-dark fw-bolder text-hover-primary "
                            >
                                {' '}
                                {decouple.newSellerSaleId}{' '}
                            </Link>
                        ),
                    },
                    {
                        title: 'Buyer Sale',
                        content: (
                            <Link
                                to={`/sale/${decouple.newBuyerSaleId}/summary`}
                                className="text-dark fw-bolder text-hover-primary "
                            >
                                {' '}
                                {decouple.newBuyerSaleId}{' '}
                            </Link>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Root Sale',
                        content: (
                            <Link
                                to={`/sale/${decouple.rootSaleId}/summary`}
                                className="text-dark fw-bolder text-hover-primary "
                            >
                                {' '}
                                {decouple.rootSaleId}{' '}
                            </Link>
                        ),
                    },
                    { title: '', content: '' },
                ],
            }
        )
        return renderRows
    }
}
