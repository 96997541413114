/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { AuthPage, Logout } from '../../_metronic/modules/auth'
import { ErrorsPage } from '../../_metronic/modules/errors/ErrorsPage'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import useToken from '../backoffice/common/secure/useToken'
import useBackofficeUser, {
    getUserPaths,
    getUserPermissions,
} from '../backoffice/common/secure/useBackofficeUser'

const Routes: FC = () => {
    const token = useToken()
    const { backofficeUser } = useBackofficeUser()
    let isAuthorized = token.token != null
    const paths = backofficeUser.groups ? getUserPaths(backofficeUser) : []
    const rows = backofficeUser.groups ? getUserPermissions(backofficeUser) : []

    return (
        <>
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login" />
                ) : (
                    <MasterLayout>
                        <PrivateRoutes
                            allowedPaths={paths}
                            allowedRows={rows}
                        />
                    </MasterLayout>
                )}
            </Switch>
            <MasterInit />
        </>
    )
}

export { Routes }
