import { ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getDojiEnv } from '../../../Env'
import { ButtonLoading } from '../spinner/ButtonLoading'

const ActionConfirmationModal = (props: {
    show: boolean
    onClose: () => void
    onConfirm: () => any
    actionName: string
    loading: boolean
    modalBody?: ReactNode
}) => {
    const { show, onClose, onConfirm, loading, actionName, modalBody } = props

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop={loading ? 'static' : undefined}
        >
            <Modal.Header closeButton className={'modal-header'}>
                <Modal.Title>
                    You are about to <strong>{actionName}</strong> in{' '}
                    <strong>{getDojiEnv()}</strong>
                </Modal.Title>
                {!loading && (
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={onClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                )}
            </Modal.Header>

            <Modal.Body>{modalBody}</Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={loading}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    <div style={{ width: 60 }}>
                        {loading ? <ButtonLoading /> : 'confirm'}
                    </div>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ActionConfirmationModal
