import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Button, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ReturnMethod } from '../../data/Returning'
import Select from 'react-select'

const methodOptions = [
    {
        value: ReturnMethod.REFUND,
        label: ReturnMethod.REFUND,
    },
    {
        value: ReturnMethod.REPAIR,
        label: ReturnMethod.REPAIR,
    },
]

interface ConfirmReturnMethodButtonProps {
    request: IReturningRequest
}

export const ConfirmReturnMethodButton = ({
    request,
}: ConfirmReturnMethodButtonProps) => {
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [method, setMethod] = useState<ReturnMethod>(request.returnMethod)
    const handleShow = () => {
        setShow(true)
    }
    const returningRequestService = new ReturningRequestServiceImpl()

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/arrows/arr016.svg'}
                        className="svg-icon-1 svg-icon-success"
                    />{' '}
                    Confirm method
                </div>
            </Link>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>Confirm Return Method</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <p>Please select the Return Method</p>
                    <p style={{ color: 'red' }}>
                        (Attention: Confirming REFUND will automatically
                        reimburse the user)
                    </p>
                    <div>
                        <Select
                            classNamePrefix="select2"
                            isMulti={false}
                            value={{ value: method, label: method }}
                            onChange={(i) => {
                                setMethod(i?.value || request.returnMethod)
                            }}
                            options={methodOptions}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleClose()
                            approve()
                        }}
                        disabled={false}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function approve() {
        const response = await returningRequestService.confirmReturnMethod(
            request.requestId,
            method
        )
        if (response) {
            success('Return method confirmed!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not confirm the return method!')
        }
    }
}
