import { useLocation } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SALE_REPORT_ROUTE } from '../../RouteConstants'
import { downloadFile } from '../../utils/DownloadFile'

export const DownloadCsvButton = () => {
    const { search } = useLocation()
    const handleClick = async () => {
        const urlSearchParams = new URLSearchParams(search)
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        downloadFile(
            SALE_REPORT_ROUTE + (`?${urlSearchParams.toString()}` || ''),
            'sales.csv',
            options
        )
    }

    return (
        <div className="menu-item px-3">
            <div className="menu-link px-md-4" onClick={handleClick}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/files/fil017.svg'}
                        className="svg-icon-1"
                    />{' '}
                    csv
                </div>
            </div>
        </div>
    )
}
