import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../../common/style/modal.css'
import Returning, {
    RequiredInformationTypeOptions,
    ReturnTypeOptions,
} from '../../../data/Returning'
import './../../style/update.css'
import Select from 'react-select'
import { httpHelper } from '../../../../common/HttpCommon'
import { RETURNING_ROUTE } from '../../../../common/RouteConstants'
import useBackofficeUser, {
    getUserPermissions,
} from '../../../../common/secure/useBackofficeUser'

interface Options {
    value: string
    label: string
}

const UpdateButton = (props: { returning: Returning }) => {
    const returning = props.returning
    const [defective, setDefective] = useState(false)
    const [active, setActive] = useState(false)
    const { success, danger } = useAlert()
    const [title, setTitle] = useState('')
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [requiredOptions, setRequiredOptions] = useState(Array<Options>())
    const [returnTypeOptions, setReturnTypeOptions] = useState(Array<Options>())
    const { backofficeUser } = useBackofficeUser()
    const userPermissions = getUserPermissions(backofficeUser)

    const handleShow = () => {
        setShow(true)
        setTitle(returning.title)
        setDefective(returning.isDefective)
        setActive(returning.active)
        setReturnTypeOptions(
            returning.acceptedReturnTypes.map((type) => {
                return { value: type, label: type }
            })
        )
        setRequiredOptions(
            returning.requiredInformation.map((required) => {
                return { value: required, label: required }
            })
        )
    }

    let canEdit = false
    userPermissions.forEach((permission) => {
        if (permission === 'PUT') canEdit = true
    })

    if (!canEdit) return <></>

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen055.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Update
                </div>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        <strong>Update</strong> #{returning.returningReasonId}
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <b>Title</b>
                    <Form.Control
                        type={'text'}
                        placeholder={'Title'}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                    <div
                        className="form-check form-switch form-check-custom form-check-solid"
                        id={'defective-checkbox'}
                    >
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchDefault"
                        >
                            <div className="fw-bold text-muted">Defective</div>
                        </label>
                        <input
                            className="form-check-input h-20px"
                            type="checkbox"
                            defaultChecked={defective}
                            onChange={(e) => {
                                setDefective(e.target.checked)
                            }}
                            id="flexSwitchDefault"
                        />
                    </div>
                    <div
                        className="form-check form-switch form-check-custom form-check-solid"
                        id={'active-checkbox'}
                    >
                        <label
                            className="form-check-label"
                            htmlFor="switchActive"
                        >
                            <div className="fw-bold text-muted">Active</div>
                        </label>
                        <input
                            className="form-check-input h-20px flexSwitch"
                            type="checkbox"
                            defaultChecked={active}
                            onChange={(e) => {
                                setActive(e.target.checked)
                            }}
                            id="switchActive"
                        />
                    </div>

                    <div className="mb-5 col-md-12 mt-3">
                        <label className="form-label fw-bold">
                            {'Required Options'}
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select2"
                                isMulti={true}
                                defaultValue={requiredOptions}
                                options={RequiredInformationTypeOptions}
                                onChange={(e) => {
                                    setRequiredOptions(e as Array<Options>)
                                }}
                            />
                        </div>
                    </div>

                    <div className="mb-5 col-md-12 mt-3">
                        <label className="form-label fw-bold">
                            {'Return Type'}
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select2"
                                isMulti={true}
                                defaultValue={returnTypeOptions}
                                options={ReturnTypeOptions}
                                onChange={(e) => {
                                    setReturnTypeOptions(e as Array<Options>)
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            send(returning.returningReasonId).then()
                            handleClose()
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function send(returningReasonId: string) {
        let body = {
            title: title,
            isDefective: defective,
            active: active,
            requiredInformation: requiredOptions.map((option) => option.value),
            acceptedReturnTypes: returnTypeOptions.map(
                (option) => option.value
            ),
        }

        await httpHelper
            .put(`${RETURNING_ROUTE}/${returningReasonId}`, undefined, body)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could not updated returning!')
                else {
                    success('Returning was updated!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default UpdateButton
