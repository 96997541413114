import { Link } from 'react-router-dom'

interface CardLinkFieldProps {
    to: string | ToProps
    children?: string
}

interface ToProps {
    pathname: string
}
export const CardLinkField = ({ to, children = '' }: CardLinkFieldProps) => (
    <Link
        to={to}
        className="fw-bold fs-7"
        target={typeof to === 'string' ? undefined : '_blank'}
    >
        <span>{children}</span>
    </Link>
)
