import Sale from '../../data/Sale'
import SpecificSale from '../../data/SpecificSale'
import { httpHelper } from '../../../common/HttpCommon'
import { SALE_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { isGradeVerification } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import EnvironmentConfirmationModal from '../../../common/components/modal/EnvironmentConfirmationModal'
import { useState } from 'react'

const RejectGradeButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const { success, danger } = useAlert()
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const closeConfirmationModal = () => {
        setConfirmModalVisible(false)
    }

    const handleError = (reason: any) => {
        const rejectionData = reason?.response?.data
        if (rejectionData?.message)
            if (rejectionData?.errors?.length > 0) {
                const errorsMessage = rejectionData.errors
                    .map((error: { code: string | Number; error: string }) => {
                        return error.error
                    })
                    .join('\nError: ')
                danger('Error: ' + errorsMessage)
            } else danger('Error: ' + reason?.response?.data?.message)
        else danger('Error: could not reject grade!!')
        setConfirmModalVisible(false)
    }

    const handleSuccess = () => {
        setConfirmModalVisible(false)
        success('Grade Rejected!')
        window.setTimeout(() => {
            window.location.reload()
        }, 1500)
    }

    if (!isGradeVerification(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={() => {
                    setConfirmModalVisible(true)
                }}
            >
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen040.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Reject Grade
                </div>
            </div>
            <EnvironmentConfirmationModal
                show={confirmModalVisible}
                onClose={closeConfirmationModal}
                onConfirm={() => reject(sale.saleId)}
                actionName="Reject Grade"
                loading={loading}
            />
        </div>
    )

    async function reject(saleId: string) {
        let body = {
            cancelReason: 'GRADE_REJECTION',
            description: 'Grade rejected via Backoffice',
        }
        await httpHelper
            .delete(`${SALE_ROUTE}/${saleId}`, undefined, body)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could not reject grade!')
                else {
                    handleSuccess()
                }
            })
            .catch((reason) => {
                handleError(reason)
            })
    }
}

export default RejectGradeButton
