import { ReactElement } from 'react'
import {
    IReturningRequest,
    ReturningRequestAction,
} from '../../requests/data/ReturningRequest'
import { ApproveRequestButton } from './ApproveRequestButton'
import { CompleteRequestButton } from './CompleteRequestButton'
import { ConfirmRepairButton } from './ConfirmRepairButton'
import { ConfirmReturnButton } from './ConfirmReturnButton'
import { ConfirmReturnMethodButton } from './ConfirmReturnMethodButton'
import { RejectRepairButton } from './RejectRepairButton'
import { RejectRequestButton } from './RejectRequestButton'
import { RejectReturnButton } from './RejectReturnButton'
import { ChargeRefundFromSellerButton } from './ChargeRefundFromSellerButton'
import { ConfirmBuyerShipmentDetailsButton } from './ConfirmBuyerShipmentDetailButton'

const buttonsMap = {
    [ReturningRequestAction.APPROVE_REQUEST]: (request: IReturningRequest) => (
        <ApproveRequestButton request={request} />
    ),
    [ReturningRequestAction.REJECT_REQUEST]: (request: IReturningRequest) => (
        <RejectRequestButton request={request} />
    ),
    [ReturningRequestAction.APPROVE_RETURN]: (request: IReturningRequest) => (
        <ConfirmReturnButton request={request} />
    ),
    [ReturningRequestAction.REJECT_RETURN]: (request: IReturningRequest) => (
        <RejectReturnButton request={request} />
    ),
    [ReturningRequestAction.CONFIRM_RETURN_METHOD]: (
        request: IReturningRequest
    ) => <ConfirmReturnMethodButton request={request} />,
    [ReturningRequestAction.CONFIRM_REPAIR]: (request: IReturningRequest) => (
        <ConfirmRepairButton request={request} />
    ),
    [ReturningRequestAction.REJECT_REPAIR]: (request: IReturningRequest) => (
        <RejectRepairButton request={request} />
    ),
    [ReturningRequestAction.CHARGE_REFUND_FROM_SELLER]: (
        request: IReturningRequest
    ) => <ChargeRefundFromSellerButton request={request} />,
    [ReturningRequestAction.COMPLETE_REQUEST]: (request: IReturningRequest) => (
        <CompleteRequestButton request={request} />
    ),
    [ReturningRequestAction.CONFIRM_BUYER_SHIPMENT_DETAILS]: (
        request: IReturningRequest
    ) => <ConfirmBuyerShipmentDetailsButton request={request} />,
}

export const ReturningRequestActionButtons = (
    request?: IReturningRequest
): ReactElement[] => {
    const buttons: ReactElement[] = []
    if (!request) return buttons
    const actions = request.getStatusUpdateActions()
    actions.forEach((action) => {
        buttons.push(buttonsMap[action](request))
    })

    return buttons
}
