import Sale from '../../data/Sale'
import SpecificSale from '../../data/SpecificSale'
import { httpHelper } from '../../../common/HttpCommon'
import { SALE_ROUTE, SHIPMENT_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { KTSVG } from '../../../../../_metronic/helpers'

const ToggleFlagButton = (props: { sale: Sale | SpecificSale }) => {
    const { success, danger } = useAlert()
    const sale = props.sale
    if (
        !sale.buyerShipmentDetails ||
        ![sale.buyerStatus, sale.sellerStatus].includes('IN_TRANSIT')
    )
        return <></>

    const flagged = !!sale.buyerShipmentDetails.shipment?.lostParcelWarning

    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={() => {
                    toggle()
                }}
            >
                <KTSVG
                    path={`/media/flags/${
                        flagged ? 'white_flag' : 'red_flag'
                    }.svg`}
                    className="svg-icon-1 me-1"
                />
                {flagged ? 'Remove flag' : 'Flag'}
            </div>
        </div>
    )

    async function toggle() {
        try {
            await httpHelper.put(
                `${SHIPMENT_ROUTE}/${sale.buyerShipmentDetails?.shipmentId}/lost-parcel-warning`,
                undefined,
                { lostParcelWarning: !flagged }
            )
            // TODO: Remove this call when shipment cache on sales list is cleared after updating flag
            await httpHelper.get<SpecificSale>(
                `${SALE_ROUTE}/${sale.saleId}?useCacheOnShipment=false`
            )
            success('Flag updated!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } catch {
            danger('Error updating flag!')
        }
    }
}

export default ToggleFlagButton
