import { useEffect, useState } from 'react'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import ActionConfirmationModal from '../../../common/components/modal/ActionConfirmationModal'
import EnvironmentConfirmationModal from '../../../common/components/modal/EnvironmentConfirmationModal'
import { useOrderService } from '../../../order/service/rest/OrderServiceImpl'
import IUser from '../../data/User'

interface CancelAllOrdersButtonProps {
    side: 'ASK' | 'BID'
    user: IUser
}

const CancelAllOrdersButton = (props: CancelAllOrdersButtonProps) => {
    const { success, danger, warning } = useAlert()
    const { cancelAllOrders, loading, requestStatus } = useOrderService()
    const [currentState, setCurrentState] = useState<
        '' | 'envConfirmation' | 'finalConfirmation'
    >('')

    const orderType = props.side === 'ASK' ? 'Asks' : 'Bids'

    const handleConfirm = () => {
        cancelAllOrders({
            userId: props.user.userId,
            side: props.side,
        })
    }

    const handleClose = () => {
        setCurrentState('')
    }

    const setEnvironmentConfirmationState = () => {
        setCurrentState('envConfirmation')
    }

    const setFinalConfirmation = () => {
        setCurrentState('finalConfirmation')
    }

    useEffect(() => {
        if (requestStatus === 'SUCCESS') {
            success(`All user ${orderType} cancelled successfully!`)
            setCurrentState('')
        } else if (requestStatus === 'TIMEOUT') {
            warning(
                'Request timed out during cancellation. Please check the progress in the Orders page.'
            )
            setCurrentState('')
        } else if (requestStatus === 'ERROR') {
            danger(`Error cancelling user ${orderType}!`)
            setCurrentState('')
        }
    }, [requestStatus, danger, success, orderType, warning])

    const ConfirmationModalBody = () => {
        if (loading) {
            return (
                <>
                    <p>Please wait! This could take a few minutes.</p>
                    <p>
                        You can check the progress{' '}
                        <Link
                            to={`/order?side=${props.side}&userId=${props?.user?.userId}`}
                            target="_blank"
                        >
                            here
                        </Link>
                        .
                    </p>
                </>
            )
        }
        return <p>Are you sure?</p>
    }
    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-3"
                onClick={setEnvironmentConfirmationState}
            >
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen044.svg'}
                        className="svg-icon-warning me-1"
                    />
                    {`Cancel all ${orderType}`}
                </div>
            </div>
            <EnvironmentConfirmationModal
                show={currentState === 'envConfirmation'}
                onClose={handleClose}
                onConfirm={setFinalConfirmation}
                actionName={`cancel all user ${orderType}`}
                loading={loading}
            />
            <ActionConfirmationModal
                show={currentState === 'finalConfirmation'}
                onClose={handleClose}
                onConfirm={handleConfirm}
                actionName={`cancel all user ${orderType}`}
                loading={loading}
                modalBody={ConfirmationModalBody()}
            />
        </div>
    )
}

export default CancelAllOrdersButton
