import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import React from 'react'
import { MonetaryField } from '../../../common/components/fields/MonetaryField'
import {
    TrackingNumberRow,
    TrackingSide,
} from '../buttons/shipment/TrackingNumberRow'
import { DataField } from '../../../common/components/fields/DataField'

const trackingUrlBuyerRow = (data: SpecificSale) => {
    let trackingUrl = '#'
    if (
        data.buyerShipmentDetails?.shipment?.trackingUrl !== null &&
        data.buyerShipmentDetails?.shipment?.trackingUrl !== undefined
    ) {
        trackingUrl = data.buyerShipmentDetails.shipment.trackingUrl
    }
    return (
        <a
            href={trackingUrl}
            className="fw-bolder fs-6"
            target="_blank"
            rel="noreferrer"
        >
            {data.buyerShipmentDetails?.shipment?.trackingUrl}
        </a>
    )
}

const trackingUrlSellerRow = (data: SpecificSale) => {
    let trackingUrl = '#'
    if (
        data.sellerShipmentDetails?.postLocationDetails.shipment
            ?.trackingUrl !== null &&
        data.sellerShipmentDetails?.postLocationDetails.shipment
            ?.trackingUrl !== undefined
    ) {
        trackingUrl =
            data.sellerShipmentDetails.postLocationDetails.shipment.trackingUrl
    }
    return (
        <a
            href={trackingUrl}
            className="fw-bolder fs-6"
            target="_blank"
            rel="noreferrer"
        >
            {
                data.sellerShipmentDetails?.postLocationDetails.shipment
                    ?.trackingUrl
            }
        </a>
    )
}

export function ShipmentView(sale: SpecificSale): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    // Buyer Shipment Details
    renderRows.push(
        {
            column: [{ title: '', content: <h1>Buyer Shipment Details</h1> }],
        },
        {
            column: [
                { title: 'Type', content: sale.buyerShipmentDetails?.type },
                {
                    title: 'Fee',
                    content: MonetaryField(
                        sale.buyerShipmentDetails?.fee,
                        sale.currency
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Street',
                    content: (
                        <span className="fw-bold fs-7">
                            {sale.buyerShipmentDetails?.address.street}{' '}
                        </span>
                    ),
                },
                { title: '', content: '' },
            ],
        },
        {
            column: [
                {
                    title: 'Post Code',
                    content: sale.buyerShipmentDetails?.address.postCode,
                },
                {
                    title: 'Town',
                    content: sale.buyerShipmentDetails?.address.town,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Provider',
                    content: sale.buyerShipmentDetails?.shipment?.provider,
                },
                {
                    title: 'Provider ID',
                    content: sale.buyerShipmentDetails?.shipment?.providerId,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Shipment Id',
                    content: sale.buyerShipmentDetails?.shipmentId,
                },
                {
                    title: 'Tracking Number',
                    content: (
                        <TrackingNumberRow
                            sale={sale}
                            side={TrackingSide.BUYER}
                        />
                    ),
                },
            ],
        },
        {
            column: [
                { title: 'Tracking URL', content: trackingUrlBuyerRow(sale) },
                {
                    title: 'Shipment Status',
                    content: sale.buyerShipmentDetails?.shipment?.status,
                },
            ],
        }
    )

    // Seller Shipment Details
    renderRows.push(
        {
            column: [{ title: '', content: <h1>Seller Shipment Details</h1> }],
        },
        {
            column: [
                { title: 'Type', content: sale.sellerShipmentDetails?.type },
                {
                    title: 'Delivery Fee',
                    content: MonetaryField(
                        sale.sellerShipmentDetails?.fee,
                        sale.currency
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Street',
                    content: (
                        <span className="fw-bold fs-7">
                            {
                                sale.sellerShipmentDetails?.postLocationDetails
                                    .address?.street
                            }{' '}
                        </span>
                    ),
                },
                { title: '', content: '' },
            ],
        },
        {
            column: [
                {
                    title: 'Post Code',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails.address
                            ?.postCode,
                },
                {
                    title: 'Town',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails.address
                            ?.town,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Provider',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails.shipment
                            ?.provider,
                },
                {
                    title: 'Provider ID',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails.shipment
                            ?.providerId,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Tracking Number',
                    content: (
                        <TrackingNumberRow
                            sale={sale}
                            side={TrackingSide.SELLER}
                        />
                    ),
                },
                {
                    title: 'Expiration Date',
                    content: DataField(
                        sale.sellerShipmentDetails?.postLocationDetails.shipment
                            ?.expirationDate
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Shipment Id',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails
                            ?.shipmentId,
                },
                { title: 'Tracking URL', content: trackingUrlSellerRow(sale) },
            ],
        },
        {
            column: [
                {
                    title: 'Shipment Status',
                    content:
                        sale.sellerShipmentDetails?.postLocationDetails
                            ?.shipment?.status,
                },
                {
                    title: '',
                    content: '',
                },
            ],
        }
    )
    return renderRows
}
