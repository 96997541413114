import { KTSVG } from '../../../../../_metronic/helpers'
interface BooleanFieldProps {
    condition: boolean
}
export const BooleanField = (props: BooleanFieldProps) => {
    return (
        <KTSVG
            path={`/media/icons/duotune/arrows/arr01${
                props.condition ? '6' : '5'
            }.svg`}
            className={`svg-icon svg-icon-2x svg-icon-${
                props.condition ? 'success' : 'danger'
            } booleanField`}
        />
    )
}
