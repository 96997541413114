import SpecificSale from '../data/SpecificSale'
import { ReactElement } from 'react'
import CancelButton from './buttons/CancelButton'
import ForceCompleteSale from './buttons/ForceCompleteSale'
import DecoupleButton from './buttons/DecoupleButton'
import DownloadSellerLabel from './buttons/seller/DownloadSellerLabel'
import DownloadBuyerLabel from './buttons/buyer/DownloadBuyerLabel'
import UpdatePriceButton from './buttons/UpdatePriceButton'
import ApprovePaymentButton from './buttons/payment/ApprovePaymentButton'
import UpdateProductButton from './buttons/product/UpdateProductButton'
import BuyerToCollectedButton from './buttons/buyer/BuyerToCollectedButton'
import SellerToCollectedButton from './buttons/seller/SellerToCollectedButton'
import BuyerToDeliveredButton from './buttons/buyer/BuyerToDeliveredButton'
import SellerToDeliveredButton from './buttons/seller/SellerToDeliveredButton'
import AcceptGradeButton from './buttons/AcceptGradeButton'
import SellerToFailedButton from './buttons/seller/SellerToFailedButton'
import IncrementShipmentDaysButton from './buttons/seller/IncrementShipmentDaysButton'
import DownloadBuyerInvoice from './buttons/buyer/DownloadBuyerInvoice'
import DownloadSellerInvoice from './buttons/seller/DownloadSellerInvoice'
import ToggleFlagButton from './buttons/ToggleFlagButton'
import ExecuteFraudAnalysisButton from './buttons/ExecuteFraudAnalysisButton'
import RejectGradeButton from './buttons/RejectGradeButton'

export function getButtons(
    sale: SpecificSale | undefined
): Array<ReactElement> {
    if (!sale) return []

    return [
        <DecoupleButton sale={sale} />,
        <CancelButton sale={sale} />,
        <ForceCompleteSale sale={sale} />,
        <UpdatePriceButton sale={sale} />,
        <ExecuteFraudAnalysisButton sale={sale} />,
        <RejectGradeButton sale={sale} />,
        <AcceptGradeButton sale={sale} />,
        <UpdateProductButton sale={sale} />,
        <ApprovePaymentButton sale={sale} />,
        <BuyerToCollectedButton sale={sale} title="Mark Buyer as Collected" />,
        <BuyerToDeliveredButton sale={sale} title="Mark Buyer as Delivered" />,
        <SellerToCollectedButton
            sale={sale}
            title="Mark Seller as Collected"
        />,
        <SellerToDeliveredButton
            sale={sale}
            title="Mark Seller as Delivered"
        />,
        <SellerToFailedButton sale={sale} />,
        <IncrementShipmentDaysButton sale={sale} />,
        <ToggleFlagButton sale={sale} />,
        <DownloadBuyerLabel sale={sale} />,
        <DownloadSellerLabel sale={sale} />,
        <DownloadBuyerInvoice sale={sale} />,
        <DownloadSellerInvoice sale={sale} />,
    ]
}
