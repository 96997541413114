import AsyncSelect from 'react-select/async'
import _ from 'lodash'

interface DynamicSearchProps {
    title: string
    subtitle: string
    onSelect: any
    fetchData: any
    formatOptionLabel: any
    ValueOptionLabel: any
}
const reactSelectStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#eef3f7',
        height: '45px',
        borderColor: '#eef3f7',
        width: '500px',
    }),
}

export const DynamicSearch = ({
    title,
    subtitle,
    onSelect,
    formatOptionLabel,
    ValueOptionLabel,
    fetchData,
}: DynamicSearchProps) => {
    const noOptionsMessage = (term: { inputValue: string }) => {
        return term.inputValue.length > 3 ? (
            <span>No options</span>
        ) : (
            <span>Search input must be at least 3 characters</span>
        )
    }

    const loadOptions = async (inputValue: string, callback: any) => {
        const data = await fetchData(inputValue)
        callback(data)
        return data
    }

    return (
        <div className="card mb-4 pb-4">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                        {title}
                    </span>
                    <span className="text-muted my-4 fw-bold fs-7">
                        {subtitle}
                    </span>
                    <AsyncSelect
                        blurInputOnSelect={true}
                        closeMenuOnSelect={false}
                        isClearable
                        styles={reactSelectStyle}
                        formatOptionLabel={formatOptionLabel}
                        components={{ SingleValue: ValueOptionLabel }}
                        getOptionValue={(option: any) => {
                            return option.email
                        }}
                        onChange={onSelect}
                        noOptionsMessage={noOptionsMessage}
                        loadOptions={_.debounce(loadOptions, 400)}
                    />
                </h3>
            </div>
        </div>
    )
}
