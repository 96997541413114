import { useState } from 'react'
import Sale from '../../data/Sale'
import { isSellerWaitingShipment } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import { httpHelper } from '../../../common/HttpCommon'
import { SALE_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../data/SpecificSale'
import './../../../common/style/modal.css'
import ConfirmEnvironmentModel from '../modal/ConfirmEnvironmentModel'

const ForceCompleteSale = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const { success, danger } = useAlert()

    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)

    const handleShow = () => {
        setShowConfirmCancelModal(true)
    }
    const handleCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false)
    }
    const handleConfirmCancelModal = () => {
        complete(sale.saleId).then()
        setShowConfirmCancelModal(false)
    }

    if (!isSellerWaitingShipment(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen037.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Force complete a transaction
                </div>
            </div>
            <ConfirmEnvironmentModel
                show={showConfirmCancelModal}
                onClose={handleCloseConfirmCancelModal}
                onConfirm={handleConfirmCancelModal}
                title="You are about to FORCE complete a sale"
            />
        </div>
    )

    async function complete(saleId: string) {
        try {
            await httpHelper.post(`${SALE_ROUTE}/${saleId}/force-complete`)
            success('Sale was completed!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } catch (e: any) {
            danger(
                `Error: could not complete the sale! message: "${e.message}" response: "${e.response?.data?.message}"`
            )
        }
    }
}

export default ForceCompleteSale
