import axios, { AxiosRequestConfig } from 'axios'
import RestResponse from './RestResponse'
import apiUrl from '../Env'
import {
    getPreferencesCountry,
    getPreferencesCurrency,
    getPreferencesLanguage,
} from './Preferences'

class HttpHelper {
    async get<T>(resource: string, params?: URLSearchParams): Promise<T> {
        try {
            const { data } = await Axios.get<RestResponse<T>>(resource, {
                params: params,
            })
            return data.response
        } catch (error) {
            console.error(`error at request ${resource}`, error)
            throw error
        }
    }

    async post<T>(resource: string, body?: any): Promise<T> {
        try {
            const { data } = await Axios.post<RestResponse<T>>(resource, body)
            return data.response
        } catch (error) {
            console.error(`error at request ${resource}`, error)
            throw error
        }
    }

    async put<T>(
        resource: string,
        params?: URLSearchParams,
        dataConfig?: {}
    ): Promise<T> {
        try {
            const { data } = await Axios.put<RestResponse<T>>(
                resource,
                dataConfig
            )
            return data.response
        } catch (error) {
            console.error(`error at request ${resource}`, error)
            throw error
        }
    }

    async delete<T>(
        resource: string,
        params?: URLSearchParams,
        dataConfig?: {},
        headers?: { [key: string]: string }
    ): Promise<T> {
        try {
            const { data } = await Axios.delete<RestResponse<T>>(resource, {
                params: params,
                data: dataConfig,
                headers: headers,
            })
            return data.response
        } catch (error) {
            console.error(`error at request ${resource}`, error)
            throw error
        }
    }
}

const getToken = () => {
    return localStorage.getItem('Authorization')
}

export const RequestInterceptor = (request: AxiosRequestConfig) => {
    if (!request.headers.Authorization)
        request.headers.Authorization = `Bearer ${getToken()}`
    request.headers.Currency = getPreferencesCurrency()
    request.headers.Country = getPreferencesCountry()
    request.headers['Accept-Language'] = getPreferencesLanguage()
    return request
}

const Axios = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-type': 'application/json',
    },
})

export const AxiosReturnFile = axios.create({
    baseURL: apiUrl,
    responseType: 'blob',
})

Axios.interceptors.request.use(RequestInterceptor)
AxiosReturnFile.interceptors.request.use(RequestInterceptor)

export const httpHelper = new HttpHelper()
export default Axios
