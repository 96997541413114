export const TableActionsMenu = (props: { children?: React.ReactNode }) => {
    if (!props.children) return <></>
    return (
        <div className="d-flex justify-content-end">
            <div
                className="btn btn-sm show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="top-end"
            >
                <span>
                    <i className="bi bi-three-dots fs-3" />
                </span>
            </div>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-6 w-150px"
                data-kt-menu="true"
            >
                {props.children}
            </div>
        </div>
    )
}
