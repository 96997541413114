interface TableCurrencyFieldProps {
    price: number
    currency: string
}
export const TableCurrencyField = ({
    price = 0,
    currency = '',
}: TableCurrencyFieldProps) => (
    <span className="text-dark fw-bolder fw-bold d-block fs-7">
        {price}
        <span className="text-muted fw-bold"> {currency}</span>
    </span>
)
