import React, { useState } from 'react'
import 'flatpickr/dist/themes/dark.css'

type Props = {
    title: string
    defaultValue?: string | null
    onChangeCallback: (newValue: string) => void
}

export const DynamicFilterBoolean = ({
    title,
    defaultValue,
    onChangeCallback,
}: Props) => {
    const [value, setValue] = useState<boolean>(
        defaultValue != null ? defaultValue === 'true' : false
    )
    const onChange = () => {
        onChangeCallback(String(!value))
        setValue(!value)
    }

    return (
        <>
            <div className="mb-10">
                <div className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        defaultChecked={value}
                        onClick={onChange}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                    >
                        {title}
                    </label>
                </div>
            </div>
        </>
    )
}
