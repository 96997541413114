import { Label } from './Label'
import { SellerChargedStatus } from '../../requests/data/ReturningRequest'

type SellerChargedStatusBadgeProps = {
    sellerChargedStatus: SellerChargedStatus
}

export const SellerChargedStatusBadge = ({
    sellerChargedStatus,
}: SellerChargedStatusBadgeProps) => {
    switch (sellerChargedStatus) {
        case SellerChargedStatus.CHARGED:
            return <Label css="badge-success">{sellerChargedStatus}</Label>

        case SellerChargedStatus.NOT_CHARGED:
            return (
                <Label css="badge-light-warning">{sellerChargedStatus}</Label>
            )

        default:
            return <Label css="badge-light-dark">{sellerChargedStatus}</Label>
    }
}
