import { CustomRow } from '../../../../common/components/data/CardUtil'
import { IReturningRequest } from '../../data/ReturningRequest'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'
import { TextField } from '../../../../common/components/fields/TextField'

export function ImageView(request: IReturningRequest): Array<CustomRow> {
    const images = request.images
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <div>
                        {images.length ? (
                            <PhotoProvider>
                                {images.map((image) => (
                                    <PhotoView src={image?.url}>
                                        <img
                                            src={image?.url}
                                            alt=""
                                            style={{ width: 250 }}
                                            className="me-3 cursor-pointer"
                                        />
                                    </PhotoView>
                                ))}
                            </PhotoProvider>
                        ) : (
                            <TextField>No images provided</TextField>
                        )}
                    </div>
                ),
            },
        ],
    })
    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {images?.map((image) => (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={image.url}
                            >
                                {image.url}
                            </a>
                        ))}
                    </div>
                ),
            },
        ],
    })
    return renderRows
}
