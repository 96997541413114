import React from 'react'
interface BoldTextFiledProps {
    children?: React.ReactNode
}

export const BoldTextField = (props: BoldTextFiledProps) => {
    return (
        <span className="fw-bold fs-7">
            {props.children ? props.children : '-'}
        </span>
    )
}
