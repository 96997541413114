import { Label } from './Label'

type RequiredInformationBadgeProps = {
    info: string[]
}

export const RequiredInformationBadge = ({
    info,
}: RequiredInformationBadgeProps) => {
    return (
        <>
            {info.map((i) => (
                <Label css="badge-light">{i}</Label>
            ))}
        </>
    )
}
