interface SearchResponseMessageProps {
    message?: string
}
export const SearchResponseMessage = (props: SearchResponseMessageProps) => {
    if (!props.message) return <></>
    return (
        <>
            <div className="card">
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        {/* <span className="card-label fw-bolder fs-3 mb-1">
                            this is the title
                        </span> */}
                        <span className="text-muted mt-1 fw-bold fs-7">
                            {props.message}
                        </span>
                    </h3>
                    <div
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Click to add a user"
                    ></div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className="card-body py-3">
                    {/* begin::Table container */}
                    <div className="table-responsive">
                        {/* <div
                            className="d-flex justify-content-center text-primary align-items-center"
                            style={{ height: '300px' }}
                        >
                            <div className="spinner-border" role="status"></div>
                        </div> */}

                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}
