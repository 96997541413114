import { Label } from './Label'
import { ReturnMethod } from '../../data/Returning'

type ReturnMethodBadgeProps = {
    returnMethod: ReturnMethod
}

export const ReturnMethodBadge = ({ returnMethod }: ReturnMethodBadgeProps) => {
    switch (returnMethod) {
        case ReturnMethod.REFUND:
            return <Label css="badge-secondary">{returnMethod}</Label>
        case ReturnMethod.REPAIR:
            return <Label css="badge-light-primary">{returnMethod}</Label>
    }
}
