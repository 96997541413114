import { useState } from 'react'
import { Country } from './data/Country'
import { Currency } from './data/Currency'
import { Language } from './data/Language'
import { Location } from './data/Location'

const CURRENCY_KEY = 'currency'
const COUNTRY_KEY = 'country'
const LANGUAGE_KEY = 'language'

const GB = Country.GB
const BR = Country.BR
const BRL = Currency.BRL
const GBP = Currency.GBP
const ptBr = Language.PT_BR
const enGb = Language.EN_GB

const buildLocation = (country: Country): Location => {
    switch (country) {
        case BR:
            return {
                country: BR,
                currency: BRL,
                language: ptBr,
            }
        case GB:
            return {
                country: GB,
                currency: GBP,
                language: enGb,
            }
        default:
            return {
                country: GB,
                currency: GBP,
                language: enGb,
            }
    }
}

export const getPreferencesCurrency = (): Currency => {
    return (localStorage.getItem(CURRENCY_KEY) as Currency) || GBP
}

export const setPreferencesCurrency = (currency: Currency) => {
    localStorage.setItem(CURRENCY_KEY, currency)
}

export const getPreferencesCountry = (): Country => {
    return (localStorage.getItem(COUNTRY_KEY) as Country) || GB
}

export const setPreferencesCountry = (country: Country) => {
    localStorage.setItem(COUNTRY_KEY, country)
}

export const getPreferencesLanguage = (): Language => {
    return (localStorage.getItem(LANGUAGE_KEY) as Language) || enGb
}

export const setPreferencesLanguage = (language: Language) => {
    localStorage.setItem(LANGUAGE_KEY, language)
}

export const useUserPreferences = () => {
    const [stateLocation, setStateLocation] = useState<Location>(
        buildLocation(getPreferencesCountry())
    )

    const setLocation = (country: Country) => {
        const location = buildLocation(country)
        setPreferencesCountry(location.country)
        setPreferencesCurrency(location.currency)
        setPreferencesLanguage(location.language)
        setStateLocation(location)
    }

    return {
        location: stateLocation,
        setLocation,
    }
}
