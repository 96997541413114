import React, { useCallback, useEffect, useState } from 'react'
import { BACKOFFICE_ROUTE, PRODUCTS_ROUTE } from '../../common/RouteConstants'
import { httpHelper } from '../../common/HttpCommon'
import { useHistory, useLocation } from 'react-router-dom'
import {
    FormatOptionLabel,
    ValueOptionLabel,
} from '../../product/component/ProductSearchLabels'
import { PageTitle } from '../../../../_metronic/layout/core'
import { SearchInput } from './SearchInput'
import { SearchResultContainer } from './SearchResultContainer'
import _ from 'lodash'
import { QuickSearchResponse } from '../data/QuickSearchResponse'
import Sale from '../../sale/data/Sale'
import IUser from '../../user/data/User'
import Order from '../../order/data/Order'
import { TableLoading } from './TableLoading'
import { SearchResponseMessage } from './NoMatchesFound'

type ResponseStatus = 'NO_MATCH' | 'ERROR' | 'WAITING'

const QuickSearch: React.FC = () => {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [responseStatus, setResponseStatus] =
        useState<ResponseStatus>('WAITING')
    const [term, setTerm] = useState<string>('')
    const [data, setData] = useState<QuickSearchResponse>({})
    const { search } = useLocation()

    const handleChange = (e: any) => {
        const newTerm = e?.target?.value
        setTerm(newTerm)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = useCallback(
        _.debounce(async (newTerm: string) => {
            setLoading(true)
            setResponseStatus('WAITING')
            try {
                const apiData = await httpHelper.get<QuickSearchResponse>(
                    `${BACKOFFICE_ROUTE}/generic-search?term=${newTerm}`
                )
                setData(apiData)
                if (ChecknoMatches(apiData)) {
                    setResponseStatus('NO_MATCH')
                }
            } catch {
                setResponseStatus('ERROR')
            } finally {
                setLoading(false)
            }
        }, 200),
        []
    )

    const updateUrl = () => {
        let queryParams = new URLSearchParams()
        queryParams.set('term', term)

        history.replace({ search: queryParams.toString() })
    }

    useEffect(() => {
        setResponseStatus('WAITING')
        if (term?.length >= 3) {
            updateUrl()
            fetchData(term)
        } else {
            setData({})
        }
    }, [term])

    useEffect(() => {
        const params = new URLSearchParams(search)
        const paramTerm = params.get('term') || ''
        setTerm(paramTerm)
    }, [])

    const handleSaleLineClick = (sale: Sale) => {
        history.push(`/sale/${sale.saleId}/summary`)
    }

    const handleUserLineClick = (user: IUser) => {
        history.push(`/user/${user.userId}/summary`)
    }

    const handleOrderLineClick = (order: Order) => {
        history.push(`/order/${order.orderId}/summary`)
    }

    const ChecknoMatches = (response: QuickSearchResponse): boolean => {
        return !response.orders && !response.sales && !response.users?.length
    }

    const getResponseMessage = () => {
        if (responseStatus === 'ERROR') return 'An error occurred.'
        else if (responseStatus === 'NO_MATCH') return 'No matches found!'
        return ''
    }

    const message = getResponseMessage()

    return (
        <>
            <PageTitle>Quick search</PageTitle>
            <SearchInput
                title=""
                subtitle="Search for users, orders or sales"
                formatOptionLabel={FormatOptionLabel}
                ValueOptionLabel={ValueOptionLabel}
                value={term}
                onChange={handleChange}
            />
            {loading ? (
                <TableLoading loading={loading} />
            ) : (
                <>
                    <SearchResultContainer
                        title="Users"
                        resource={PRODUCTS_ROUTE}
                        data={data.users}
                        dataType="user"
                        lineAction={handleUserLineClick}
                    />
                    <SearchResultContainer
                        title="Sales"
                        resource={PRODUCTS_ROUTE}
                        data={data.sales}
                        dataType="sale"
                        lineAction={handleSaleLineClick}
                    />
                    <SearchResultContainer
                        title="Orders"
                        resource={PRODUCTS_ROUTE}
                        data={data.orders}
                        dataType="order"
                        lineAction={handleOrderLineClick}
                    />
                </>
            )}
            {<SearchResponseMessage message={message} />}
        </>
    )
}

export { QuickSearch }
