import React from 'react'

import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { Type } from '../../../common/components/table/DynamicFilter'
import Moment from 'react-moment'
import { RETURNING_ROUTE } from '../../../common/RouteConstants'
import Returning from '../../data/Returning'
import { Link } from 'react-router-dom'
import { BooleanField } from '../../../common/components/fields/BooleanField'
import { ReturnTypeBadge } from '../../component/badge/ReturnTypeBadge'
import { InformationTypeLabel } from './label/InformationTypeLabel'
import UpdateButton from './button/UpdateButton'
import DisableButton from './button/DisableButton'
import CreateButton from './button/CreateButton'

const ReturningReasons: React.FC = () => {
    const createdColumn = (data: Returning) => {
        return (
            <div>
                <span className="text-muted fw-bold d-block fs-8">
                    Created at
                </span>
                <span className="text-dark fw-bolder d-block fs-7">
                    <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
                </span>
            </div>
        )
    }

    const idColumn = (data: Returning) => {
        return (
            <div>
                <Link
                    to={`#`} ///returning-view/${data.returningReasonId}/summary
                    className="text-dark text-hover-primary fw-bolder d-block fs-6"
                >
                    {data.title}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    {data.returningReasonId}
                </span>
            </div>
        )
    }

    const returnTypesColumn = (data: Returning) => {
        return (
            <div>
                {data.acceptedReturnTypes.map((type) => {
                    return (
                        <div>
                            <ReturnTypeBadge type={type} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const informationTypeColumn = (data: Returning) => {
        return (
            <div>
                {data.requiredInformation.map((type) => {
                    return (
                        <div>
                            <InformationTypeLabel status={type} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const isActiveColumn = (data: Returning) => {
        return <BooleanField condition={data.active} />
    }

    const isDefectiveColumn = (data: Returning) => {
        return <BooleanField condition={data.isDefective} />
    }

    const actionColumn = (data: Returning) => {
        return (
            <>
                <Link
                    to={'#'}
                    className="btn btn-bg-white btn-sm show menu-dropdown"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                >
                    <span>
                        <i className="bi bi-three-dots fs-5" />
                    </span>
                </Link>
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4"
                    data-kt-menu="true"
                >
                    <UpdateButton returning={data} />
                    <DisableButton returning={data} />
                </div>
            </>
        )
    }

    const columns = [
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Title',
            render: idColumn,
        },
        {
            header: 'Types',
            render: returnTypesColumn,
        },
        {
            header: 'Information Types',
            render: informationTypeColumn,
        },
        {
            header: 'Active',
            render: isActiveColumn,
        },
        {
            header: 'Defective',
            render: isDefectiveColumn,
        },
        {
            header: 'Action',
            render: actionColumn,
        },
    ]

    const filters = [
        {
            title: 'Is Active',
            queryParam: 'active',
            type: Type.SWITCH,
        },
    ]
    return (
        <>
            <DynamicTable
                title={'Returning Reasons'}
                description={'List of returning reasons'}
                resource={RETURNING_ROUTE}
                columns={columns}
                filters={filters}
            />
            <CreateButton />
        </>
    )
}

export { ReturningReasons }
