import { Link } from 'react-router-dom'
import { useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Button, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'

interface RejectRepairButtonProps {
    request: IReturningRequest
}

export const RejectRepairButton = ({ request }: RejectRepairButtonProps) => {
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
    }
    const returningRequestService = new ReturningRequestServiceImpl()
    return (
        <>
            <div className="menu-item px-3">
                <Link to="#" className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/arrows/arr015.svg'}
                            className="svg-icon-1 svg-icon-danger"
                        />{' '}
                        Reject repair
                    </div>
                </Link>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>
                            You are about to reject a Repair
                        </Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Are you sure you want to reject the repair?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                handleClose()
                                confirm()
                            }}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

    async function confirm() {
        const response = await returningRequestService.rejectRepair(
            request.requestId
        )
        if (response) {
            success('repair rejected!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not reject the repair!')
        }
    }
}
