import { useState } from 'react'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import BackofficeUser from '../../user/data/BackofficeUser'
import JwtPayload from '../../user/data/JwtPayload'

export default function useBackofficeUser() {
    const getBackofficeUser = (): BackofficeUser => {
        if (localStorage.getItem('Authorization')) {
            const jwt = localStorage.getItem('Authorization')!
            const decodedJwt = jwt_decode<JwtPayload>(jwt)
            const user: BackofficeUser = JSON.parse(decodedJwt.user)
            const expDate = moment(user.expirationDate)

            if (expDate.isBefore(moment())) {
                localStorage.removeItem('Authorization')
                window.location.reload()
                return {} as BackofficeUser
            }

            return user
        }

        return {} as BackofficeUser
    }

    const [backofficeUser] = useState<BackofficeUser>(getBackofficeUser())

    return {
        backofficeUser,
    }
}

export function getUserPaths(backofficeUser: BackofficeUser): string[] {
    return backofficeUser.groups.flatMap((g) => g).flatMap((r) => r.paths)
}

export function getUserPermissions(backofficeUser: BackofficeUser): string[] {
    return backofficeUser.groups
        .flatMap((g) => g.roles)
        .flatMap((r) => r.rolePermissions)
}
