import Moment from 'react-moment'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { WITHDRAWALS_ROUTE } from '../../../common/RouteConstants'
import IUser from '../../data/User'
import { Withdrawal } from '../../data/Withdrawal'

const createdColumn = (data: Withdrawal) => {
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}

const amountColumn = (data: Withdrawal) => (
    <span className="text-dark fw-bolder fs-5">
        {data.amount}{' '}
        <span className="text-muted fw-bold">{'  ' + data.currency}</span>
    </span>
)
const feeColumn = (data: Withdrawal) => (
    <span className="text-dark fw-bolder fs-5">
        {data.fee} <span className="text-muted fw-bold">{'%'}</span>
    </span>
)

const statusColumn = (data: Withdrawal) => (
    <span className="text-dark fw-bolder d-block fs-7">{data.status}</span>
)

const payoutMethodColumn = (data: Withdrawal) => (
    <>
        <span className="text-dark fw-bolder d-block fs-7">
            {data?.payoutMethod?.type}
        </span>
        <span className="text-muted fw-bold d-block fs-8">
            {data?.payoutMethod?.provider}
        </span>
    </>
)

const columns = [
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Amount',
        render: amountColumn,
    },
    {
        header: 'Fee',
        render: feeColumn,
    },
    {
        header: 'Status',
        render: statusColumn,
    },
    {
        header: 'Payout Method',
        render: payoutMethodColumn,
    },
]

export function WithdrawalsView(
    user: IUser,
    currentScreen: string
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title="Withdrawals"
                        description="List of withdrawals"
                        resource={`${WITHDRAWALS_ROUTE}/?userId=${user?.userId}`}
                        columns={columns}
                        filters={[]}
                        currentScreen={currentScreen}
                    />
                ),
            },
        ],
    })
    return renderRows
}
