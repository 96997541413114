import { httpHelper } from '../../../common/HttpCommon'
import { USER_ROUTE } from '../../../common/RouteConstants'
import { TransactionFee } from '../../data/TransactionFee'
import IUser from '../../data/User'
import User from '../../data/User'
import { UserService } from '../UserService'

export class UserServiceImpl implements UserService {
    updateTransactionFee(userId: string, fee: number): Promise<TransactionFee> {
        return httpHelper.post<TransactionFee>(`${USER_ROUTE}/${userId}/fee`, {
            fee,
        })
    }
    findById(userId: string): Promise<User> {
        return httpHelper.get<IUser>(`${USER_ROUTE}/${userId}`)
    }
    updateUserInstitutional(userId: string, newValue: boolean): Promise<IUser> {
        return httpHelper.put<IUser>(`${USER_ROUTE}/${userId}`, undefined, {
            institutional: newValue,
        })
    }
}
