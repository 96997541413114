import { useState } from 'react'
import Sale from '../../data/Sale'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../data/SpecificSale'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'
import { ButtonLoading } from '../../../common/components/spinner/ButtonLoading'
import { FraudAnalysisServiceImpl } from '../../../fraudanalysis/service/impl/FraudAnalysisServiceImpl'

const ExecuteFraudAnalysisButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const [disabledButton, setDisabledButton] = useState(true)
    const [saleId, setSaleId] = useState('')
    const { success, danger } = useAlert()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => {
        if (!loading) setShow(false)
    }
    const handleShow = () => {
        setShow(true)
        setDisabledButton(true)
        setSaleId('')
    }
    const service = new FraudAnalysisServiceImpl()

    return (
        <>
            <div className="menu-item px-3">
                <div className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/general/gen048.svg'}
                            className="svg-icon-1"
                        />{' '}
                        Send to Fraud Analysis
                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>
                            You are about to send this sale to Fraud Analysis.
                        </Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Please confirm the{' '}
                            <strong>sale id ({sale.saleId})</strong> to execute.
                        </p>
                        <p>
                            <strong>
                                Remember, you have to pay a 0,72% fee for this
                                action.
                            </strong>
                        </p>
                        <Form.Control
                            type={'text'}
                            placeholder={sale.saleId}
                            value={saleId}
                            onChange={(e) => {
                                setSaleId(e.target.value)
                                if (e.target.value === sale.saleId)
                                    setDisabledButton(false)
                                else setDisabledButton(true)
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            disabled={loading}
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={() => {
                                execute(sale.saleId)
                            }}
                            disabled={disabledButton}
                        >
                            <div style={{ width: 150 }}>
                                {loading ? <ButtonLoading /> : 'Pay 0.72% fee'}
                            </div>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

    async function execute(saleId: string) {
        setLoading(true)
        try {
            await service.createAnalysis(saleId)
            success('Send Fraud Analysis')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } catch (e) {
            danger(`Error: ${e.response.data.message}`)
        } finally {
            setShow(false)
            setLoading(false)
        }
    }
}

export default ExecuteFraudAnalysisButton
