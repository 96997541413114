import { AxiosReturnFile } from '../HttpCommon'

export function downloadFile(address: string, fileName: string, headers: {}) {
    return new Promise((resolve, reject) => {
        return AxiosReturnFile.get(address, headers)
            .then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
            .catch((e: any) => {
                console.error('error:', e)
                reject(e)
            })
    })
}
