import SpecificSale, { Shipment } from './SpecificSale'
import { SellerStatus } from './SellerStatus'
import { BuyerStatus } from './BuyerStatus'
import Sale from './Sale'

export enum Perspective {
    BUYER,
    SELLER,
}

export function hasLabel(
    sale: SpecificSale | Sale,
    perspective: Perspective
): boolean | Shipment {
    let shipment: Shipment

    if (sale.sellerStatus === SellerStatus.DECOUPLED) return false

    if (perspective === Perspective.SELLER) {
        if (!sale.sellerShipmentDetails?.postLocationDetails.shipment?.hasLabel)
            return false
        shipment = sale.sellerShipmentDetails.postLocationDetails.shipment
    } else {
        if (!sale.buyerShipmentDetails?.shipment?.hasLabel) return false
        shipment = sale.buyerShipmentDetails.shipment
    }

    return shipment
}

export function isDecoupled(sale: SpecificSale | Sale) {
    return (
        !sale.isFromDecouple &&
        !(
            sale.sellerStatus === SellerStatus.DECOUPLED ||
            sale.sellerStatus === SellerStatus.COMPLETED
        )
    )
}

export function isMarkedBuyerCollected(sale: SpecificSale | Sale) {
    return (
        sale.buyerStatus === BuyerStatus.WAITING_SHIPMENT &&
        sale.sellerStatus === SellerStatus.COMPLETED
    )
}

export function isMarkedBuyerDelivered(sale: SpecificSale | Sale) {
    return (
        sale.buyerStatus === BuyerStatus.IN_TRANSIT &&
        sale.sellerStatus === SellerStatus.COMPLETED
    )
}

export function isMarkedSellerDelivered(sale: SpecificSale | Sale) {
    return sale.sellerStatus === SellerStatus.IN_TRANSIT
}

export function isMarkedSellerAsFailed(sale: SpecificSale | Sale) {
    return (
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_DROPOFF ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_COLLECTION
    )
}

export function isGradeVerification(sale: SpecificSale | Sale) {
    return sale.sellerStatus === SellerStatus.GRADE_VERIFICATION
}

export function isMarkedSellerCollected(sale: SpecificSale | Sale) {
    return (
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_DROPOFF ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_COLLECTION
    )
}

export function isCancelable(sale: SpecificSale | Sale) {
    const buyerAllowedStatusForCancel =
        sale.buyerStatus === BuyerStatus.WAITING_SHIPMENT ||
        sale.buyerStatus === BuyerStatus.WAITING_PAYMENT_APPROVAL ||
        sale.buyerStatus === BuyerStatus.IN_TRANSIT

    const sellerAllowedStatusForCancel =
        sale.sellerStatus === SellerStatus.FAILED_SHIPMENT_COLLECTION ||
        sale.sellerStatus === SellerStatus.FAILED_SHIPMENT_DROPOFF ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_DROPOFF ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_COLLECTION ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_INFO ||
        sale.sellerStatus === SellerStatus.IN_TRANSIT

    return (
        (buyerAllowedStatusForCancel && sellerAllowedStatusForCancel) ||
        (sale.isFromDecouple && sellerAllowedStatusForCancel) ||
        (sale.isFromDecouple && buyerAllowedStatusForCancel)
    )
}

export function isUpdatedPrice(sale: SpecificSale | Sale) {
    return (
        sale.isFromDecouple &&
        (sale.sellerStatus === SellerStatus.GRADE_VERIFICATION ||
            sale.buyerStatus === BuyerStatus.WAITING_PAYMENT_APPROVAL)
    )
}

export function isSellerWaitingShipment(sale: SpecificSale | Sale) {
    return (
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_INFO ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_COLLECTION ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_DROPOFF
    )
}

export function isPaymentApproval(sale: SpecificSale | Sale) {
    return (
        sale.isFromDecouple &&
        sale.buyerStatus === BuyerStatus.WAITING_PAYMENT_APPROVAL
    )
}

export function isUpdatedProduct(sale: SpecificSale | Sale) {
    const isAcceptableSellerStatuses =
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_DROPOFF ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_COLLECTION ||
        sale.sellerStatus === SellerStatus.WAITING_SHIPMENT_INFO ||
        sale.sellerStatus === SellerStatus.GRADE_VERIFICATION
    const isAcceptableBuyerStatuses =
        sale.buyerStatus === BuyerStatus.WAITING_SHIPMENT ||
        sale.buyerStatus === BuyerStatus.WAITING_PAYMENT_APPROVAL ||
        sale.buyerStatus === BuyerStatus.INSTITUTIONAL_PENDING_PAYMENT ||
        sale.buyerStatus === BuyerStatus.PENDING_RETURN

    return isAcceptableSellerStatuses && isAcceptableBuyerStatuses
}

export function isBuyerCompleted(sale: SpecificSale | Sale) {
    return sale.buyerStatus === BuyerStatus.COMPLETED
}

export function isSellerCompleted(sale: SpecificSale | Sale) {
    return sale.sellerStatus === SellerStatus.COMPLETED
}
