import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { RejectActionButton } from '../../../common/components/button/RejectActionButton'

interface RejectRequestButtonProps {
    request: IReturningRequest
}

export const RejectRequestButton = ({ request }: RejectRequestButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function reject(description: string) {
        const response = await returningRequestService.rejectRequest(
            request.requestId,
            description
        )
        if (response) {
            success('Request rejected!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not reject the request!')
        }
    }

    return (
        <RejectActionButton
            modalTitle="You are about to reject a Return Request"
            modalText="Please provide a description for the rejection."
            buttonText="Reject"
            action={reject}
        />
    )
}
