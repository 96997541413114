import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Button, Modal, Form } from 'react-bootstrap-v5'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { SHIPMENT_V2_ROUTE } from '../../../../common/RouteConstants'
import { httpHelper } from '../../../../common/HttpCommon'

export const AttachTrackingButton = (props: { shipmentId: String }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Link
                to="#"
                className="fw-bold fs-7"
                onClick={() => setShowModal(true)}
            >
                <span>
                    <KTSVG
                        path={'/media/icons/duotune/art/art005.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Edit
                </span>
            </Link>
            <AttachTrackingModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                shipmentId={props.shipmentId}
            />
        </>
    )
}

const AttachTrackingModal = (props: {
    show: boolean
    handleClose: () => void
    shipmentId: String
}) => {
    const [trackingNumber, setTrackingNumber] = useState('')
    const [provider, setProvider] = useState('DPD_LOCAL')
    const [disabledButton, setDisabledButton] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const { success, danger } = useAlert()

    async function attachTrackingNumber() {
        try {
            setLoading(true)
            const body = {
                trackingNumber: trackingNumber,
                provider: provider,
            }
            await httpHelper.put(
                `${SHIPMENT_V2_ROUTE}/${props.shipmentId}/tracking/attach`,
                undefined,
                body
            )
            success('Tracking number attached to shipment!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } catch (e: any) {
            danger(
                `Error while attaching tracking number. message: "${e.message}" response: "${e.response?.data?.message}"`
            )
        } finally {
            props.handleClose()
            setLoading(false)
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton className={'modal-header'}>
                <Modal.Title>
                    You are about to <strong>update</strong> Tracking Number
                </Modal.Title>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                    onClick={props.handleClose}
                    aria-label="Close"
                >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                </div>
            </Modal.Header>

            <Modal.Body>
                <Form.Control
                    type="text"
                    placeholder="New Tracking Number"
                    value={trackingNumber}
                    onChange={(e) => {
                        setTrackingNumber(e.target.value)
                        if (e.target.value === '') setDisabledButton(true)
                        else setDisabledButton(false)
                    }}
                />
                <Form.Select
                    value={provider}
                    aria-label="Select a carrier"
                    onChange={(e: any) => setProvider(e.target.value)}
                >
                    <option value="DPD_LOCAL">DPD</option>
                    <option value="ROYAL_MAIL">Royal Mail</option>
                </Form.Select>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={!isLoading ? attachTrackingNumber : undefined}
                    disabled={disabledButton || isLoading}
                >
                    {isLoading ? 'Loading...' : 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
