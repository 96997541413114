import { httpHelper } from '../../../common/HttpCommon'
import { PRODUCTS_ROUTE } from '../../../common/RouteConstants'
import { IProduct } from '../../data/Product'
import { ProductService, updateRequest } from '../ProductService'

export class ProductServiceImpl implements ProductService {
    find(productId: string): Promise<IProduct> {
        return httpHelper.get(`${PRODUCTS_ROUTE}/${productId}`)
    }

    update<updateRequest>(productId: string, data: {}): Promise<IProduct> {
        return httpHelper.put(`${PRODUCTS_ROUTE}/${productId}`, undefined, data)
    }
}
