import React from 'react'

const PAGINATION_MAX_PAGES_DISPLAY: number = 6

type Props = {
    totalPages: number
    currentPage: number
    numberOfElements: number
    totalElements: number
    onClick: (page: number) => void
}

type PageItem = { page: string; isActive: boolean; blocked?: boolean }

export const DynamicPagination = ({
    totalPages,
    currentPage,
    numberOfElements,
    totalElements,
    onClick,
}: Props) => {
    function buildPagination(): PageItem[] {
        const middle = PAGINATION_MAX_PAGES_DISPLAY / 2

        let startRange = 0
        let endRange = 0
        let includeFirstPage = false
        let includeLastPage = false

        if (currentPage <= middle) {
            startRange = 1
            endRange = PAGINATION_MAX_PAGES_DISPLAY
            includeLastPage = true
        }

        if (currentPage >= middle) {
            startRange = currentPage - middle + 2
            endRange = currentPage + middle
            includeFirstPage = true
            includeLastPage = true
        }

        if (currentPage + middle >= totalPages) {
            endRange = totalPages
            includeFirstPage = true
        }

        const pageItems: PageItem[] = []

        if (includeFirstPage) {
            pageItems.push({ page: '1', isActive: false })
            pageItems.push({ page: '...', isActive: false, blocked: true })
        }

        for (let i = startRange; i <= endRange; i++) {
            if (i - 1 === currentPage) {
                pageItems.push({ page: i.toString(), isActive: true })
            } else {
                pageItems.push({ page: i.toString(), isActive: false })
            }
        }

        if (includeLastPage) {
            pageItems.push({ page: '...', isActive: false, blocked: true })
            pageItems.push({ page: totalPages.toString(), isActive: false })
        }

        return pageItems
    }

    const pages = buildPagination()

    const firstPageEntry =
        currentPage === totalPages - 1
            ? totalElements + 1 - numberOfElements
            : currentPage * numberOfElements + 1

    const lastPageEntry =
        currentPage === totalPages - 1
            ? totalElements
            : (currentPage + 1) * numberOfElements

    return (
        <>
            <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6 fw-bold text-gray-700">
                    {`Showing ${firstPageEntry} to ${lastPageEntry} of ${totalElements} entries`}
                </div>

                <ul className="pagination">
                    <li className="page-item previous">
                        <a
                            href="#"
                            onClick={(e: any) => {
                                e.preventDefault()
                                onClick(Number(currentPage - 1))
                            }}
                            className="page-link"
                        >
                            <i className="previous" />
                        </a>
                    </li>
                    {pages.map((p) => {
                        return (
                            <li
                                className={`page-item ${
                                    p.isActive ? 'active' : ''
                                } ${p.blocked ? 'disabled' : ''}`}
                            >
                                <a
                                    href="#"
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        onClick(Number(p.page))
                                    }}
                                    className="page-link"
                                >
                                    {p.page}
                                </a>
                            </li>
                        )
                    })}
                    <li className="page-item next">
                        <a
                            href="#"
                            onClick={(e: any) => {
                                e.preventDefault()
                                onClick(Number(currentPage + 1))
                            }}
                            className="page-link"
                        >
                            <i className="next" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}
