import { ReactElement, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { httpHelper } from '../../HttpCommon'
import { IPagination } from '../../rest/IPagination'
import { DynamicFilter, Filter, FilterValue } from './DynamicFilter'
import { DynamicPagination } from './DynamicPagination'

type DynamicTableProps<P> = {
    title?: string
    description?: string | null
    resource: string
    columns: {
        header: string
        minWidth?: number | null
        render: (data: P) => ReactElement | null
    }[]
    filters: Filter[]
    lineAction?: (data: P) => void
    currentScreen?: string
}

export const DynamicTable = <P extends unknown>({
    title,
    description,
    columns,
    resource,
    filters,
    lineAction,
}: DynamicTableProps<P>) => {
    const history = useHistory()
    const { pathname, search } = useLocation()
    const urlSearchParams = new URLSearchParams(search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const [data, setData] = useState<P[]>([])
    const [totalPages, setTotalPages] = useState<number>(0)
    const [numberOfElements, setNumberOfElements] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(
        Number(params['page']) || 0
    )
    const [loading, setLoading] = useState(false)
    const [totalElements, setTotalElements] = useState<number>(0)

    const [stateFilters, setStateFilters] = useState(
        filters.map((filter) => ({
            ...filter,
            ...(params[filter.queryParam] && {
                defaultValue: params[filter.queryParam],
            }),
        }))
    )
    const [filtersValue, setFiltersValue] = useState<FilterValue[]>(
        getInitialFilterValues()
    )
    async function fetchData() {
        setLoading(true)
        const resp: IPagination<P[]> = await httpHelper.get(
            resource,
            getFilterQueryParams()
        )
        getFilterValues()

        if (resp instanceof Array) {
            setData(resp)
            setTotalPages(1)
            setCurrentPage(-1)
            setTotalElements(resp.length)
        } else {
            setData(resp.content)
            setTotalPages(resp.totalPages)
            setCurrentPage(resp.number)
            setTotalElements(resp.totalElements)
            setNumberOfElements(resp.numberOfElements)
        }
        setLoading(false)
        return resp.content
    }

    function getFilterQueryParams(): URLSearchParams {
        const params = new URLSearchParams()

        filtersValue.forEach((f) => {
            if (f.value) params.append(f.queryParam, f.value)
        })

        params.append('page', currentPage.toString())
        params.append('size', '10')

        return params
    }

    function onPageChange(page: number) {
        const queryParams = new URLSearchParams(search)
        queryParams.set('page', (page - 1).toString())
        history.replace({ search: queryParams.toString() })

        setCurrentPage(page - 1)
    }

    function onResetFilters(filterValues: FilterValue[]) {
        setStateFilters(filters)
        history.replace({ search: '' })
        setFiltersValue([])
    }

    function updateUrl(filterValues: FilterValue[]) {
        if (filterValues.length) {
            let queryParams = new URLSearchParams(search)
            filterValues.forEach((filter) => {
                if (filter.value) {
                    queryParams.set(filter.queryParam, filter.value)
                } else {
                    queryParams.delete(filter.queryParam)
                }
            })
            queryParams.delete('page')
            history.replace({ search: queryParams.toString() })
        }
    }

    function onApplyFilters(filterValues: FilterValue[]) {
        setCurrentPage(0)
        updateUrl(filterValues)
        setFiltersValue(filterValues)
    }

    function getFilterValues(): FilterValue[] {
        return stateFilters.map((f) => {
            return {
                queryParam: f.queryParam,
                value: f.defaultValue || '',
            }
        })
    }

    function getInitialFilterValues(): FilterValue[] {
        return filters.map((f) => {
            return {
                queryParam: f.queryParam,
                value: params[f.queryParam] || f.defaultValue || '',
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, filtersValue, pathname, resource])

    let key = 0
    let headerKey = 0

    return (
        <>
            <div className="card">
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">
                            {title}
                        </span>
                        <span className="text-muted mt-1 fw-bold fs-7">
                            {description}
                            {`${loading ? '' : `(${totalElements})`}`}
                        </span>
                    </h3>
                    <div
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Click to add a user"
                    >
                        {!!filters?.length && (
                            <div className="me-0">
                                <DynamicFilter
                                    onResetFiltersCallback={onResetFilters}
                                    onApplyFiltersCallback={onApplyFilters}
                                    filters={stateFilters}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className="card-body py-3">
                    {/* begin::Table container */}
                    <div className="table-responsive">
                        {loading ? (
                            <div
                                className="d-flex justify-content-center text-primary align-items-center"
                                style={{ height: '300px' }}
                            >
                                <div
                                    className="spinner-border"
                                    role="status"
                                ></div>
                            </div>
                        ) : (
                            <table
                                className={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4${
                                    lineAction
                                        ? ' table-hover cursor-pointer'
                                        : ''
                                }`}
                            >
                                {/* begin::Table head */}
                                <thead>
                                    <tr className="fw-bolder text-muted">
                                        <th className="w-25px">
                                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="1"
                                                    data-kt-check="true"
                                                    data-kt-check-target=".widget-9-check"
                                                />
                                            </div>
                                        </th>
                                        {columns.map((data) => {
                                            return (
                                                <th
                                                    key={`headerKey${headerKey++}`}
                                                >
                                                    {data.header}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {data.map((o: any) => {
                                        return (
                                            <tr
                                                onClick={
                                                    lineAction
                                                        ? () => lineAction(o)
                                                        : undefined
                                                }
                                                key={`tableKey${key++}`}
                                            >
                                                <td>
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input
                                                            className="form-check-input widget-9-check"
                                                            type="checkbox"
                                                            value="1"
                                                        />
                                                    </div>
                                                </td>
                                                {columns.map((column) => (
                                                    <td>{column.render(o)}</td>
                                                ))}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        )}
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                    <DynamicPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        numberOfElements={numberOfElements}
                        totalElements={totalElements}
                        onClick={onPageChange}
                    />
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}
