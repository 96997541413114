import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { saleColumns } from '../Sales'
import { SALE_ROUTE } from '../../../common/RouteConstants'

export function DecoupleView(
    sale: SpecificSale,
    currentPage: string
): Array<CustomRow> {
    const decoupleId = sale?.decouple?.decoupleId || ''

    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title={'Sale'}
                        description={'List of sales'}
                        resource={`${SALE_ROUTE}/?page=1&pageSize=25&source=admin&decoupleId=${decoupleId}`}
                        columns={saleColumns}
                        filters={[]}
                        currentScreen={currentPage}
                    />
                ),
            },
        ],
    })
    return renderRows
}
