import {
    EVENT_PATH,
    ORDER_PATH,
    RETURNING_PATH,
    SALE_PATH,
} from '../../../app/backoffice/common/PathConstants'
import useBackofficeUser, {
    getUserPaths,
} from '../../../app/backoffice/common/secure/useBackofficeUser'

export interface MenuItemProps {
    title: string
    icon: string
    to: string
}

const salesMenuItem: MenuItemProps = {
    title: 'Sales',
    icon: '/media/icons/duotune/ecommerce/ecm001.svg',
    to: '/sale',
}
const ordersMenuItem: MenuItemProps = {
    title: 'Orders',
    to: '/order',
    icon: '/media/icons/duotune/ecommerce/ecm002.svg',
}
const lostParcelsMenuItem: MenuItemProps = {
    title: 'Lost Parcels',
    to: '/lost-parcels',
    icon: '/media/flags/white_flag.svg',
}
const returnReasonsMenuItem: MenuItemProps = {
    title: 'Reasons',
    icon: '/media/icons/duotune/ecommerce/ecm006.svg',
    to: '/return/reasons',
}
const returnRequestsMenuItem: MenuItemProps = {
    title: 'Requests',
    to: '/return/requests',
    icon: '/media/icons/duotune/ecommerce/ecm009.svg',
}
const decouplesMenuItem: MenuItemProps = {
    title: 'Decouples',
    to: '/decouple',
    icon: '/media/icons/duotune/ecommerce/ecm004.svg',
}
const settingsMenuItem: MenuItemProps = {
    title: 'Event Configurations',
    to: '/event',
    icon: '/media/icons/duotune/general/gen016.svg',
}

export const menuItems = {
    sales: salesMenuItem,
    orders: ordersMenuItem,
    lostParcel: lostParcelsMenuItem,
    returnReasons: returnReasonsMenuItem,
    returnRequests: returnRequestsMenuItem,
    decouples: decouplesMenuItem,
    settings: settingsMenuItem,
}

export interface MenuItemWithSub {
    title: string
    icon?: string
    subItems: Array<MenuItemProps>
    to: string
}

export const useMenuButtonsMapper = () => {
    const { backofficeUser } = useBackofficeUser()

    const getMenuButtons = (): Array<MenuItemWithSub> => {
        const paths = backofficeUser.groups ? getUserPaths(backofficeUser) : []
        const menus: Array<MenuItemWithSub> = [
            {
                title: 'Customers',
                subItems: [],
                to: '/customers',
            },
            {
                title: 'Orders & Sales',
                subItems: [],
                icon: '/media/icons/duotune/ecommerce/ecm008.svg',
                to: '/orders',
            },
            {
                title: 'Return',
                subItems: [],
                icon: '/media/icons/duotune/arrows/arr092.svg',
                to: '/return',
            },
            {
                title: 'Shopping',
                subItems: [],
                to: '/shopping',
            },
            {
                title: 'Finance',
                subItems: [],
                to: '/finance',
            },
            {
                title: 'Reports',
                subItems: [],
                to: '/reports',
            },
            {
                title: 'Settings',
                subItems: [],
                icon: '/media/icons/duotune/general/gen019.svg',
                to: '/settings',
            },
        ]

        paths.forEach((path) => {
            switch (path) {
                case SALE_PATH:
                    menus[1].subItems.push(menuItems.sales)
                    menus[1].subItems.push(menuItems.lostParcel)
                    break
                case ORDER_PATH:
                    menus[1].subItems.push(menuItems.orders)
                    break
                case RETURNING_PATH:
                    menus[2].subItems.push(
                        menuItems.returnReasons,
                        menuItems.returnRequests
                    )
                    break
                // case DECOUPLE_PATH:
                //     menus[1].subItems.push(menuItems.decouples)
                // break
                case EVENT_PATH:
                    menus[6].subItems.push(menuItems.settings)
            }
        })
        return menus.filter((m) => m.subItems.length)
    }

    return { getMenuButtons }
}
