import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Sale from '../../data/Sale'
import { isUpdatedPrice } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import { httpHelper } from '../../../common/HttpCommon'
import { DECOUPLE_PRICES_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../data/SpecificSale'
import './../../../common/style/modal.css'
import { Button, Form, Modal } from 'react-bootstrap-v5'

const UpdatePriceButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const [disabledButton, setDisabledButton] = useState(true)
    const [price, setPrice] = useState('')
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        setDisabledButton(true)
        setPrice('')
    }

    if (!isUpdatedPrice(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/finance/fin010.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Update Price
                </div>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        You are about to Change sale price
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Please set the <strong>new price</strong> for this sale.
                    </p>
                    <Form.Control
                        type={'number'}
                        placeholder={'New Price'}
                        value={price}
                        onChange={(e) => {
                            setPrice(e.target.value)
                            if (e.target.value !== '') setDisabledButton(false)
                            else setDisabledButton(true)
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            update(sale.saleId).then()
                            handleClose()
                        }}
                        disabled={disabledButton}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function update(saleId: string) {
        let body = {
            price: price,
        }

        await httpHelper
            .put(`${DECOUPLE_PRICES_ROUTE}/${saleId}`, undefined, body)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could not update sale!')
                else {
                    success('Sale updated!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default UpdatePriceButton
