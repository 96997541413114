import React from 'react'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import { PRODUCTS_ROUTE } from '../../common/RouteConstants'
import { IProduct } from '../data/Product'
import { TableBoldTextField } from '../../common/components/table/TableBoldTextField'
import { ProductCondition } from '../../sale/component/label/Condition'
import { TableLinkField } from '../../common/components/table/TableLinkField'
import { DynamicSearch } from '../../common/components/search/DynamicSearch'
import { httpHelper } from '../../common/HttpCommon'
import { useHistory } from 'react-router-dom'
import RestResponse from '../../common/RestResponse'
import { TableItemImage } from '../../common/components/table/TableItemImage'
import { FormatOptionLabel, ValueOptionLabel } from './ProductSearchLabels'
import { MonetaryField } from '../../common/components/fields/MonetaryField'

const idColumn = (data: IProduct) => {
    if (!data) return <></>
    return (
        <TableLinkField to={`/product/${data.productId}/summary`}>
            {data.productId}
        </TableLinkField>
    )
}

const productColumn = (data: IProduct) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <TableItemImage url={data?.thumbnail?.url} />
            <div className="d-flex justify-content-start flex-column">
                <TableBoldTextField>{data?.title}</TableBoldTextField>
            </div>
        </div>
    )
}

const gradeColumn = (data: IProduct) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <ProductCondition status={data.grade.description} />
        </div>
    )
}

const bestBidColumn = (data: IProduct) => {
    if (!data) return <></>
    return MonetaryField(data.bestBid, data.currency)
}

const bestAskColumn = (data: IProduct) => {
    if (!data) return <></>
    return MonetaryField(data.bestAsk, data.currency)
}

const columns = [
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Product',
        render: productColumn,
    },
    {
        header: 'Grade',
        render: gradeColumn,
    },
    {
        header: 'Best Bid',
        render: bestBidColumn,
    },
    {
        header: 'Best Ask',
        render: bestAskColumn,
    },
]

async function loadOptions(inputValue: string) {
    const data = await httpHelper.get<RestResponse<IProduct[]>>(
        `${PRODUCTS_ROUTE}?title=${inputValue}`
    )
    return data.content
}

const Products: React.FC = () => {
    const history = useHistory()

    const handleSelect = (product: IProduct) => {
        history.push(`/product/${product.productId}/summary`)
    }

    return (
        <>
            <DynamicSearch
                title="Product"
                subtitle="Search by name"
                onSelect={handleSelect}
                fetchData={loadOptions}
                formatOptionLabel={FormatOptionLabel}
                ValueOptionLabel={ValueOptionLabel}
            />
            <DynamicTable
                description={'List of products'}
                resource={PRODUCTS_ROUTE}
                columns={columns}
                filters={[]}
            />
        </>
    )
}

export { Products, columns }
