import IUser from '../data/User'

export const FormatOptionLabel = (value: IUser) => {
    return (
        <div>
            <span className="text-dark fw-bolder d-block fs-6">
                {value.firstName} {value.lastName}
            </span>
            <span className="text-muted fw-bold text-muted d-block fs-7">
                {value.email}
            </span>
        </div>
    )
}

export const ValueOptionLabel = (props: any) => {
    return (
        <div
            style={{
                gridArea: '1 / 1 / 2 / 3',
                marginLeft: '2px',
                marginRight: '2px',
                maxWidth: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
            }}
        >
            <span>{props.data.email}</span>
        </div>
    )
}
