export default interface IUser {
    userId: string
    email: string
    firstName: string
    lastName: string
    nickname: string
    phoneNumber: string
    created: string
    countryCodeISO: string
    avatar: string
    country: string
    status: string
    documentNumber: string
    documentType: string
    lastLogin: Date
    kycStatus: KycStatus
    institutional: boolean
    appleIdentityId: string
    session: string
    utm: string
    activeSessions: string
    password: string
    confirmPassword: string
    emailVerificationCode: string
    passwordless: boolean
    standardFee: number
}

export enum KycStatus {
    VERIFIED = 'VERIFIED',
    NOT_VERIFIED = 'NOT_VERIFIED',
}

export interface SimpleUser {
    fullName: string
    email: string
    userId: string
    phoneNumber?: string
    document?: Document
}

export interface Document {
    type: string
    value: string
}
