import SpecificSale from '../../data/SpecificSale'

type Props = {
    sale: SpecificSale
}

export const SaleType = ({ sale }: Props) => {
    let operation = 'INSTANT MATCH'

    if (!sale.isBuyerOrderFromBid && sale.isSellerOrderFromAsk)
        operation = 'BUY NOW'
    else if (sale.isBuyerOrderFromBid && !sale.isSellerOrderFromAsk)
        operation = 'SELL NOW'

    switch (operation) {
        case 'BUY NOW':
            return <span className="badge badge-primary">{operation}</span>
        case 'SELL NOW':
            return <span className="badge badge-success">{operation}</span>
        case 'INSTANT MATCH':
            return <span className="badge badge-info">{operation}</span>
        default: {
            return <span className="badge badge-dark">{operation}</span>
        }
    }
}
