import _ from 'lodash'
import AsyncSelect from 'react-select/async'
import React, { useEffect, useState } from 'react'
import { FormatOptionLabelMeta } from 'react-select/base'
import { httpHelper } from '../../HttpCommon'
import { ActionMeta, SingleValue } from 'react-select'

const reactSelectStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#eef3f7',
        height: '45px',
        borderColor: '#eef3f7',
    }),
}

export type ResourceType = 'USER' | 'PRODUCT'

type Props = {
    title: string
    termQueryParam?: string
    resourceUrl: string
    onChangeCallback: (newValue: string) => void
    resourceType?: ResourceType
}

export const DynamicFilterRemote = ({
    title,
    termQueryParam = 'term',
    resourceUrl,
    onChangeCallback,
    resourceType = 'USER',
}: Props) => {
    const [value, setValue] = useState<string>('')

    function noOptionsMessage(term: { inputValue: string }) {
        return term.inputValue.length > 3 ? (
            <span>No options</span>
        ) : (
            <span>Search input must be at least 3 characters</span>
        )
    }

    interface BuildOptionsDataProps {
        primaryText?: string
        secondaryText?: string
        selectedTextProperty?: string
        value?: string
    }
    const buildOptionData = (data: BuildOptionsDataProps) => {
        return {
            primaryText: data.primaryText || '',
            secondaryText: data.secondaryText || '',
            selectedTextProperty: data.selectedTextProperty || '',
            value: data.value || '',
        }
    }

    const getOptionData = (value: any) => {
        if (!value) return buildOptionData({})
        switch (resourceType) {
            case 'USER':
                return buildOptionData({
                    primaryText: `${value.firstName} ${value.lastName}`,
                    secondaryText: value.email,
                    selectedTextProperty: 'email',
                    value: value.userId,
                })
            case 'PRODUCT':
                return buildOptionData({
                    primaryText: value.title,
                    selectedTextProperty: 'title',
                    secondaryText: value.grade?.description || '',
                    value: value.productId,
                })
        }
    }

    const parseData = (d: any) => {
        switch (resourceType) {
            case 'USER':
                return d
            case 'PRODUCT':
                return d.products
            default:
                return d
        }
    }

    function formatOptionLabel(
        value: any,
        formatOptionLabelMeta: FormatOptionLabelMeta<string>
    ) {
        formatOptionLabelMeta.inputValue = value.enumValues
        const optionData = getOptionData(value)
        return (
            <div>
                <span className="text-dark fw-bolder d-block fs-6">
                    {optionData.primaryText}
                </span>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    {optionData.secondaryText}
                </span>
            </div>
        )
    }

    const ValueOptionLabel = (props: any) => {
        const selectedTextProperty = getOptionData(
            props.data
        ).selectedTextProperty
        return (
            <div
                style={{
                    gridArea: '1 / 1 / 2 / 3',
                    marginLeft: '2px',
                    marginRight: '2px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                }}
            >
                <span>{props.data[selectedTextProperty]}</span>
            </div>
        )
    }

    function loadOptions(inputValue: string, callback: any) {
        const params = new URLSearchParams()
        params.append(termQueryParam, inputValue)
        httpHelper.get(resourceUrl, params).then((r: any) => {
            callback(parseData(r))
        })
    }

    function onChange(
        newValue: SingleValue<any>,
        actionMeta: ActionMeta<string>
    ) {
        setValue(getOptionData(newValue).value)
    }

    useEffect(() => {
        onChangeCallback(value)
    }, [value])

    return (
        <div className="mb-5 col-md-6">
            <label className="form-label fw-bold">{title}:</label>
            <div>
                <AsyncSelect
                    blurInputOnSelect={true}
                    closeMenuOnSelect={false}
                    isClearable
                    styles={reactSelectStyle}
                    formatOptionLabel={formatOptionLabel}
                    components={{ SingleValue: ValueOptionLabel }}
                    getOptionValue={(option: any) =>
                        getOptionData(option).value
                    }
                    onChange={onChange}
                    noOptionsMessage={noOptionsMessage}
                    loadOptions={_.debounce(loadOptions, 400)}
                />
            </div>
        </div>
    )
}
