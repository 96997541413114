import { CustomRow } from '../../../common/components/data/CardUtil'
import { SellerStatus } from '../label/SellerStatus'
import { BuyerStatus } from '../label/BuyerStatus'
import SpecificSale from '../../data/SpecificSale'
import { ProductCondition } from '../label/Condition'
import { MonetaryField } from '../../../common/components/fields/MonetaryField'
import { DataField } from '../../../common/components/fields/DataField'
import { SaleType } from '../label/Type'
import { TextField } from '../../../common/components/fields/TextField'
import { CardLinkField } from '../../../common/components/card/CardLinkField'
import { BoldTextField } from '../../../common/components/fields/BoldTextField'
import PaymentDetails from '../../data/PaymentDetails'

export function SummaryView(
    sale?: SpecificSale,
    paymentDetails?: PaymentDetails
): Array<CustomRow> {
    if (!sale) return []

    let cardData: Array<CustomRow> = [
        {
            column: [
                {
                    title: 'Seller',
                    content: (
                        <CardLinkField
                            to={`/user/${sale.sellerDetail?.userId}/summary`}
                        >
                            {sale.sellerDetail?.fullName}
                        </CardLinkField>
                    ),
                },
                {
                    title: 'Buyer',
                    content: (
                        <CardLinkField
                            to={`/user/${sale.buyerDetail?.userId}/summary`}
                        >
                            {sale.buyerDetail?.fullName}
                        </CardLinkField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Email',
                    content: <TextField>{sale.sellerDetail?.email}</TextField>,
                },
                {
                    title: 'Buyer Email',
                    content: <TextField>{sale.buyerDetail?.email}</TextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: `Seller ${
                        sale.sellerDetail?.document?.type ?? 'Document'
                    }`,
                    content: (
                        <TextField>
                            {sale.sellerDetail?.document?.value}
                        </TextField>
                    ),
                },
                {
                    title: `Buyer ${
                        paymentDetails?.userDocument?.type ?? 'Document'
                    }`,
                    content: (
                        <TextField>
                            {paymentDetails?.userDocument?.value}
                        </TextField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Phone Number',
                    content: (
                        <TextField>{sale.sellerDetail?.phoneNumber}</TextField>
                    ),
                },
                {
                    title: 'Buyer Phone Number',
                    content: (
                        <TextField>{sale.buyerDetail?.phoneNumber}</TextField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller OrderId',
                    content: (
                        <CardLinkField
                            to={`/order/${sale.sellerOrderId}/summary`}
                        >
                            {sale.sellerOrderId}
                        </CardLinkField>
                    ),
                },
                {
                    title: 'Buyer OrderId',
                    content: (
                        <CardLinkField
                            to={`/order/${sale.buyerOrderId}/summary`}
                        >
                            {sale.buyerOrderId}
                        </CardLinkField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Status',
                    content: (
                        <SellerStatus status={sale.sellerStatus.toString()} />
                    ),
                },
                {
                    title: 'Buyer Status',
                    content: (
                        <BuyerStatus status={sale.buyerStatus.toString()} />
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Created',
                    content: DataField(sale.created),
                },
                {
                    title: 'Condition',
                    content: (
                        <ProductCondition
                            status={
                                sale.productDetail?.grade.description.toString()!!
                            }
                        />
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Product',
                    content: (
                        <CardLinkField
                            to={`/product/${sale.productId}/summary`}
                        >
                            {sale.productDetail?.title}
                        </CardLinkField>
                    ),
                },
                {
                    title: 'Sale Type',
                    content: <SaleType sale={sale} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Price',
                    content: MonetaryField(sale.price, sale.currency),
                },
                {
                    title: 'Fee',
                    content: <TextField>{sale.fee}%</TextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Total',
                    content: MonetaryField(sale.sellerTotal, sale.currency),
                },
                {
                    title: 'Fee Amount',
                    content: MonetaryField(sale.feeAmount, sale.currency),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Buy Side Agent',
                    content: (
                        <TextField>{sale?.dojiUserAgent?.buySide}</TextField>
                    ),
                },
                {
                    title: 'Sell Side Agent',
                    content: (
                        <TextField>{sale?.dojiUserAgent?.sellSide}</TextField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Buyer Total',
                    content: MonetaryField(sale.buyerTotal, sale.currency),
                },
                {
                    title: 'Cancellation Description',
                    content: (
                        <TextField>{sale.cancellationDescription}</TextField>
                    ),
                },
            ],
        },
    ]

    cardData.push({
        column: [
            {
                title: 'Coupon Code',
                content: <TextField>{sale.buyerCoupon?.couponCode}</TextField>,
            },
            {
                title: 'Coupon Discount',
                content: MonetaryField(
                    sale.buyerCoupon?.discountPrice,
                    sale.currency
                ),
            },
        ],
    })
    cardData.push({
        column: [
            {
                title: 'Delivery Fee',
                content: MonetaryField(
                    sale?.sellerShipmentDetails?.fee,
                    sale.currency
                ),
            },
            sale.decoupleId
                ? {
                      title: 'Decouple',
                      content: (
                          <CardLinkField
                              to={`/decouple/${sale.decoupleId}/summary`}
                          >
                              Decouple
                          </CardLinkField>
                      ),
                  }
                : {
                      title: '',
                      content: '',
                  },
        ],
    })

    return cardData
}
