let apiUrl = 'https://api.doji.co.uk'
let dojiEnv = 'production'
let siteUrl = 'https://doji.co.uk'

if (process.env.NODE_ENV === 'development') {
    apiUrl = 'https://api.staging.doji.co'
    dojiEnv = 'development'
}

export const getDojiEnv = (): string => dojiEnv.toUpperCase()
export const getSiteUrl = (): string => siteUrl

export default apiUrl
