import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { MenuItem } from '../../common/components/data/CardUtil'
import IUser from '../data/User'
import { UserServiceImpl } from '../service/rest/UserServiceImpl'
import CancelAllOrdersButton from './buttons/CancelAllOrdersButton'
import UpdateTransactionFeeButton from './buttons/UpdateTransactionFeeButton'
import { UpdateUserInstitutionalButton } from './buttons/UpdateUserInstitutionalButton'
import { UploadOrdersCsv } from './buttons/UploadOrdersCsv'
import { getRows } from './RenderRows'

function menuRules(user: IUser | undefined, pathname: string) {
    let list = []

    list.push(
        { title: 'SUMMARY', to: `${pathname}/summary` },
        { title: 'ORDERS', to: `${pathname}/user-orders` },
        { title: 'SALES', to: `${pathname}/user-sales` },
        { title: 'ADDRESSES', to: `${pathname}/addresses` },
        { title: 'PAYMENT METHODS', to: `${pathname}/payment-methods` },
        { title: 'WALLETS', to: `${pathname}/wallets` },
        { title: 'WITHDRAWALS', to: `${pathname}/withdrawals` }
    )
    return list
}

export function UserView() {
    const [user, setUser] = useState<IUser>()
    const { userId } = useParams<{ userId: string }>()
    const userService = new UserServiceImpl()
    const [loading, setLoading] = useState(false)

    const fetchData = () => {
        setLoading(true)
        userService
            .findById(userId)
            .then((newUser) => setUser(newUser))
            .catch(() => {
                setUser(undefined)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()

    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/user-orders', '')
        .replace('/user-sales', '')
        .replace('/wallets', '')
        .replace('/withdrawals', '')
        .replace('/payment-methods', '')
        .replace('/addresses', '')

    const menuItems: MenuItem[] = menuRules(user, pathname)
    const rows = getRows(currentSubMenu, user)

    if (!user) return <></>

    const buttons = [
        <UpdateTransactionFeeButton user={user} />,
        <UpdateUserInstitutionalButton user={user} />,
        <UploadOrdersCsv user={user} />,
        <CancelAllOrdersButton user={user} side="ASK" />,
        <CancelAllOrdersButton user={user} side="BID" />,
    ]

    return (
        <DynamicCard
            title={`User`}
            subtitle={`#${user.userId}`}
            menuItems={menuItems}
            buttons={buttons}
            rows={rows}
            address={currentSubMenu}
            loading={loading}
        />
    )
}
