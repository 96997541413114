import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#eef3f7',
        minHeight: '45px',
        borderColor: '#eef3f7',
    }),
}

type Props = {
    title: string
    enumValues: string[]
    defaultValue?: string | null
    isMulti?: boolean
    onChangeCallback: (newValue: string) => void
}

export const DynamicFilterEnum = ({
    title,
    enumValues,
    defaultValue,
    onChangeCallback,
    isMulti = false,
}: Props) => {
    const [value, setValue] = useState<string>(
        defaultValue != null && defaultValue ? defaultValue : ''
    )
    const [selected, setSelected] = useState(
        isMulti
            ? defaultValue &&
                  defaultValue?.split(',')?.map((i) => ({ label: i, value: i }))
            : defaultValue && { label: defaultValue, value: defaultValue }
    )

    useEffect(() => {
        onChangeCallback(value)
    }, [value])

    function onChange(v: any) {
        setSelected(v)
        if (isMulti) {
            const values = v.map((v: any) => {
                return v.value
            })
            setValue(values.join(','))
        } else {
            setValue(v.value)
        }
    }

    const options = enumValues.map((v) => {
        return { value: v, label: v }
    })

    return (
        <div className="mb-5 col-md-6">
            <label className="form-label fw-bold">{title}:</label>
            <div>
                <Select
                    classNamePrefix="select2"
                    isMulti={isMulti}
                    defaultValue={selected}
                    onChange={onChange}
                    styles={customStyles}
                    options={options}
                />
            </div>
        </div>
    )
}
