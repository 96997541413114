import { PhotoProvider, PhotoView } from 'react-photo-view'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { TextField } from '../../../common/components/fields/TextField'
import { IProduct } from '../../data/Product'

export const MediasView = (product: IProduct): CustomRow[] => {
    const medias = product.medias ?? []
    const rows: CustomRow[] = [
        {
            column: [
                {
                    title: '',
                    content: (
                        <div>
                            {medias.length ? (
                                <PhotoProvider>
                                    {medias.map((media) => (
                                        <PhotoView src={media.url}>
                                            <img
                                                src={media.url}
                                                alt=""
                                                style={{ width: 250 }}
                                                className="me-3 cursor-pointer"
                                            />
                                        </PhotoView>
                                    ))}
                                </PhotoProvider>
                            ) : (
                                <TextField>No images provided</TextField>
                            )}
                        </div>
                    ),
                },
            ],
        },
    ]
    return rows
}
