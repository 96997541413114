export enum BuyerStatus {
    PENDING_CHECKOUT = 'PENDING_CHECKOUT',
    PENDING_SELLER_CHECKOUT = 'PENDING_SELLER_CHECKOUT',
    PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
    PENDING_PAYMENT_3DS = 'PENDING_PAYMENT_3DS',
    PAYMENT_REJECTED = 'PAYMENT_REJECTED',
    CHECKOUT_3DS_EXPIRED = 'CHECKOUT_3DS_EXPIRED',
    CHECKOUT_EXPIRED = 'CHECKOUT_EXPIRED',
    CHECKOUT_CANCELLED = 'CHECKOUT_CANCELLED',
    DELIVERY_EXPIRED = 'DELIVERY_EXPIRED',
    WAITING_SHIPMENT = 'WAITING_SHIPMENT',
    WAITING_PAYMENT_APPROVAL = 'WAITING_PAYMENT_APPROVAL',
    INSTITUTIONAL_PENDING_PAYMENT = 'INSTITUTIONAL_PENDING_PAYMENT',
    IN_TRANSIT = 'IN_TRANSIT',
    COMPLETED = 'COMPLETED',
    PENDING_RETURN = 'PENDING_RETURN',
    CANCELLED = 'CANCELLED',
    RETURNED = 'RETURNED',
    DECOUPLED = 'DECOUPLED',
}
