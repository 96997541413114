import React from 'react'
import { Link } from 'react-router-dom'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import Order from '../data/Order'
import Moment from 'react-moment'
import { OrderStatusComponent } from './label/OrderStatusComponent'
import { OrderOperation } from './label/OrderOperation'
import { Type } from '../../common/components/table/DynamicFilter'
import { ORDER_STATUS } from '../data/SpecificOrder'
import { InstitutionalComponent } from './label/InstitutionalComponent'
import CancelButton from './buttons/CancelButton'
import { ProductCondition } from '../../sale/component/label/Condition'
import { ResourceType } from '../../common/components/table/DynamicFilterRemote'

const idColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <Link to={`/order/${data.orderId}/summary`}>
            <span className="fw-bold">{data?.orderId?.substr(0, 8)}..</span>
        </Link>
    )
}

const userColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <Link
                to="#"
                className="text-dark fw-bolder text-hover-primary d-block fs-6"
            >
                {data?.user?.fullName}
                {InstitutionalComponent(data.isInstitutional)}
            </Link>
            <span className="text-muted fw-bold text-muted d-block fs-7">
                {data?.user?.email}
            </span>
        </div>
    )
}

const productColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <div className="symbol symbol-45px me-5">
                <img src={data.productDetail?.thumbnail?.url} alt="" />
            </div>
            <div className="d-flex justify-content-start flex-column">
                <a
                    href="#"
                    className="text-dark fw-bolder text-hover-primary fs-6"
                >
                    {data.productDetail?.title}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    <ProductCondition
                        status={data.productDetail?.grade.description}
                    />
                </span>
            </div>
        </div>
    )
}

const priceColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-end text-dark fw-bolder fs-6 pe-1">
                {data.price}
            </span>
            <span className="text-muted fw-bold text-muted fs-7">
                {data.currency}
            </span>
        </div>
    )
}

const statusColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <OrderStatusComponent status={data.status} />
        </div>
    )
}

const typeOrderColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <OrderOperation side={data.side} type={data.type} />
        </div>
    )
}
const createdColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}

const updatedColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8 mt-2">
                Updated at
            </span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="YYYY-MM-DD HH:mm">{data.updated}</Moment>
            </span>
        </div>
    )
}

const actionColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <>
            <Link
                to="#"
                className="btn btn-bg-white btn-sm show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
            >
                <span>
                    <i className="bi bi-three-dots fs-5" />
                </span>
            </Link>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                data-kt-menu="true"
            >
                <CancelButton order={data} isMenu={true} />
            </div>
        </>
    )
}

const columns = [
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'User',
        render: userColumn,
    },
    {
        header: 'Product',
        render: productColumn,
    },
    {
        header: 'Price',
        render: priceColumn,
    },
    {
        header: 'Status',
        render: statusColumn,
    },
    {
        header: 'Type/Side',
        render: typeOrderColumn,
    },
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Updated',
        render: updatedColumn,
    },
    {
        header: 'Action',
        render: actionColumn,
    },
]

const filters = [
    {
        title: 'Created from',
        queryParam: 'startDate',
        type: Type.DATE_START,
    },
    {
        title: 'Created to',
        queryParam: 'endDate',
        type: Type.DATE_END,
    },
    {
        title: 'Updated from',
        queryParam: 'startUpdateDate',
        type: Type.DATE,
    },
    {
        title: 'Updated to',
        queryParam: 'endUpdateDate',
        type: Type.DATE,
    },
    {
        title: 'Expiration from',
        queryParam: 'startExpirationDate',
        type: Type.DATE_START,
    },
    {
        title: 'Expiration to',
        queryParam: 'endExpirationDate',
        type: Type.DATE_END,
    },
    {
        title: 'Order type',
        queryParam: 'type',
        type: Type.ENUM,
        enumValues: ['MARKET', 'LIMIT', 'EMPTY'],
    },
    {
        title: 'Order Side',
        queryParam: 'side',
        type: Type.ENUM,
        enumValues: ['BID', 'ASK', 'EMPTY'],
    },
    {
        title: 'Order status',
        queryParam: 'status',
        type: Type.ENUM_MULTI,
        enumValues: ORDER_STATUS,
    },
    {
        title: 'User',
        queryParam: 'userId',
        type: Type.REMOTE,
        resourceUrl: '/admin/v1/users/term',
    },
    {
        title: 'Product',
        queryParam: 'productId',
        type: Type.REMOTE,
        resourceUrl: `public/v1/indexed-products/search`,
        resourceType: 'PRODUCT' as ResourceType,
    },
    {
        title: 'Is Institutional',
        queryParam: 'isInstitutional',
        type: Type.BOOLEAN,
    },
]

const Orders: React.FC = () => {
    return (
        <>
            <DynamicTable
                title="Order"
                description="List of orders"
                columns={columns}
                filters={filters}
                resource="admin/v1/orders"
            />
        </>
    )
}
export { Orders, columns, filters }
