import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import RestResponse from "../../../../app/backoffice/common/RestResponse";
import Axios from "../../../../app/backoffice/common/HttpCommon";
import {LOGIN_ROUTE} from "../../../../app/backoffice/common/RouteConstants";
import * as buffer from 'buffer';

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `localhost:8186/auth/v1/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {

    const token = buffer.Buffer.from(`${email}:${password}`, 'utf-8').toString('base64')

    const options = {
        headers: {
            'Authorization': `Basic ${token}`,
        },
    };

    return Axios.post<RestResponse<any>>(
        LOGIN_ROUTE,
        null,
        options
    )
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
