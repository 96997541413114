import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { Filter, Type } from '../../../common/components/table/DynamicFilter'
import Moment from 'react-moment'
import { RETURNING_REQUESTS_ROUTE } from '../../../common/RouteConstants'
import {
    IReturningRequest,
    ReturningRequestStatus,
} from '../data/ReturningRequest'
import { RequestStatusBadge } from '../../component/badge/RequestStatusBadge'
import { ReturnTypeBadge } from '../../component/badge/ReturnTypeBadge'
import { useHistory } from 'react-router-dom'
import { TableTextField } from '../../../common/components/table/TableTextField'
import { TableBoldTextField } from '../../../common/components/table/TableBoldTextField'

const ReturningRequests = () => {
    const history = useHistory()
    const handleLineAction = (request: IReturningRequest) => {
        history.push(`/return/requests/${request.requestId}/summary`)
    }
    const createdColumn = (data: IReturningRequest) => {
        return (
            <>
                <TableTextField>Created at</TableTextField>
                <TableBoldTextField>
                    <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
                </TableBoldTextField>
            </>
        )
    }

    const idColumn = (data: IReturningRequest) => {
        return (
            <>
                <TableTextField>Request ID</TableTextField>
                <TableBoldTextField>{data.requestId}</TableBoldTextField>
            </>
        )
    }

    const reasonColumn = (data: IReturningRequest) => {
        return (
            <>
                <TableTextField>
                    {data.returningReason.returningReasonId}
                </TableTextField>
                <TableBoldTextField>
                    {data.returningReason.title}
                </TableBoldTextField>
            </>
        )
    }
    const userColumn = (data: IReturningRequest) => {
        return (
            <>
                <TableTextField>Name</TableTextField>
                <TableBoldTextField>{`${data.user?.firstName} ${data.user?.lastName}`}</TableBoldTextField>
            </>
        )
    }
    const typeColumn = (data: IReturningRequest) => {
        return <ReturnTypeBadge type={data.type} />
    }
    const statusColumn = (data: IReturningRequest) => {
        return <RequestStatusBadge status={data.currentStatus} />
    }

    const columns = [
        {
            header: 'Request ID',
            render: idColumn,
        },
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Reason',
            render: reasonColumn,
        },
        {
            header: 'User',
            render: userColumn,
        },
        {
            header: 'Type',
            render: typeColumn,
        },
        {
            header: 'Status',
            render: statusColumn,
        },
    ]

    const customSwitchValues = {
        active: `${ReturningRequestStatus.COMPLETED},${ReturningRequestStatus.REJECTED}`,
        notActive: '',
    }
    const filters: Filter[] = [
        {
            title: 'Hide Completed/Rejected',
            queryParam: 'excludeStatuses',
            type: Type.CUSTOM_SWITCH,
            customSwitchValues,
        },
        {
            title: 'Status',
            queryParam: 'statuses',
            type: Type.ENUM_MULTI,
            enumValues: Object.values(ReturningRequestStatus),
        },
    ]
    return (
        <DynamicTable
            title={'Return Requests'}
            description={'List of requests'}
            resource={`${RETURNING_REQUESTS_ROUTE}?includeUserInfo=true`}
            columns={columns}
            filters={filters}
            lineAction={handleLineAction}
        />
    )
}

export { ReturningRequests }
