import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
interface ConfirmReturnButtonProps {
    request: IReturningRequest
}

export const ConfirmReturnButton = ({ request }: ConfirmReturnButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function confirm() {
        const response = await returningRequestService.confirmReturn(
            request.requestId
        )
        if (response) {
            success('return confirmed!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not confirm the return!')
        }
    }

    return (
        <ApproveActionButton
            buttonText="Confirm return"
            modalTitle="You are about to confirm a Return"
            modalText="Are you sure you want to confirm this return?"
            action={confirm}
        />
    )
}
