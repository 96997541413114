import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { SALE_ROUTE_V2 } from '../../../common/RouteConstants'
import { saleColumns } from '../../../sale/component/Sales'
import IUser from '../../data/User'
import { filters } from '../../../sale/component/Sales'

export function SalesView(
    user: IUser,
    currentScreen: string
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title="User sales"
                        description="List of sales"
                        resource={`${SALE_ROUTE_V2}/?userId=${user?.userId}`}
                        columns={saleColumns}
                        filters={filters}
                    />
                ),
            },
        ],
    })
    return renderRows
}
