import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { MenuItem } from '../../common/components/data/CardUtil'
import { IProduct } from '../data/Product'
import { ProductServiceImpl } from '../service/rest/ProductServiceImpl'
import { getRows } from './view/RenderRows'
import EditProductButton from './button/EditProductButton'

function menuRules(props: { product?: IProduct; pathname: string }) {
    const { product, pathname } = props
    let list = []
    list.push({ title: 'SUMMARY', to: `${pathname}/summary` })
    if (product?.medias?.length)
        list.push({ title: 'MEDIAS', to: `${pathname}/medias` })
    list.push({ title: 'ORDERS', to: `${pathname}/orders` })
    list.push({ title: 'SALES', to: `${pathname}/sales` })
    return list
}

export const ProductDetail = () => {
    const [product, setProduct] = useState<IProduct>()
    const { productId } = useParams<{ productId: string }>()
    const [loading, setLoading] = useState(false)
    const productService = new ProductServiceImpl()

    async function fetchData() {
        setLoading(true)
        const p = await productService.find(productId)
        setProduct(p)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()

    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/medias', '')
        .replace('/orders', '')
        .replace('/sales', '')

    const menuItems: MenuItem[] = menuRules({ product, pathname })
    const rows = getRows(currentSubMenu, product)
    const buttons = [<EditProductButton product={product} />]

    return (
        <DynamicCard
            title={`Product`}
            subtitle={`#${product?.productId}`}
            menuItems={menuItems}
            rows={rows}
            address={currentSubMenu}
            loading={loading}
            buttons={buttons}
        />
    )
}
