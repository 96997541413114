import React from 'react'
import SpecificSale from '../../../data/SpecificSale'
import { AttachTrackingButton } from '../../../../sale/component/buttons/shipment/AttachTrackingButton'
import { ShipmentStatus } from '../../../data/ShipmentStatus'

export enum TrackingSide {
    BUYER,
    SELLER,
}

export const TrackingNumberRow = (props: {
    sale: SpecificSale
    side: TrackingSide
}) => {
    const getTrackingNumber = (): string | undefined => {
        if (props.side === TrackingSide.BUYER)
            return props.sale.buyerShipmentDetails?.shipment?.trackingNumber

        return props.sale.sellerShipmentDetails?.postLocationDetails.shipment
            ?.trackingNumber
    }

    const getShipmentStatus = (): string | undefined => {
        if (props.side === TrackingSide.BUYER)
            return props.sale.buyerShipmentDetails?.shipment?.status

        return props.sale.sellerShipmentDetails?.postLocationDetails.shipment
            ?.status
    }

    const getShipmentId = (): string => {
        if (props.side === TrackingSide.BUYER)
            return props.sale.buyerShipmentDetails!!.shipment!!.shipmentId

        return props.sale.sellerShipmentDetails!!.postLocationDetails.shipment!!
            .shipmentId
    }

    const trackingNumber = getTrackingNumber()
    const shipmentStatus = getShipmentStatus()
    const showEdit =
        shipmentStatus === ShipmentStatus.CREATED ||
        shipmentStatus === ShipmentStatus.FAILED

    return (
        <>
            {trackingNumber && <span className="me-3">{trackingNumber}</span>}
            {showEdit && (
                <span>
                    <AttachTrackingButton shipmentId={getShipmentId()} />
                </span>
            )}
        </>
    )
}
