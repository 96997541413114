import clsx from 'clsx'
import {FC} from 'react'
import { LocationSelector } from '../../../../app/backoffice/common/components/header/LocationSelector'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
    // toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <div className='topbar d-flex align-items-stretch flex-shrink-0'>
                {/* Search */}
                {/*<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>*/}
                {/*    <Search/>*/}
                {/*</div>*/}
                {/* begin::User */}
                <LocationSelector />
                <div
                    className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                    id='kt_header_user_menu_toggle'
                >
                    {/* begin::Toggle */}
                    <div
                        className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <img
                            className='h-25px w-25px rounded'
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                            alt='perfil'
                        />
                    </div>
                    <HeaderUserMenu/>
                    {/* end::Toggle */}
                </div>
                {/* end::User */}
            </div>
        </div>
    )
}

export {Topbar}
