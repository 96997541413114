import { CustomRow } from '../../../../common/components/data/CardUtil'
import { TextField } from '../../../../common/components/fields/TextField'
import { IReturningRequest } from '../../data/ReturningRequest'
import { BoldTextField } from '../../../../common/components/fields/BoldTextField'
import { CardLinkField } from '../../../../common/components/card/CardLinkField'
import { MonetaryField } from '../../../../common/components/fields/MonetaryField'
import { SellerChargedStatusBadge } from '../../../component/badge/SellerChargedStatusBadge'
import { RefundStatusBadge } from '../../../component/badge/RefundStatusBadge'

export function RefundView(request: IReturningRequest): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []
    const {
        refundId,
        currency,
        sellerId,
        status,
        sellerChargedStatus,
        buyerTotal,
        sellerTotal,
    } = request.refund!!
    renderRows.push(
        {
            column: [
                {
                    title: 'Refund ID',
                    content: <BoldTextField>{refundId}</BoldTextField>,
                },
                {
                    title: 'Status',
                    content: <RefundStatusBadge status={status} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Buyer Total',
                    content: MonetaryField(buyerTotal, currency),
                },
                {
                    title: 'Currency',
                    content: <TextField>{currency}</TextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Total',
                    content: MonetaryField(sellerTotal, currency),
                },
                {
                    title: 'Seller ID',
                    content: (
                        <CardLinkField to={`/user/${sellerId}/summary`}>
                            {sellerId}
                        </CardLinkField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Seller Charged Status',
                    content: (
                        <SellerChargedStatusBadge
                            sellerChargedStatus={sellerChargedStatus}
                        />
                    ),
                },
                {
                    title: 'Seller Wallet',
                    content: (
                        <CardLinkField to={`/user/${sellerId}/wallets`}>
                            Go To Wallet Balance
                        </CardLinkField>
                    ),
                },
            ],
        }
    )
    return renderRows
}
