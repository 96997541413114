import { ReactChild, ReactChildren } from 'react'
import { RequiredInformationType } from '../../../data/Returning'

type Props = {
    status: string
}

export const InformationTypeLabel = ({ status }: Props) => {
    let statusComp = null

    switch (status) {
        case RequiredInformationType.TEXT_DESCRIPTION:
            statusComp = <Label css="badge-light-primary">{status}</Label>
            break
        case RequiredInformationType.IMAGE_UPLOAD:
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{status}</Label>
            break
        }
    }

    return <>{statusComp}</>
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <>
            <span className={`badge ${css} fs-7 fw-bolder`}>{children}</span>
        </>
    )
}
