import Sale from '../../data/Sale'
import SpecificSale from '../../data/SpecificSale'
import { httpHelper } from '../../../common/HttpCommon'
import { GRADE_ACCEPTANCE_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { isGradeVerification } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import EnvironmentConfirmationModal from '../../../common/components/modal/EnvironmentConfirmationModal'
import { useState } from 'react'

const AcceptGradeButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const { success, danger } = useAlert()
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const closeConfirmationModal = () => {
        setConfirmModalVisible(false)
    }

    const handleError = () => {
        danger('Error: Could not accept grade!')
        setConfirmModalVisible(false)
    }

    const handleSuccess = () => {
        setConfirmModalVisible(false)
        success('Grade accepted!')
        window.setTimeout(() => {
            window.location.reload()
        }, 1500)
    }

    if (!isGradeVerification(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={() => {
                    setConfirmModalVisible(true)
                }}
            >
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen043.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Accept Grade
                </div>
            </div>
            <EnvironmentConfirmationModal
                show={confirmModalVisible}
                onClose={closeConfirmationModal}
                onConfirm={() => accept(sale.saleId)}
                actionName="Accept Grade"
                loading={loading}
            />
        </div>
    )

    async function accept(saleId: string) {
        setLoading(true)
        await httpHelper
            .put(
                `${GRADE_ACCEPTANCE_ROUTE}?gradeDecision=ACCEPT&saleIds=${saleId}`
            )
            .then((response) => {
                if (!response) handleError()
                else handleSuccess()
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false)
            })
    }
}

export default AcceptGradeButton
