export default interface Returning {
    title: string
    isDefective: boolean
    active: boolean
    created: string
    updated: string | null
    returningReasonId: string
    requiredInformation: Array<RequiredInformationType>
    acceptedReturnTypes: Array<ReturnType>
}

export enum RequiredInformationType {
    IMAGE_UPLOAD = 'IMAGE_UPLOAD',
    TEXT_DESCRIPTION = 'TEXT_DESCRIPTION',
}

export enum ReturnType {
    RETURN = 'RETURN',
    RESEND = 'RESEND',
    EXCHANGE = 'EXCHANGE',
}

export enum ReturnMethod {
    REFUND = 'REFUND',
    REPAIR = 'REPAIR',
}

export const RequiredInformationTypeOptions = [
    {
        value: RequiredInformationType.IMAGE_UPLOAD,
        label: RequiredInformationType.IMAGE_UPLOAD,
    },
    {
        value: RequiredInformationType.TEXT_DESCRIPTION,
        label: RequiredInformationType.TEXT_DESCRIPTION,
    },
]

export const ReturnTypeOptions = [
    {
        value: ReturnType.RETURN,
        label: ReturnType.RETURN,
    },
    {
        value: ReturnType.RESEND,
        label: ReturnType.RESEND,
    },
    {
        value: ReturnType.EXCHANGE,
        label: ReturnType.EXCHANGE,
    },
]
