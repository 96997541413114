import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../../_metronic/pages/dashboard/DashboardWrapper'
import { Orders } from '../backoffice/order/component/Orders'
import { Sales } from '../backoffice/sale/component/Sales'
import { Events } from '../backoffice/event/components/Events'
import { SaleView } from '../backoffice/sale/component/Sale'
import { OrderView } from '../backoffice/order/component/Order'
import {
    DECOUPLE_PATH,
    EVENT_PATH,
    ORDER_PATH,
    RETURNING_PATH,
    SALE_PATH,
    USER_PATH,
} from '../backoffice/common/PathConstants'
import EventView from '../backoffice/event/components/EventView'
import EventEdit from '../backoffice/event/components/EventEdit'
import { Decouples } from '../backoffice/decouple/component/Decouples'
import { DecoupleView } from '../backoffice/decouple/component/Decouple'
import { ReturningReasons } from '../backoffice/returning/reason/component/ReturningReasons'
import { ReturningRequests } from '../backoffice/returning/requests/component/ReturningRequests'
import { ReturningRequestView } from '../backoffice/returning/requests/component/ReturningRequestView'
import { Users } from '../backoffice/user/component/Users'
import { UserView } from '../backoffice/user/component/UserView'
import { LostParcels } from '../backoffice/sale/lost-parcels/LostParcels'
import { Products } from '../backoffice/product/component/Products'
import { ProductDetail } from '../backoffice/product/component/ProductDetail'
import { QuickSearch } from '../backoffice/search/component/QuickSearch'

export function PrivateRoutes(props: {
    allowedPaths: string[]
    allowedRows: string[]
}) {
    const allowedPaths = props.allowedPaths
    const allowedRows = props.allowedRows

    const BuilderPageWrapper = lazy(
        () => import('../../_metronic/pages/layout-builder/BuilderPageWrapper')
    )
    const ProfilePage = lazy(
        () => import('../../_metronic/modules/profile/ProfilePage')
    )
    const WizardsPage = lazy(
        () => import('../../_metronic/modules/wizards/WizardsPage')
    )
    const AccountPage = lazy(
        () => import('../../_metronic/modules/accounts/AccountPage')
    )
    const WidgetsPage = lazy(
        () => import('../../_metronic/modules/widgets/WidgetsPage')
    )
    const ChatPage = lazy(
        () => import('../../_metronic/modules/apps/chat/ChatPage')
    )
    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                {/* <Route path="/dashboard" component={DashboardWrapper} /> */}
                <Route path="/search" component={QuickSearch} />
                {permittedRoutes().map((route) => route)}
                {/*<Route path='/statement' component={Statements}/>*/}
                {/*<Route path='/builder' component={BuilderPageWrapper}/>*/}
                {/*<Route path='/crafted/pages/profile' component={ProfilePage}/>*/}
                {/*<Route path='/crafted/pages/wizards' component={WizardsPage}/>*/}
                {/*<Route path='/crafted/widgets' component={WidgetsPage}/>*/}
                {/*<Route path='/crafted/account' component={AccountPage}/>*/}
                {/*<Route path='/apps/chat' component={ChatPage}/>*/}
                {/*<Route path='/menu-test' component={MenuTestPage}/>*/}
                <Redirect from="/auth" to="/search" />
                <Redirect exact from="/" to="/search" />
                <Redirect to="/error/404" />
            </Switch>
        </Suspense>
    )

    function permittedRoutes() {
        const routes: JSX.Element[] = []

        let allowedEdit = false
        allowedRows.forEach((row) => {
            if (row === 'PUT') allowedEdit = true
        })

        allowedPaths.forEach((path) => {
            routes.push(
                <Route path="/product/:productId" component={ProductDetail} />
            )
            routes.push(<Route path="/product" component={Products} />)
            switch (path) {
                case ORDER_PATH:
                    routes.push(
                        <Route path="/order/:orderId" component={OrderView} />
                    )
                    routes.push(<Route path="/order" component={Orders} />)
                    break
                case SALE_PATH:
                    routes.push(
                        <Route path="/sale/:saleId" component={SaleView} />
                    )
                    routes.push(<Route path="/sale" component={Sales} />)
                    routes.push(
                        <Route path="/lost-parcels" component={LostParcels} />
                    )

                    break
                case EVENT_PATH:
                    routes.push(<Route path="/event" component={Events} />)
                    routes.push(
                        <Route path="/event-view" component={EventView} />
                    )
                    if (allowedEdit)
                        routes.push(
                            <Route path="/event-edit" component={EventEdit} />
                        )
                    break
                case DECOUPLE_PATH:
                    routes.push(
                        <Route
                            path={'/decouple/:decoupleId'}
                            component={DecoupleView}
                        />
                    )
                    routes.push(
                        <Route path={'/decouple'} component={Decouples} />
                    )
                    break
                case RETURNING_PATH:
                    routes.push(
                        <Route
                            path={'/return/requests/:requestId'}
                            component={ReturningRequestView}
                        />
                    )
                    routes.push(
                        <Route
                            path={'/return/reasons'}
                            component={ReturningReasons}
                        />
                    )
                    routes.push(
                        <Route
                            path={'/return/requests'}
                            component={ReturningRequests}
                        />
                    )
                    break
                case USER_PATH:
                    routes.push(
                        <Route path={'/user/:userId'} component={UserView} />
                    )
                    routes.push(<Route path={'/user'} component={Users} />)
            }
        })
        return routes
    }
}
