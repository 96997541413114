import { ReactChild, ReactChildren } from 'react'

type Props = {
    status: string
}

export const BUYER_STATUS = [
    'PENDING_CHECKOUT',
    'PENDING_SELLER_CHECKOUT',
    'PROCESSING_PAYMENT',
    'PENDING_PAYMENT_3DS',
    'PAYMENT_REJECTED',
    'CHECKOUT_3DS_EXPIRED',
    'CHECKOUT_EXPIRED',
    'CHECKOUT_CANCELLED',
    'DELIVERY_EXPIRED',
    'WAITING_SHIPMENT',
    'WAITING_PAYMENT_APPROVAL',
    'INSTITUTIONAL_PENDING_PAYMENT',
    'IN_TRANSIT',
    'COMPLETED',
    'PENDING_RETURN',
    'CANCELLED',
    'RETURNED',
    'DECOUPLED',
]

export const BuyerStatus = ({ status }: Props) => {
    let statusComp = null

    switch (status) {
        case 'PENDING_CHECKOUT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PENDING_SELLER_CHECKOUT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PROCESSING_PAYMENT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PENDING_PAYMENT_3DS':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PAYMENT_REJECTED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_3DS_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_CANCELLED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'DELIVERY_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'WAITING_SHIPMENT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'WAITING_PAYMENT_APPROVAL':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'INSTITUTIONAL_PENDING_PAYMENT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'IN_TRANSIT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PENDING_RETURN':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'COMPLETED':
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case 'RETURNED':
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case 'CANCELLED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'DECOUPLED':
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{status}</Label>
            break
        }
    }

    return <>{statusComp}</>
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <>
            <span className={`badge ${css} fs-7 fw-bolder`}>{children}</span>
        </>
    )
}
