import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import { getDojiEnv } from '../../../Env'
import { KTSVG } from '../../../../../_metronic/helpers'

const ConfirmEnvironmentModel = (props: {
    show: Boolean
    onClose: () => void
    onConfirm: () => void
    title: string
}) => {
    const [disabledButton, setDisabledButton] = useState(true)
    const [textInput, setTextInput] = useState('')

    useEffect(() => {
        if (textInput === getDojiEnv()) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [textInput])

    useEffect(() => {
        setDisabledButton(true)
        setTextInput('')
    }, [props.show])

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton className={'modal-header'}>
                <Modal.Title>
                    <strong>{getDojiEnv()}</strong>: {props.title}
                </Modal.Title>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                    onClick={() => props.onClose()}
                    aria-label="Close"
                >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                </div>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Please for safety purposes, please write the{' '}
                    <strong>environment in upper case letters</strong> for the
                    action you are performing. (ex: STAGING)
                </p>
                <Form.Control
                    type={'text'}
                    placeholder={'ENVIRONMENT'}
                    value={textInput}
                    onChange={(e) => {
                        setTextInput(e.target.value)
                    }}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={props.onConfirm}
                    disabled={disabledButton}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmEnvironmentModel
