import Sale from '../../../data/Sale'
import SpecificSale from '../../../data/SpecificSale'
import { httpHelper } from '../../../../common/HttpCommon'
import { SHIPMENT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { isMarkedBuyerCollected } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'

const BuyerToCollectedButton = (props: {
    sale: Sale | SpecificSale
    title?: string
}) => {
    const sale = props.sale
    const title = props.title
    const { success, danger } = useAlert()

    if (!isMarkedBuyerCollected(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={() => {
                    markAsCollected()
                }}
            >
                <KTSVG
                    path={'/media/icons/duotune/ecommerce/ecm004.svg'}
                    className="svg-icon-1 me-1"
                />
                {title || 'Mark as Collected'}
            </div>
        </div>
    )

    async function markAsCollected() {
        const trackingNumber = sale.buyerShipmentDetails?.shipmentId

        await httpHelper
            .put(`${SHIPMENT_ROUTE}/${trackingNumber}/status?status=COLLECTED`)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: Not marked as collected!')
                else {
                    success('Shipment mark as collected!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default BuyerToCollectedButton
