import { ProductCondition } from '../../sale/component/label/Condition'
import { IProduct } from '../data/Product'

export const FormatOptionLabel = (value: IProduct) => {
    return (
        <div>
            <span className="text-dark fw-bolder d-block fs-6">
                {value.title}
                <div className="ms-1">
                    <ProductCondition status={value?.grade?.description} />
                </div>
            </span>
        </div>
    )
}

export const ValueOptionLabel = (props: any) => {
    return (
        <div
            style={{
                gridArea: '1 / 1 / 2 / 3',
                marginLeft: '2px',
                marginRight: '2px',
                maxWidth: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
            }}
        >
            <span>{props.data.title}</span>
        </div>
    )
}
