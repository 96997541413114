import { Form } from 'react-bootstrap-v5'

interface DynamicSearchProps {
    title: string
    subtitle: string
    formatOptionLabel: any
    ValueOptionLabel: any
    value: string
    onChange: (e: any) => void
}
export const SearchInput = ({
    title,
    subtitle,
    onChange,
    value,
}: DynamicSearchProps) => {
    return (
        <div className="card mb-4 pb-4">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                        {title}
                    </span>
                    <span className="text-muted my-4 fw-bold fs-7">
                        {subtitle}
                    </span>
                    <Form.Control
                        type={'text'}
                        placeholder="User name, email, sale id or order id"
                        value={value}
                        onChange={onChange}
                        style={formControlStyles}
                    />
                </h3>
            </div>
        </div>
    )
}

const formControlStyles = {
    background: '#eef3f7',
    height: '45px',
    borderColor: '#eef3f7',
    width: '500px',
}
