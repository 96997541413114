import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { columns } from '../../../order/component/Orders'
import { IProduct } from '../../data/Product'
import { filters } from '../../../order/component/Orders'

export const OrdersView = (product: IProduct): CustomRow[] => {
    const rows: CustomRow[] = [
        {
            column: [
                {
                    title: '',
                    content: (
                        <DynamicTable
                            description={'List of orders'}
                            columns={columns}
                            resource={`admin/v1/orders?productId=${product.productId}`}
                            filters={filters}
                        />
                    ),
                },
            ],
        },
    ]
    return rows
}
