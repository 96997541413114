import { ReactElement } from 'react'
import { userColumns } from '../../user/component/Users'
import { orderColumns } from './tableColumns/orderColumns'
import { saleColumns } from './tableColumns/saleColumns'

type DynamicTableProps<P> = {
    title?: string
    description?: string | null
    resource: string
    dataType: 'user' | 'sale' | 'order'
    lineAction?: (data: P) => void
    data?: any[]
    minWidth?: number | null
}

interface Column {
    header: string
    minWidth?: number | null
    render: (data: any) => ReactElement | null
}

export const SearchResultContainer = <P extends unknown>({
    title,
    description,
    data,
    lineAction,
    dataType,
}: DynamicTableProps<P>) => {
    let key = 0
    let headerKey = 0

    let columns: Column[] = []

    switch (dataType) {
        case 'user':
            columns = userColumns
            break
        case 'sale':
            columns = saleColumns
            break
        case 'order':
            columns = orderColumns
            break

        default:
            break
    }

    if (!data?.length) return <></>

    return (
        <>
            <div className="card mb-4">
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-3 mb-1">
                            {title}
                        </span>
                        <span className="text-muted mt-1 fw-bold fs-7">
                            {description}
                        </span>
                    </h3>
                    <div
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Click to add a user"
                    ></div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className="card-body py-3">
                    {/* begin::Table container */}
                    <div className="table-responsive">
                        <table
                            className={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4${
                                lineAction ? ' table-hover cursor-pointer' : ''
                            }`}
                        >
                            {/* begin::Table head */}
                            <thead>
                                <tr className="fw-bolder text-muted">
                                    <th className="w-25px">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="1"
                                                data-kt-check="true"
                                                data-kt-check-target=".widget-9-check"
                                            />
                                        </div>
                                    </th>
                                    {columns.map((data) => {
                                        return (
                                            <th key={`headerKey${headerKey++}`}>
                                                {data.header}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data.map((o: any) => {
                                    return (
                                        <tr
                                            onClick={
                                                lineAction
                                                    ? () => lineAction(o)
                                                    : undefined
                                            }
                                            key={`tableKey${key++}`}
                                        >
                                            <td>
                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input
                                                        className="form-check-input widget-9-check"
                                                        type="checkbox"
                                                        value="1"
                                                    />
                                                </div>
                                            </td>
                                            {columns.map((column) => (
                                                <td>{column.render(o)}</td>
                                            ))}
                                        </tr>
                                    )
                                })}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}
