import { OrderSide, OrderType } from '../../data/SpecificOrder'

type Props = {
    side: OrderSide
    type: OrderType
}

export const OrderOperation = ({ side, type }: Props) => {
    let operation = null

    if (side === OrderSide.ASK && type === OrderType.MARKET)
        operation = 'BUY NOW'
    if (side === OrderSide.ASK && type === OrderType.LIMIT) operation = 'ASK'
    if (side === OrderSide.BID && type === OrderType.MARKET)
        operation = 'SELL NOW'
    if (side === OrderSide.BID && type === OrderType.LIMIT) operation = 'BID'

    switch (operation) {
        case 'BUY NOW':
            return <span className="badge badge-success">{operation}</span>
        case 'ASK':
            return (
                <span className="badge badge-light-primary">{operation}</span>
            )
        case 'SELL NOW':
            return <span className="badge badge-warning">{operation}</span>
        case 'BID':
            return (
                <span className="badge badge-light-success">{operation}</span>
            )
        default: {
            return <span className="badge badge-dark">{operation}</span>
        }
    }
}
