/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useMenuButtonsMapper, MenuItemWithSub, MenuItemProps } from "../../../../app/backoffice/common/MenuRoutes";

export function AsideMenuMain() {
  const intl = useIntl();
  const { getMenuButtons } = useMenuButtonsMapper();
  const menuButtons = getMenuButtons();

  function menuRender(menuItemWithSub: MenuItemWithSub) {
    return (
      <AsideMenuItemWithSub
        title={menuItemWithSub.title}
        to={menuItemWithSub.to}
        icon={menuItemWithSub.icon}
      >
        {menuItemWithSub.subItems.map((menuItem: MenuItemProps) => (
          <AsideMenuItem
            title={menuItem.title}
            to={menuItem.to}
            icon={menuItem.icon}
          />
        ))}
      </AsideMenuItemWithSub>
    );
  }

  return (
    <>
      <AsideMenuItem
        to="/search"
        icon="/media/icons/duotune/art/art002.svg"
        title="Search"
        fontIcon="bi-app-indicator"
      />
      {/* <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      /> */}
      <AsideMenuItem
        to="/user"
        icon="/media/icons/duotune/general/gen049.svg"
        title='Users'
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/product"
        icon="/media/icons/duotune/general/gen049.svg"
        title='Products'
        fontIcon="bi-app-indicator"
      />
      {menuButtons.map((m) => menuRender(m))}
    </>
  );

}
