import { useEffect, useState } from 'react'
import { DynamicCard } from '../../../common/components/card/DynamicCard'
import { MenuItem } from '../../../common/components/data/CardUtil'
import { IReturningRequest } from '../data/ReturningRequest'
import { getRows } from './RenderRows'
import { useParams } from 'react-router-dom'
import { ReturningRequestActionButtons } from '../../component/buttons/ReturningRequestActionButtons'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import SpecificSale from '../../../sale/data/SpecificSale'
import { httpHelper } from '../../../common/HttpCommon'
import { SALE_ROUTE } from '../../../common/RouteConstants'

function menuRules(request: IReturningRequest | undefined, pathname: string) {
    let list = []

    list.push({ title: 'SUMMARY', to: `${pathname}/summary` })
    if (!request) return list
    if (request.returningReason)
        list.push({ title: 'IMAGES', to: `${pathname}/images` })
    if (request.images) list.push({ title: 'REASON', to: `${pathname}/reason` })
    if (request.images) list.push({ title: 'USER', to: `${pathname}/user` })
    if (request.refund) list.push({ title: 'REFUND', to: `${pathname}/refund` })
    list.push({ title: 'SALE', to: `${pathname}/sale` })

    return list
}

export function ReturningRequestView() {
    const [request, setRequest] = useState<IReturningRequest>()
    const [sale, setSale] = useState<SpecificSale>()
    const { requestId } = useParams<{ requestId: string }>()
    const [loading, setLoading] = useState(false)
    const returningRequestService = new ReturningRequestServiceImpl()

    async function fetchData() {
        setLoading(true)
        const request_ = await returningRequestService.findRequest(requestId)
        setRequest(request_)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (request) {
            httpHelper
                .get<SpecificSale>(`${SALE_ROUTE}/${request.saleId}`)
                .then((resp) => setSale(resp))
        }
    }, [request])

    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()

    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/user', '')
        .replace('/images', '')
        .replace('/reason', '')
        .replace('/refund', '')
        .replace('/sale', '')

    const menuItems: MenuItem[] = menuRules(request, pathname)
    const buttons = ReturningRequestActionButtons(request)
    const rows = getRows(currentSubMenu, request, sale)

    return (
        <DynamicCard
            title={`Return Request`}
            subtitle={`#${request?.requestId}`}
            menuItems={menuItems}
            buttons={buttons}
            rows={rows}
            address={currentSubMenu}
            loading={loading}
        />
    )
}
