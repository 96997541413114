import { ReactChild, ReactChildren } from 'react'

export const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <span className={`badge ${css} fs-7 fw-bolder me-3`}>{children}</span>
    )
}
