import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from '../_metronic/modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
import { AlertOutlet, AlertProvider } from 'react-bootstrap-hooks-alert'
import './backoffice/common/style/alert.css'

type Props = {
    basename: string | undefined
}

const App: React.FC<Props> = ({ basename }) => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <AlertProvider
                timeouts={{
                    warning: 2200,
                    success: 1000,
                    danger: 3000,
                    info: 1500,
                }}
            >
                <AlertOutlet className={'alert alert-outlet top-0'} />
                <BrowserRouter basename={basename}>
                    <I18nProvider>
                        <LayoutProvider>
                            <AuthInit>
                                <Routes />
                            </AuthInit>
                        </LayoutProvider>
                    </I18nProvider>
                </BrowserRouter>
            </AlertProvider>
        </Suspense>
    )
}

export { App }
