import Sale from '../../../data/Sale'
import SpecificSale from '../../../data/SpecificSale'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { downloadFile } from '../../../../common/utils/DownloadFile'
import { SHIPMENT_ROUTE } from '../../../../common/RouteConstants'
import { SellerStatus } from '../../../data/SellerStatus'

const DownloadSellerLabel = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const shipment = sale.sellerShipmentDetails?.postLocationDetails?.shipment
    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    if (
        sale.sellerStatus === SellerStatus.DECOUPLED ||
        !sale.sellerShipmentDetails?.postLocationDetails?.shipment?.hasLabel ||
        !shipment
    )
        return <></>

    const download = () => {
        const url = `${SHIPMENT_ROUTE}/${sale.saleId}/labels/pdf-by-sale?isSeller=true`
        const filename = `${shipment.orderId}-${shipment.shipmentId}.pdf`
        downloadFile(url, filename, options).then()
    }

    return (
        <div className="menu-item px-3">
            <div className="menu-link px-md-4" onClick={download}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/files/fil017.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Seller Label
                </div>
            </div>
        </div>
    )
}

export default DownloadSellerLabel
