import { useState } from 'react'
import Sale from '../../data/Sale'
import { isDecoupled } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import { httpHelper } from '../../../common/HttpCommon'
import { DECOUPLE_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../data/SpecificSale'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'
import { ButtonLoading } from '../../../common/components/spinner/ButtonLoading'

const DecoupleButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const [disabledButton, setDisabledButton] = useState(true)
    const [reason, setReason] = useState('')
    const { success, danger } = useAlert()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => {
        if (!loading) setShow(false)
    }
    const handleShow = () => {
        setShow(true)
        setDisabledButton(true)
        setReason('')
    }

    if (!isDecoupled(sale)) return <></>

    const handleDecoupleError = () => {
        setShow(false)
        setLoading(false)
        danger('Error: could not decouple sale!')
    }

    const handleDecoupleSuccess = () => {
        setShow(false)
        setLoading(false)
        success('Sale decoupled!')
        window.setTimeout(() => {
            window.location.reload()
        }, 1500)
    }

    return (
        <>
            <div className="menu-item px-3">
                <div className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/general/gen051.svg'}
                            className="svg-icon-1"
                        />{' '}
                        Decouple
                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>
                            You are about to decouple a sale
                        </Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Please confirm the{' '}
                            <strong>sale id ({sale.saleId})</strong> to execute.
                        </p>
                        <Form.Control
                            type={'text'}
                            placeholder={sale.saleId}
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value)
                                if (e.target.value === sale.saleId)
                                    setDisabledButton(false)
                                else setDisabledButton(true)
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            disabled={loading}
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={() => {
                                decouple(sale.saleId)
                            }}
                            disabled={disabledButton}
                        >
                            <div style={{ width: 60 }}>
                                {loading ? <ButtonLoading /> : 'confirm'}
                            </div>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

    function decouple(saleId: string) {
        setLoading(true)
        httpHelper
            .post(`${DECOUPLE_ROUTE}/${saleId}`)
            .then((response) => {
                setLoading(false)
                if (!response) handleDecoupleError()
                else handleDecoupleSuccess()
            })
            .catch(handleDecoupleError)
    }
}

export default DecoupleButton
