import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Sale from '../../data/Sale'
import { isCancelable } from '../../data/ButtonsRules'
import { KTSVG } from '../../../../../_metronic/helpers'
import { httpHelper } from '../../../common/HttpCommon'
import { SALE_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../data/SpecificSale'
import './../../../common/style/modal.css'
import ConfirmEnvironmentModel from '../modal/ConfirmEnvironmentModel'
import CancelDescriptionModal from '../modal/CancelDescriptionModal'

const CancelButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const { success, danger } = useAlert()

    const [showDescriptionModal, setShowDescriptionModal] = useState(false)
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)
    const handleCloseDescriptionModal = () => setShowDescriptionModal(false)
    const handleConfirmDescriptionModal = (
        cancelReason: string,
        description: string
    ) => {
        cancel(sale.saleId, cancelReason, description).then()
        handleCloseDescriptionModal()
    }
    const handleShow = () => {
        setShowConfirmCancelModal(true)
    }
    const handleCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false)
    }
    const handleConfirmCancelModal = () => {
        setShowConfirmCancelModal(false)
        setShowDescriptionModal(true)
    }

    if (!isCancelable(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <div className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen027.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Cancel
                </div>
            </div>
            <ConfirmEnvironmentModel
                show={showConfirmCancelModal}
                onClose={handleCloseConfirmCancelModal}
                onConfirm={handleConfirmCancelModal}
                title="You are about to cancel a sale"
            />
            <CancelDescriptionModal
                show={showDescriptionModal}
                onClose={handleCloseDescriptionModal}
                onConfirm={handleConfirmDescriptionModal}
            />
        </div>
    )

    async function cancel(
        saleId: string,
        cancelReason: string,
        description: string
    ) {
        let body = {
            cancelReason: cancelReason,
            description: description,
        }
        await httpHelper
            .delete(`${SALE_ROUTE}/${saleId}`, undefined, body)
            .then((response: any) => {
                if (response === undefined || response === null) {
                    danger('Error: could not cancel sale!')
                } else {
                    success('Sale was canceled!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
            .catch((reason) => {
                const rejectionData = reason?.response?.data
                if (rejectionData?.message)
                    if (rejectionData?.errors) {
                        const errorsMessage = rejectionData.errors
                            .map(
                                (error: {
                                    code: string | Number
                                    error: string
                                }) => {
                                    return error.error
                                }
                            )
                            .join('\nError: ')
                        danger('Error: ' + errorsMessage)
                    } else danger('Error: ' + reason?.response?.data?.message)
                else danger('Error: could not cancel sale!')
            })
    }
}

export default CancelButton
