import { CustomRow } from '../../../common/components/data/CardUtil'
import { BoldTextField } from '../../../common/components/fields/BoldTextField'
import { MonetaryField } from '../../../common/components/fields/MonetaryField'
import { TableItemImage } from '../../../common/components/table/TableItemImage'
import { ProductCondition } from '../../../sale/component/label/Condition'
import { ProductTags } from '../label/tags'
import { IProduct } from '../../data/Product'
import { CardLinkField } from '../../../common/components/card/CardLinkField'
import { getSiteUrl } from '../../../Env'
import { ProductServiceImpl } from '../../service/rest/ProductServiceImpl'

const siteUrl = getSiteUrl()

const defaultObject = {
    title: '',
    content: <></>,
}

function getTagsColumn(product: IProduct) {
    if (product.tags != null && product.tags.length > 0) {
        let object = {
            title: 'Tags',
            content: <ProductTags product={product} />,
        }
        return {
            column: [object, defaultObject],
        }
    }
    return {
        column: [
            {
                title: 'Tags',
                content: <BoldTextField>{'-'}</BoldTextField>,
            },
            defaultObject,
        ],
    }
}

export const SummaryView = (product: IProduct): CustomRow[] => {
    const productService = new ProductServiceImpl()
    const productVipUrl = () => {
        if (
            product.grade.description === 'Brand' ||
            product.grade.description === 'Needs Repair'
        ) {
            return <BoldTextField>-</BoldTextField>
        } else {
            return (
                <CardLinkField
                    to={{
                        pathname: `${siteUrl}/product/${product.optimisedUrl}`,
                    }}
                >
                    {product.optimisedUrl}
                </CardLinkField>
            )
        }
    }

    const attributes = product?.attributes?.map((attr) => {
        return {
            column: [
                {
                    title: attr.name,
                    content: <BoldTextField>{attr.value}</BoldTextField>,
                },
                defaultObject,
            ],
        }
    })
    const properties = product?.productProperties?.map((prop) => {
        return {
            column: [
                {
                    title: prop.name,
                    content: <BoldTextField>{prop.value}</BoldTextField>,
                },
                defaultObject,
            ],
        }
    })
    const rows: CustomRow[] = [
        {
            column: [
                {
                    title: '',
                    content: (
                        <span
                            style={{ marginRight: '75px' }}
                            className="fw-bold text-muted fs-3"
                        >
                            Product
                        </span>
                    ),
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: '',
                    content: <BoldTextField>{product.title}</BoldTextField>,
                },
                {
                    title: '',
                    content: <TableItemImage url={product.thumbnail.url} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Currency',
                    content: <BoldTextField>{product.currency}</BoldTextField>,
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: 'Condition',
                    content: (
                        <ProductCondition status={product.grade.description} />
                    ),
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: 'Best Bid',
                    content: MonetaryField(product.bestBid, product.currency),
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: 'Best Ask',
                    content: MonetaryField(product.bestAsk, product.currency),
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: 'Link to VIP',
                    content: productVipUrl(),
                },
                defaultObject,
            ],
        },
        {
            column: [
                {
                    title: '',
                    content: (
                        <div className="mt-4">
                            <span className="fw-bold text-muted fs-3">
                                Attributes
                            </span>
                        </div>
                    ),
                },
                defaultObject,
            ],
        },
        ...attributes,
        {
            column: [
                {
                    title: '',
                    content: (
                        <div className="mt-4">
                            <span className="fw-bold text-muted fs-3">
                                Properties
                            </span>
                        </div>
                    ),
                },
                defaultObject,
            ],
        },
        ...properties,
    ]
    let columns = getTagsColumn(product)
    if (columns != null) {
        rows.splice(4, 0, columns)
    }
    return rows
}
