import { IReturningRequest } from '../data/ReturningRequest'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { SummaryView as SaleSummaryView } from '../../../sale/component/view/SummaryView'
import { ImageView } from './view/ImageView'
import { UserView } from './view/UserView'
import { ReasonView } from './view/ReasonView'
import { RefundView } from './view/RefundView'
import SpecificSale from '../../../sale/data/SpecificSale'
import { SummaryView } from './view/SummaryView'

export function getRows(
    currentPage: string,
    request: IReturningRequest | undefined,
    sale: SpecificSale | undefined
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    if (!request) return renderRows

    switch (currentPage) {
        case 'summary':
            return SummaryView(request)
        case 'images':
            return ImageView(request)
        case 'user':
            return UserView(request)
        case 'reason':
            return ReasonView(request)
        case 'refund':
            return RefundView(request)
        case 'sale':
            return SaleSummaryView(sale)
    }
    return renderRows
}
