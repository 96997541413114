import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { OrderOperation } from '../../../order/component/label/OrderOperation'
import { OrderStatusComponent } from '../../../order/component/label/OrderStatusComponent'
import Order from '../../../order/data/Order'

const idColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <Link to={`/order/${data.orderId}/summary`}>
            <span className="fw-bold">{data?.orderId?.substr(0, 8)}..</span>
        </Link>
    )
}

const productColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <span className="text-dark fw-bolder d-block fs-7">
                {data.productTitle}{' '}
            </span>
        </div>
    )
}

const priceColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-end text-dark fw-bolder fs-6 pe-1">
                {data.price}
            </span>
            <span className="text-muted fw-bold text-muted fs-7">
                {data.currency}
            </span>
        </div>
    )
}

const statusColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <OrderStatusComponent status={data.status} />
        </div>
    )
}

const typeOrderColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <OrderOperation side={data.side} type={data.type} />
        </div>
    )
}
const createdColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}

const updatedColumn = (data: Order) => {
    if (!data) return <></>
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8 mt-2">
                Updated at
            </span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="YYYY-MM-DD HH:mm">{data.updated}</Moment>
            </span>
        </div>
    )
}

export const orderColumns = [
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Product',
        render: productColumn,
    },
    {
        header: 'Price',
        render: priceColumn,
    },
    {
        header: 'Status',
        render: statusColumn,
    },
    {
        header: 'Type/Side',
        render: typeOrderColumn,
    },
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Updated',
        render: updatedColumn,
    },
]
