import { CustomRow } from '../../common/components/data/CardUtil'
import IUser from '../data/User'
import { AddressesView } from './view/AddressesView'
import { OrdersView } from './view/OrdersView'
import { PaymentMethodsView } from './view/PaymentMethodsView'
import { SalesView } from './view/SalesView'
import { SummaryView } from './view/SummaryView'
import { WalletsView } from './view/WalletsView'
import { WithdrawalsView } from './view/WithdrawalsView'

export function getRows(
    currentPage: string,
    user: IUser | undefined
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    if (!user) return renderRows

    switch (currentPage) {
        case 'summary':
            return SummaryView(user)
        case 'user-sales':
            return SalesView(user, currentPage)
        case 'user-orders':
            return OrdersView(user, currentPage)
        case 'addresses':
            return AddressesView(user, currentPage)
        case 'payment-methods':
            return PaymentMethodsView(user, currentPage)
        case 'wallets':
            return WalletsView(user, currentPage)
        case 'withdrawals':
            return WithdrawalsView(user, currentPage)
    }
    return renderRows
}
