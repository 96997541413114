import { useState } from 'react'

export default function useToken() {
    const getToken = () => {
        return localStorage.getItem('Authorization')
    }

    const [token, setToken] = useState(getToken())

    const saveToken = (token: string) => {
        localStorage.setItem('Authorization', token)
        setToken(token)
    }
    return {
        setToken: saveToken,
        token,
    }
}
