import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface TableLinkFieldProps {
    to: string
    children: ReactNode
}

export const TableLinkField = (props: TableLinkFieldProps) => (
    <Link to={props.to}>
        <span className="fw-bold fs-7">{props.children}</span>
    </Link>
)
