import { SimpleUser } from '../../user/data/User'
import { SimpleProduct } from '../../product/data/Product'

export default interface SpecificOrder {
    orderId: string
    productId: string
    userId: string
    currency: string
    price: number
    quantity: number
    filledQuantity: number
    side: OrderSide
    type: OrderType
    status: OrderStatus
    created: string
    updated?: string | null
    expiration?: string | null
    rejectReason?: string | null
    rejectCode?: number | null
    isInstitutional: boolean
    user?: SimpleUser | undefined | null
    productDetail?: SimpleProduct | undefined | null
}

export enum OrderSide {
    ASK = 'ASK',
    BID = 'BID',
}

export enum OrderType {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET',
}

export enum OrderStatus {
    OPEN = 'OPEN',
    REJECTED = 'REJECTED',
    FILLED = 'FILLED',
    PARTIAL_FILLED = 'PARTIAL_FILLED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    CHECKOUT_CANCELLED = 'CHECKOUT_CANCELLED',
    CHECKOUT_EXPIRED = 'CHECKOUT_EXPIRED',
    DELIVERY_EXPIRED = 'DELIVERY_EXPIRED',
}

export const ORDER_STATUS = [
    'OPEN',
    'FILLED',
    'PARTIAL_FILLED',
    'REJECTED',
    'CANCELLED',
    'EXPIRED',
    'CHECKOUT_CANCELLED',
    'CHECKOUT_EXPIRED',
    'DELIVERED_EXPIRED',
]
