import React, { FC, useEffect, useState } from 'react'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import SpecificEvent from '../data/SpecificEvent'
import { CustomRow, MenuItem } from '../../common/components/data/CardUtil'
import useBackofficeUser, {
    getUserPermissions,
} from '../../common/secure/useBackofficeUser'
import Axios, { httpHelper } from '../../common/HttpCommon'
import { EVENT_ROUTE } from '../../common/RouteConstants'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import './../style/event-edit.css'
import { specificEvent } from './EventView'
import { SchemaTable } from './SchemaTable'
import Select from 'react-select'
import Editor from '@monaco-editor/react'
import { FormControl } from 'react-bootstrap-v5'
import { useAlert } from 'react-bootstrap-hooks-alert'
import EventConfigurationUpdateRequest from '../data/EventConfigurationUpdateRequest'
import { menuRules } from '../data/MenuRules'
import { BooleanField } from '../../common/components/fields/BooleanField'

const EventEdit: FC = () => {
    const [event, setEvent] = useState<SpecificEvent[]>(Array<SpecificEvent>())
    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()
    const eventId = addressPage[2]
    const [rows, setRows] = useState<CustomRow[]>(Array<CustomRow>())
    const [sendInterval, setSendInterval] = useState(0)
    const [enabled, setEnabled] = useState(false)
    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationBody, setNotificationBody] = useState('')
    const [pushTitle, setPushTitle] = useState('')
    const [pushBody, setPushBody] = useState('')
    const [emailBcc, setEmailBcc] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const [smsMessage, setSmsMessage] = useState(
        specificEvent !== null &&
            specificEvent.configuration !== null &&
            specificEvent.configuration.smsConfiguration !== null
            ? specificEvent?.configuration.smsConfiguration.message
            : ''
    )
    const [sendPush, setSendPush] = useState(false)
    const [sendSms, setSendSms] = useState(
        specificEvent !== null && specificEvent.configuration !== null
            ? specificEvent.configuration.sendSms
            : false
    )
    const [sendEmail, setSendEmail] = useState(false)
    const [isHtml, setIsHtml] = useState(false)
    const [resourceType, setResourceType] = useState('')
    const [resourceId, setResourceId] = useState('')
    const { success, danger } = useAlert()
    const [
        eventConfigurationUpdateRequest,
        setEventConfigurationUpdateRequest,
    ] = useState(new EventConfigurationUpdateRequest())

    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/notification', '')
        .replace('/push', '')
        .replace('/email', '')
        .replace('/sms', '')
    const menuItems: Array<MenuItem> = menuRules(event[0], pathname, true)
    const { backofficeUser } = useBackofficeUser()
    const userPermissions = getUserPermissions(backofficeUser)

    async function fetchData() {
        // TODO: move this api call to a service
        await httpHelper
            .get<SpecificEvent>(`${EVENT_ROUTE}/${eventId}`)
            .then((resp: SpecificEvent) => {
                setEvent([resp])
                if (resp.configuration !== null)
                    setSendInterval(resp.configuration.sendingIntervalInMinutes)
                setEnabled(!resp.disabled)
                setRows(getRows(resp))
            })
    }

    useEffect(() => {
        fetchData().then()
    }, [])
    useEffect(() => {
        setRows(getRows(event[0]))
    }, [currentSubMenu])

    useEffect(() => {
        setRows(getRows(event[0]))
    }, [sendInterval])

    useEffect(() => {
        if (event !== null && event !== undefined && event.length > 0) {
            const configuration = event[0].configuration
            const notification = configuration?.notificationConfiguration
            const push = configuration?.pushConfiguration
            const email = configuration?.emailConfiguration
            const sms = configuration?.smsConfiguration

            setEnabled(!event[0].disabled)
            if (configuration !== null) {
                eventConfigurationUpdateRequest.configuration.sendingIntervalInMinutes =
                    configuration.sendingIntervalInMinutes
                eventConfigurationUpdateRequest.configuration.sendSms =
                    configuration.sendSms
                eventConfigurationUpdateRequest.configuration.sendEmail =
                    configuration.sendEmail
                eventConfigurationUpdateRequest.configuration.sendPush =
                    configuration.sendPush
            }
            eventConfigurationUpdateRequest.disabled = event[0].disabled
            if (
                configuration !== null &&
                notification !== undefined &&
                notification !== null
            ) {
                eventConfigurationUpdateRequest.configuration.notificationConfiguration =
                    notification
                setNotificationTitle(notification.title)
                setNotificationBody(notification.body)
                if (notification.resourceType)
                    setResourceType(notification.resourceType)
                if (notification.resourceId)
                    setResourceId(notification.resourceId)
            }

            if (configuration !== null && push !== null && push !== undefined) {
                eventConfigurationUpdateRequest.configuration.pushConfiguration =
                    push
                setPushBody(push.body)
                setPushTitle(push.title)
                setSendPush(configuration.sendPush)
            }

            if (
                configuration !== null &&
                email !== null &&
                email !== undefined
            ) {
                eventConfigurationUpdateRequest.configuration.emailConfiguration =
                    email
                if (email.bbc) {
                    let res = ''
                    email.bbc
                        .map((bcc) => {
                            return bcc.email
                        })
                        .forEach((bcc) => {
                            res += bcc + ';'
                        })
                    setEmailBcc(res.substring(0, res.length - 1))
                }
                setEmailSubject(email.subject)
                setSendEmail(configuration.sendEmail)
                setIsHtml(email.html)
                setEmailBody(email.body)
            }

            if (configuration !== null && sms !== null && sms !== undefined) {
                eventConfigurationUpdateRequest.configuration.smsConfiguration =
                    sms
                setSmsMessage(sms.message)
                setSendSms(configuration.sendSms)
            }
        }
    }, [event])

    return (
        <>
            <DynamicCard
                title={'Event'}
                subtitle={`#${eventId}`}
                rows={rows}
                menuItems={menuItems}
                address={currentSubMenu}
                buttons={editButton()}
            />
        </>
    )

    function getRows(event: SpecificEvent) {
        switch (currentSubMenu) {
            case 'summary':
                return summaryRows(event)
            case 'notification':
                return notificationRows(event)
            case 'push':
                return pushRows(event)
            case 'email':
                return emailRows(event)
            case 'sms':
                return smsRows(event)
            default:
                return []
        }
    }

    function save() {
        const eventConfiguration = eventConfigurationUpdateRequest.configuration
        const emailConfiguration = eventConfiguration.emailConfiguration
        const smsConfiguration = eventConfiguration.smsConfiguration
        const pushConfiguration = eventConfiguration.pushConfiguration
        const notificationConfiguration =
            eventConfiguration.notificationConfiguration

        if (eventConfigurationUpdateRequest.disabled) {
            Axios.put(
                `${EVENT_ROUTE}/${eventId}`,
                eventConfigurationUpdateRequest
            )
                .then((r) => {
                    success('Event Updated!')
                    window.setTimeout(() => {
                        window.location.href = `/event-view/${eventId}/${currentSubMenu}`
                    }, 1000)
                })
                .catch(() => {
                    danger('The form is not valid. Please check for errors')
                })
            return
        }
        // validate summary view
        if (
            eventConfiguration.sendingIntervalInMinutes < 0 ||
            isNaN(eventConfiguration.sendingIntervalInMinutes)
        ) {
            danger('Sending Interval cannot be null')
            return
        }

        // validation notification view
        if (notificationConfiguration.title.length < 5) {
            danger(
                'Notification configuration title should have at least 5 digits'
            )
            return
        }

        if (notificationConfiguration.body.length < 5) {
            danger(
                'Notification configuration body should have at least 5 digits'
            )
            return
        }

        if (
            notificationConfiguration.resourceType === null ||
            notificationConfiguration.resourceType === ''
        ) {
            if (
                notificationConfiguration.resourceId !== null &&
                notificationConfiguration.resourceId !== ''
            ) {
                danger(
                    'If you fill in the resourceId, you must fill in the resourceType'
                )
                return
            }
        }
        if (
            notificationConfiguration.resourceId === null ||
            notificationConfiguration.resourceId === ''
        ) {
            if (
                notificationConfiguration.resourceType !== null &&
                notificationConfiguration.resourceType !== ''
            ) {
                danger(
                    'If you fill in the resourceType, you must fill in the resourceId'
                )
                return
            }
        }

        //validation push view
        if (eventConfiguration.sendPush) {
            if (pushConfiguration.title.length < 5) {
                danger('Push configuration title should have at least 5 digits')
                return
            }
            if (pushConfiguration.body.length < 5) {
                danger('Push configuration body should have at least 5 digits')
                return
            }
        }

        //validation email view
        if (eventConfiguration.sendEmail) {
            if (emailConfiguration.subject.length < 5) {
                danger(
                    'Email configuration title should have at least 5 digits'
                )
                return
            }
        }

        //validation sms view
        if (eventConfiguration.sendSms) {
            if (smsConfiguration.message.length < 5) {
                danger('Sms message should have at least 5 digits')
                return
            }
        }

        Axios.put(`${EVENT_ROUTE}/${eventId}`, eventConfigurationUpdateRequest)
            .then((r) => {
                success('Event Updated!')
                window.setTimeout(() => {
                    window.location.href = `/event-view/${eventId}/${currentSubMenu}`
                }, 1000)
            })
            .catch(() => {
                danger('The form is not valid. Please check for errors')
            })
    }

    function editButton() {
        let canEdit = false
        userPermissions.forEach((permission) => {
            if (permission === 'PUT') canEdit = true
        })

        if (event === undefined || !canEdit) return undefined

        return [
            <div className="menu-item px-3">
                <Link
                    to={``}
                    id={'save-button'}
                    className={'menu-link px-3'}
                    onClick={() => {
                        save()
                    }}
                >
                    <KTSVG
                        path={'/media/icons/duotune/general/gen055.svg'}
                        className="svg-icon-1"
                    />{' '}
                    SAVE
                </Link>
            </div>,
        ]
    }

    function smsRows(event: SpecificEvent | undefined) {
        let renderRows: Array<CustomRow> = []
        if (event === undefined) return renderRows

        const meta = event.meta.meta
        renderRows.push(
            {
                column: [
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(0, meta.length / 2)}
                            />
                        ),
                    },
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(meta.length / 2, meta.length)}
                            />
                        ),
                    },
                ],
            },
            { column: [] },
            { column: [] },
            {
                column: [
                    {
                        title: 'Message',
                        content: (
                            <div className="mb-3 w-75">
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={smsMessage}
                                    onChange={(e) => {
                                        setSmsMessage(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.smsConfiguration.message =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: '',
                        content: (
                            <div
                                className="form-check form-switch form-check-custom form-check-solid"
                                id={'enabled-checkbox'}
                            >
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchDefaultSendSms"
                                >
                                    <div className="fw-bold text-muted">
                                        Send SMS
                                    </div>
                                </label>
                                <input
                                    className="form-check-input h-20px flexSwitch"
                                    type="checkbox"
                                    defaultChecked={sendSms}
                                    onChange={(e) => {
                                        setSendSms(!sendSms)
                                        eventConfigurationUpdateRequest.configuration.sendSms =
                                            e.target.checked
                                    }}
                                    id="flexSwitchDefaultSendSms"
                                />
                            </div>
                        ),
                    },
                    { title: '', content: '' },
                ],
            },
            {
                column: [
                    { title: '', content: '' },
                    {
                        title: '',
                        content: (
                            <Link
                                to={'#'}
                                id={''}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    save()
                                }}
                            >
                                <KTSVG
                                    path={
                                        '/media/icons/duotune/general/gen055.svg'
                                    }
                                    className="svg-icon-1"
                                />{' '}
                                SAVE
                            </Link>
                        ),
                    },
                ],
            }
        )

        return renderRows
    }

    function emailRows(event: SpecificEvent | undefined) {
        let renderRows: Array<CustomRow> = []
        if (event === undefined) return renderRows

        const meta = event.meta.meta

        renderRows.push(
            {
                column: [
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(0, meta.length / 2)}
                            />
                        ),
                    },
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(meta.length / 2, meta.length)}
                            />
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'BCC',
                        content: (
                            <div className="mb-3 w-75">
                                <FormControl
                                    type="text"
                                    id="email-bcc"
                                    className="form-control"
                                    defaultValue={emailBcc}
                                    onChange={(e) => {
                                        setEmailBcc(e.target.value)
                                        let bccList = e.target.value.endsWith(
                                            ';'
                                        )
                                            ? e.target.value.substring(
                                                  0,
                                                  e.target.value.length - 1
                                              )
                                            : e.target.value
                                        eventConfigurationUpdateRequest.configuration.emailConfiguration.bbc =
                                            bccList.split(';').map((bcc) => {
                                                return { email: bcc, alias: '' }
                                            })
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Subject',
                        content: (
                            <div className="mb-3 w-75">
                                <FormControl
                                    type="text"
                                    id="email-subject"
                                    className="form-control"
                                    defaultValue={emailSubject}
                                    onChange={(e) => {
                                        setEmailSubject(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.emailConfiguration.subject =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: '',
                        content: (
                            <div
                                className="form-check form-switch form-check-custom form-check-solid"
                                id={'enabled-checkbox'}
                            >
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchDefaultSendEmail"
                                >
                                    <div className="fw-bold text-muted">
                                        Send
                                    </div>
                                </label>
                                <input
                                    className="form-check-input h-20px flexSwitch"
                                    type="checkbox"
                                    defaultChecked={sendEmail}
                                    onChange={(e) => {
                                        setSendEmail(!sendEmail)
                                        eventConfigurationUpdateRequest.configuration.sendEmail =
                                            e.target.checked
                                    }}
                                    id="flexSwitchDefaultSendEmail"
                                />
                            </div>
                        ),
                    },
                    {
                        title: '',
                        content: (
                            <div
                                className="form-check form-switch form-check-custom form-check-solid"
                                id={'enabled-checkbox'}
                            >
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchDefaultIsHtml"
                                >
                                    <div className="fw-bold text-muted">
                                        Is HTML
                                    </div>
                                </label>
                                <input
                                    className="form-check-input h-20px flexSwitch"
                                    type="checkbox"
                                    defaultChecked={isHtml}
                                    onChange={(e) => {
                                        setIsHtml(!isHtml)
                                        eventConfigurationUpdateRequest.configuration.emailConfiguration.html =
                                            e.target.checked
                                    }}
                                    id="flexSwitchDefaultIsHtml"
                                />
                            </div>
                        ),
                    },
                ],
            },

            {
                column: [
                    {
                        title: '',
                        content: (
                            <Editor
                                height="80vh"
                                width="65vw"
                                defaultLanguage="html"
                                value={emailBody}
                                onChange={(event) => {
                                    setEmailBody(event ? event : '')
                                    eventConfigurationUpdateRequest.configuration.emailConfiguration.body =
                                        event ? event : ''
                                }}
                            />
                        ),
                    },
                ],
            },
            {
                column: [
                    { title: '', content: '' },
                    {
                        title: '',
                        content: (
                            <Link
                                to={'#'}
                                id={''}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    save()
                                }}
                            >
                                <KTSVG
                                    path={
                                        '/media/icons/duotune/general/gen055.svg'
                                    }
                                    className="svg-icon-1"
                                />{' '}
                                SAVE
                            </Link>
                        ),
                    },
                ],
            }
        )

        return renderRows
    }

    function pushRows(event: SpecificEvent | undefined) {
        let renderRows: Array<CustomRow> = []
        if (event === undefined) return renderRows
        const meta = event.meta.meta
        renderRows.push(
            {
                column: [
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(0, meta.length / 2)}
                            />
                        ),
                    },
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(meta.length / 2, meta.length)}
                            />
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Title',
                        content: (
                            <div className="mb-3 w-75">
                                <input
                                    type="text"
                                    id="push-title"
                                    className="form-control"
                                    defaultValue={pushTitle}
                                    onChange={(e) => {
                                        setPushTitle(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.pushConfiguration.title =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Body',
                        content: (
                            <div className="mb-3 w-75">
                                <input
                                    type="text"
                                    id="push-body"
                                    className="form-control"
                                    defaultValue={pushBody}
                                    onChange={(e) => {
                                        setPushBody(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.pushConfiguration.body =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: '',
                        content: (
                            <div
                                className="form-check form-switch form-check-custom form-check-solid"
                                id={'enabled-checkbox'}
                            >
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchDefaultSendPush"
                                >
                                    <div className="fw-bold text-muted">
                                        Send Push
                                    </div>
                                </label>
                                <input
                                    className="form-check-input h-20px flexSwitch"
                                    type="checkbox"
                                    defaultChecked={sendPush}
                                    onChange={(e) => {
                                        setSendPush(!sendPush)
                                        eventConfigurationUpdateRequest.configuration.sendPush =
                                            e.target.checked
                                    }}
                                    id="flexSwitchDefaultSendPush"
                                />
                            </div>
                        ),
                    },
                    { title: '', content: '' },
                ],
            },
            {
                column: [
                    { title: '', content: '' },
                    {
                        title: '',
                        content: (
                            <Link
                                to={'#'}
                                id={''}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    save()
                                }}
                            >
                                <KTSVG
                                    path={
                                        '/media/icons/duotune/general/gen055.svg'
                                    }
                                    className="svg-icon-1"
                                />{' '}
                                SAVE
                            </Link>
                        ),
                    },
                ],
            }
        )

        return renderRows
    }

    function notificationRows(event: SpecificEvent | undefined) {
        let renderRows: Array<CustomRow> = []
        if (event === undefined) return renderRows

        const meta = event.meta.meta
        const resourceTypeOptions = [
            { value: '', label: 'Empty' },
            { value: 'ORDER', label: 'Order' },
            { value: 'SALE_BUY', label: 'Sale Buy' },
            { value: 'SALE_SELL', label: 'Sale Sell' },
            { value: 'KYC', label: 'KYC' },
            { value: 'EDIT_ORDER', label: 'Edit Order' },
            { value: 'EXTERNAL_RESOURCE', label: 'External Resource' },
            { value: 'MY_ACCOUNT', label: 'My Account' },
        ]

        renderRows.push(
            {
                column: [
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(0, meta.length / 2)}
                            />
                        ),
                    },
                    {
                        title: '',
                        content: (
                            <SchemaTable
                                meta={meta.slice(meta.length / 2, meta.length)}
                            />
                        ),
                    },
                ],
            },
            { column: [] },
            { column: [] },
            {
                column: [
                    {
                        title: 'Title',
                        content: (
                            <div className="mb-3 w-75">
                                <FormControl
                                    type="text"
                                    id="notification-title"
                                    className="form-control"
                                    defaultValue={notificationTitle}
                                    onChange={(e) => {
                                        setNotificationTitle(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.notificationConfiguration.title =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Body',
                        content: (
                            <div className="mb-3 w-75">
                                <FormControl
                                    type="text"
                                    id="notification-body"
                                    className="form-control"
                                    defaultValue={notificationBody}
                                    onChange={(e) => {
                                        setNotificationBody(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.notificationConfiguration.body =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Resource Type',
                        content: (
                            <Select
                                options={resourceTypeOptions}
                                className="w-400px"
                                defaultValue={{
                                    value: resourceType,
                                    label: resourceType,
                                }}
                                onChange={(e) => {
                                    setResourceType(e!!.value)
                                    eventConfigurationUpdateRequest.configuration.notificationConfiguration.resourceType =
                                        e!!.value
                                }}
                            />
                        ),
                    },
                    { title: '', content: '' },
                ],
            },
            {
                column: [
                    {
                        title: 'Resource Id',
                        content: (
                            <div className="mb-3 w-75">
                                <FormControl
                                    type="text"
                                    id="resource-id"
                                    className="form-control"
                                    defaultValue={resourceId}
                                    onChange={(e) => {
                                        setResourceId(e.target.value)
                                        eventConfigurationUpdateRequest.configuration.notificationConfiguration.resourceId =
                                            e.target.value
                                    }}
                                />
                            </div>
                        ),
                    },
                ],
            },
            {
                column: [
                    { title: '', content: '' },
                    {
                        title: '',
                        content: (
                            <Link
                                to={`#`}
                                id={''}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    save()
                                }}
                            >
                                <KTSVG
                                    path={
                                        '/media/icons/duotune/general/gen055.svg'
                                    }
                                    className="svg-icon-1"
                                />{' '}
                                SAVE
                            </Link>
                        ),
                    },
                ],
            }
        )

        return renderRows
    }

    function summaryRows(event: SpecificEvent | undefined) {
        let renderRows: Array<CustomRow> = []

        if (event === undefined) return renderRows

        renderRows.push({
            column: [
                { title: 'Topic', content: event.topic },
                { title: 'Type', content: event.type },
            ],
        })

        renderRows.push(
            {
                column: [
                    {
                        title: 'Configured',
                        content: <BooleanField condition />,
                    },
                    {
                        title: 'Send Push',
                        content: (
                            <BooleanField
                                condition={!!event?.configuration?.sendPush}
                            />
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Send Email',
                        content: (
                            <BooleanField
                                condition={!!event?.configuration?.sendEmail}
                            />
                        ),
                    },

                    {
                        title: 'Send Interval (minutes)',
                        content: (
                            <input
                                type="number"
                                id="input_send_interval"
                                defaultValue={sendInterval}
                                onChange={(event) => {
                                    setSendInterval(
                                        Number.parseInt(event.target.value)
                                    )
                                    eventConfigurationUpdateRequest.configuration.sendingIntervalInMinutes =
                                        Number.parseInt(event.target.value)
                                }}
                                className="form-control form-control-solid w-auto"
                            />
                        ),
                    },
                ],
            },

            {
                column: [
                    { title: '', content: '' },
                    {
                        title: '',
                        content: (
                            <div
                                className="form-check form-switch form-check-custom form-check-solid"
                                id={'enabled-checkbox'}
                            >
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchDefault"
                                >
                                    <div className="fw-bold text-muted">
                                        Enabled
                                    </div>
                                </label>
                                <input
                                    className="form-check-input h-20px"
                                    type="checkbox"
                                    defaultChecked={enabled}
                                    onChange={(e) => {
                                        eventConfigurationUpdateRequest.disabled =
                                            !e.target.checked
                                        setEnabled(!enabled)
                                    }}
                                    id="flexSwitchDefault"
                                />
                            </div>
                        ),
                    },
                ],
            },

            {
                column: [
                    { title: '', content: '' },
                    {
                        title: 'Save',
                        content: (
                            <Link
                                to={'#'}
                                id={''}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    save()
                                }}
                            >
                                <KTSVG
                                    path={
                                        '/media/icons/duotune/general/gen055.svg'
                                    }
                                    className="svg-icon-1"
                                />{' '}
                                SAVE
                            </Link>
                        ),
                    },
                ],
            }
        )

        return renderRows
    }
}

export default EventEdit
