import Sale from '../../../data/Sale'
import SpecificSale from '../../../data/SpecificSale'
import { useState } from 'react'
import { httpHelper } from '../../../../common/HttpCommon'
import { SHIPMENT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { isMarkedSellerAsFailed } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'
import EnvironmentConfirmationModal from '../../../../common/components/modal/EnvironmentConfirmationModal'

const SellerToFailedButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const { success, danger } = useAlert()
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setConfirmModalVisible(false)
    }

    const handleShowConfirmationModal = () => {
        setConfirmModalVisible(true)
    }

    if (!isMarkedSellerAsFailed(sale)) return <></>
    return (
        <div className="menu-item px-3">
            <div
                className="menu-link px-md-4"
                onClick={handleShowConfirmationModal}
            >
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/coding/cod008.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Mark as Failed
                </div>
            </div>
            <EnvironmentConfirmationModal
                show={confirmModalVisible}
                onClose={handleClose}
                onConfirm={markAsFailed}
                actionName={'mark seller as failed'}
                loading={loading}
            />
        </div>
    )

    async function markAsFailed() {
        setLoading(true)
        let trackingNumber = ''

        if (sale.sellerShipmentDetails?.postLocationDetails)
            trackingNumber =
                sale.sellerShipmentDetails.postLocationDetails.shipmentId
        handleClose()
        await httpHelper
            .put(`${SHIPMENT_ROUTE}/${trackingNumber}/status?status=FAILED`)
            .then((response) => {
                if (!response) danger('Error: Not marked as failed!')
                else {
                    success('Shipment mark as failed!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
}

export default SellerToFailedButton
