import LoginService from '../LoginService'

export default class LoginServiceDefaultImpl implements LoginService {
    saveJwtToken(jwt: string) {
        localStorage.setItem('Authorization', jwt)
    }
    logout() {
        localStorage.removeItem('Authorization')
        window.location.reload()
    }
}
