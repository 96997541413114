import { useAlert } from 'react-bootstrap-hooks-alert'
import {
    BuyerShipmentDetails,
    IReturningRequest,
} from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
import { TableTextField } from '../../../common/components/table/TableTextField'
interface ConfirmReturnButtonProps {
    request: IReturningRequest
}

const ModalBody = (buyerShipmentDetails: BuyerShipmentDetails) => (
    <TableTextField>{buyerShipmentDetails.address.street}</TableTextField>
)

export const ConfirmBuyerShipmentDetailsButton = ({
    request,
}: ConfirmReturnButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function confirm() {
        if (request.buyerShipmentDetails) {
            const response =
                await returningRequestService.confirmBuyerShipmentDetails(
                    request.requestId,
                    request.buyerShipmentDetails.addressId,
                    request.buyerShipmentDetails.deliveryOptionId,
                    request.userId
                )
            if (response) {
                success('Buyer shipment details confirmed!')
                window.setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } else {
                danger('Error: could not confirm buyer shipment details!')
            }
        }
    }

    return request.buyerShipmentDetails ? (
        <ApproveActionButton
            buttonText="Confirm buyer shipment details"
            modalTitle="Buyer shipment details"
            modalText="Are you sure you want to confirm buyer shipment details?"
            action={confirm}
            modalBody={ModalBody(request.buyerShipmentDetails)}
        />
    ) : (
        <ApproveActionButton
            buttonText="Confirm buyer shipment details"
            modalTitle="Buyer shipment details"
            modalText="Buyer shipment details not found. It is not possible to confirm."
        />
    )
}
