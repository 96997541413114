import { CustomRow } from '../../../../common/components/data/CardUtil'
import { BoldTextField } from '../../../../common/components/fields/BoldTextField'
import { BooleanField } from '../../../../common/components/fields/BooleanField'
import { DateField } from '../../../../common/components/fields/DateField'
import { TextField } from '../../../../common/components/fields/TextField'
import { RequiredInformationBadge } from '../../../component/badge/RequiredInformationBadge'
import { ReturnTypeBadgesList } from '../../../component/badge/ReturnTypeBadgesList'
import { IReturningRequest } from '../../data/ReturningRequest'

export function ReasonView(request: IReturningRequest): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []
    const {
        acceptedReturnTypes,
        active,
        created,
        isDefective,
        requiredInformation,
        returningReasonId,
        title,
        updated,
    } = request.returningReason
    renderRows.push(
        {
            column: [
                {
                    title: 'Title',
                    content: <TextField>{title}</TextField>,
                },
                {
                    title: 'Created at',
                    content: <DateField date={created} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Active',
                    content: <BooleanField condition={active} />,
                },
                {
                    title: 'Is defective',
                    content: <BooleanField condition={isDefective} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Reason ID',
                    content: <BoldTextField>{returningReasonId}</BoldTextField>,
                },
                {
                    title: 'Updated',
                    content: <DateField date={updated} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Accepted return types',
                    content: (
                        <ReturnTypeBadgesList types={acceptedReturnTypes} />
                    ),
                },
                {
                    title: 'Required information',
                    content: (
                        <RequiredInformationBadge info={requiredInformation} />
                    ),
                },
            ],
        }
    )
    return renderRows
}
