export default class EventConfigurationUpdateRequest {
    public disabled: boolean = false
    public configuration: Configuration = {
        sendEmail: false,
        sendPush: false,
        sendSms: false,
        sendingIntervalInMinutes: 0,
        pushConfiguration: {
            body: '',
            title: '',
        },
        notificationConfiguration: {
            body: '',
            title: '',
            resourceId: '',
            resourceType: '',
        },
        emailConfiguration: {
            body: '',
            html: false,
            subject: '',
            bbc: [{ email: '', alias: '' }],
        },
        smsConfiguration: {
            message: '',
        },
    }
}

export interface Configuration {
    sendEmail: boolean
    sendPush: boolean
    sendSms: boolean
    sendingIntervalInMinutes: number
    pushConfiguration: PushConfiguration
    notificationConfiguration: NotificationConfiguration
    emailConfiguration: EmailConfiguration
    smsConfiguration: SmsConfiguration
}

export interface PushConfiguration {
    body: string
    title: string
}

export interface NotificationConfiguration {
    body: string
    title: string
    resourceId: string | null
    resourceType: string | null
}

export interface SmsConfiguration {
    message: string
}

export interface EmailConfiguration {
    body: string
    html: boolean
    subject: string
    bbc: Array<Bcc>
}

export interface Bcc {
    email: string
    alias: string
}
