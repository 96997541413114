import React from 'react'
import { Type } from '../../common/components/table/DynamicFilter'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import Event from '../data/Event'
import { EVENT_ROUTE } from '../../common/RouteConstants'
import { Link } from 'react-router-dom'
import { BooleanField } from '../../common/components/fields/BooleanField'

const Events: React.FC = () => {
    const topicAndTypeColumn = (data: Event) => {
        return (
            <div>
                <Link
                    to={`/event-view/${data.eventConfigurationId}/summary`}
                    className="text-dark text-hover-primary fw-bolder d-block fs-6"
                >
                    {data.type}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    {data.topic}
                </span>
            </div>
        )
    }
    const isConfiguredColumn = (data: Event) => {
        return <BooleanField condition={data.configured} />
    }
    const isEnabledColumn = (data: Event) => {
        return <BooleanField condition={!data.disabled} />
    }
    const isPushConfiguredColumn = (data: Event) => {
        return <BooleanField condition={data?.configuration?.sendPush} />
    }
    const isEmailConfiguredColumn = (data: Event) => {
        return <BooleanField condition={data?.configuration?.sendEmail} />
    }
    const isSmsConfiguredColumn = (data: Event) => {
        return <BooleanField condition={data?.configuration?.sendSms} />
    }
    const columns = [
        {
            header: 'Type & Topic',
            render: topicAndTypeColumn,
        },
        {
            header: 'Configured',
            render: isConfiguredColumn,
        },
        {
            header: 'Enabled',
            render: isEnabledColumn,
        },
        {
            header: 'Push Notification',
            render: isPushConfiguredColumn,
        },
        {
            header: 'Email Notification',
            render: isEmailConfiguredColumn,
        },
        {
            header: 'SMS Notification',
            render: isSmsConfiguredColumn,
        },
    ]

    const filters = [
        {
            title: '',
            queryParam: '',
            type: Type.REMOTE,
        },
    ]
    return (
        <>
            <DynamicTable
                title="Event Configuration"
                description="List of Events"
                columns={columns}
                filters={filters}
                resource={EVENT_ROUTE}
            />
        </>
    )
}
export { Events }
