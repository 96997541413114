import React, { useState } from 'react'
import 'flatpickr/dist/themes/dark.css'

type Props = {
    title: string
    defaultValue?: string | null
    onChangeCallback: (newValue: string) => void
}

export const DynamicFilterSwitch = ({
    title,
    defaultValue,
    onChangeCallback,
}: Props) => {
    const [value, setValue] = useState<boolean>(
        defaultValue != null ? defaultValue === 'true' : false
    )
    const onChange = () => {
        onChangeCallback(String(!value))
        setValue(!value)
    }

    return (
        <div className="form-check form-switch form-check-custom form-check-solid">
            <label className="form-check-label" htmlFor="flexSwitchDefault">
                <div className="fw-bold text-muted">{title}</div>
            </label>
            <input
                className="form-check-input h-20px"
                type="checkbox"
                defaultChecked={value}
                onChange={onChange}
                id="flexSwitchDefault"
            />
        </div>
    )
}
