import React from 'react'
import { Meta } from '../data/SpecificEvent'

type Props = {
    meta: Array<Meta>
}
export const SchemaTable = ({ meta }: Props) => {
    return (
        <table className="table table-row-dashed table-row-gray-300  gs-7 gy-2 gx-3">
            <thead>
                <tr className="fw-bolder fs-6 text-gray-800 ">
                    <th>Name</th>
                    <th>Type</th>
                </tr>
            </thead>

            <tbody>
                {meta.map((it, index) => {
                    return (
                        <tr key={`${index.toString()}-tr`}>
                            <td key={index}>{it.name}</td>
                            <td>{it.type}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
