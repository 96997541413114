import React, { useEffect, useState } from 'react'
import SpecificOrder from '../data/SpecificOrder'
import { httpHelper } from '../../common/HttpCommon'
import { ORDER_ROUTE } from '../../common/RouteConstants'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { CustomRow } from '../../common/components/data/CardUtil'
import { Link } from 'react-router-dom'
import { ProductCondition } from '../../sale/component/label/Condition'
import { DataField } from '../../common/components/fields/DataField'
import { MonetaryField } from '../../common/components/fields/MonetaryField'
import { OrderStatusComponent } from './label/OrderStatusComponent'
import { OrderOperation } from './label/OrderOperation'
import { InstitutionalComponent } from './label/InstitutionalComponent'
import CancelButton from './buttons/CancelButton'
import { BoldTextField } from '../../common/components/fields/BoldTextField'
import { CardLinkField } from '../../common/components/card/CardLinkField'
import { getSiteUrl } from '../../Env'

const siteUrl = getSiteUrl()

export function OrderView() {
    const [order, setOrder] = useState<SpecificOrder[]>(Array<SpecificOrder>())
    const addressPage = window.location.pathname.split('/')
    const orderId = addressPage[2]

    async function fetchData() {
        // TODO: move this api call to a service
        await httpHelper
            .get<SpecificOrder>(`${ORDER_ROUTE}/${orderId}`)
            .then((resp) => {
                setOrder([resp])
            })
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    const currentOrder = order !== null ? order[0] : null
    const rows = getRows(currentOrder)

    return (
        <>
            <DynamicCard title={'Order'} subtitle={orderId} rows={rows} />
            {cancelButton()}
        </>
    )

    function cancelButton() {
        if (
            order === null ||
            order === undefined ||
            order[0] === null ||
            order[0] === undefined
        )
            return <></>
        return <CancelButton order={order[0]} isMenu={false} />
    }

    function getRows(order: SpecificOrder | null) {
        let renderRows: Array<CustomRow> = []

        if (order === null || order === undefined) return renderRows

        renderRows.push(
            {
                column: [
                    {
                        title: 'Product',
                        content: (
                            <Link
                                to={`/product/${order.productId}/summary`}
                                className="text-dark fw-bolder text-hover-primary"
                            >
                                {order.productDetail?.title}{' '}
                                <ProductCondition
                                    status={
                                        order.productDetail?.grade.description.toString()!!
                                    }
                                />
                            </Link>
                        ),
                    },
                    {
                        title: 'User',
                        content: (
                            <Link
                                to={`/user/${order.user?.userId}/summary`}
                                className="text-dark fw-bolder text-hover-primary"
                            >
                                {order.user?.fullName}
                                {InstitutionalComponent(order.isInstitutional)}
                            </Link>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Operation',
                        content: (
                            <OrderOperation
                                side={order.side}
                                type={order.type}
                            />
                        ),
                    },
                    {
                        title: 'E-mail',
                        content: (
                            <BoldTextField>{order.user?.email}</BoldTextField>
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Price',
                        content: MonetaryField(order.price, order.currency),
                    },
                    {
                        title: 'Created',
                        content: DataField(order.created),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Status',
                        content: <OrderStatusComponent status={order.status} />,
                    },

                    {
                        title: 'Expiration',
                        content: DataField(order.expiration),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Link to VIP',
                        content: (
                            <CardLinkField
                                to={{
                                    pathname: `${siteUrl}/product/${order.productDetail?.optimisedUrl}`,
                                }}
                            >
                                {order.productDetail?.optimisedUrl}
                            </CardLinkField>
                        ),
                    },
                    {
                        title: '',
                        content: '',
                    },
                ],
            }
        )

        if (order.rejectReason) {
            renderRows.push({
                column: [
                    {
                        title: 'Reject Reason',
                        content: order.rejectReason,
                    },
                    {
                        title: '',
                        content: '',
                    },
                ],
            })
        }

        return renderRows
    }
}
