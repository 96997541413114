import { getDojiEnv } from '../../Env'
import SpecificSale from '../data/SpecificSale'
const STRIPE_BASE_URL = `https://dashboard.stripe.com/${
    getDojiEnv() === 'PRODUCTION' ? '' : 'test/'
}payments/`
export const paymentServiceProviderDataFromSale = (sale: SpecificSale) => {
    if (sale?.paymentDetails?.clientSecret?.includes('_secret_'))
        return {
            provider: 'Stripe',
            link:
                STRIPE_BASE_URL +
                sale.paymentDetails.clientSecret.split('_secret_')[0],
        }
    return null
}
