import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import React from 'react'
import { DataField } from '../../../common/components/fields/DataField'
import { CardLinkField } from '../../../common/components/card/CardLinkField'
import { paymentServiceProviderDataFromSale } from '../../utils/utils'
import { MonetaryField } from '../../../common/components/fields/MonetaryField'
import { TextField } from '../../../common/components/fields/TextField'
import PaymentDetails from '../../data/PaymentDetails'

export function PaymentView(
    sale: SpecificSale,
    paymentDetails?: PaymentDetails
): Array<CustomRow> {
    const paymentServiceProviderData = paymentServiceProviderDataFromSale(sale)
    let renderRows: Array<CustomRow> = []
    renderRows.push(
        {
            column: [
                { title: 'Type', content: sale.paymentDetails.type },
                {
                    title: 'Credit Card',
                    content:
                        sale.paymentDetails.paymentMethod?.card?.maskedNumber,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Charge Date',
                    content: DataField(sale.paymentDetails.chargeDate),
                },
                {
                    title: 'Capture Date',
                    content: DataField(sale.paymentDetails.captureDate),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Expiration Date',
                    content: DataField(sale.paymentDetails.expirationDate),
                },
                {
                    title: 'Release Date',
                    content: DataField(sale.paymentDetails.releaseDate),
                },
            ],
        },
        {
            column: [
                {
                    title: `Seller ${
                        sale.sellerDetail?.document?.type ?? 'Document'
                    }`,
                    content: (
                        <TextField>
                            {sale.sellerDetail?.document?.value}
                        </TextField>
                    ),
                },
                {
                    title: `Buyer ${
                        paymentDetails?.userDocument?.type ?? 'Document'
                    }`,
                    content: (
                        <TextField>
                            {paymentDetails?.userDocument?.value}
                        </TextField>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Status',
                    content: (
                        <TextField>{paymentDetails?.status ?? '-'}</TextField>
                    ),
                },
                {
                    title: '',
                    content: '',
                },
            ],
        }
    )
    if (paymentServiceProviderData) {
        renderRows.push({
            column: [
                {
                    title: paymentServiceProviderData.provider,
                    content: (
                        <CardLinkField
                            to={{ pathname: paymentServiceProviderData.link }}
                        >
                            {paymentServiceProviderData.link}
                        </CardLinkField>
                    ),
                },
                {
                    title: 'Price',
                    content: MonetaryField(sale.price, sale.currency),
                },
            ],
        })
    }
    if (paymentDetails?.details) {
        renderRows.push({
            column: [
                {
                    title: 'Number of Installments',
                    content: (
                        <TextField>
                            {paymentDetails.details?.numberOfInstallments}
                        </TextField>
                    ),
                },
                {
                    title: 'Installments Price',
                    content: (
                        <TextField>
                            {MonetaryField(
                                paymentDetails.details?.installmentsPrice,
                                sale.currency
                            )}
                        </TextField>
                    ),
                },
            ],
        })
        renderRows.push({
            column: [
                {
                    title: 'Interest Amount',
                    content: (
                        <TextField>
                            {MonetaryField(
                                paymentDetails.details?.interestAmount,
                                sale.currency
                            )}
                        </TextField>
                    ),
                },
                {
                    title: 'Annual Interest Rate',
                    content: (
                        <TextField>{`${
                            paymentDetails.details?.annualInterestRate * 100
                        }%`}</TextField>
                    ),
                },
            ],
        })
        renderRows.push({
            column: [
                {
                    title: 'Discount Amount',
                    content: (
                        <TextField>
                            {MonetaryField(
                                paymentDetails.details?.discountAmount,
                                sale.currency
                            )}
                        </TextField>
                    ),
                },
                {
                    title: 'Discount Rate',
                    content: (
                        <TextField>{`${
                            paymentDetails.details?.discountRate * 100
                        }%`}</TextField>
                    ),
                },
            ],
        })
        if (paymentDetails?.providerId) {
            renderRows.push({
                column: [
                    {
                        title: 'Iugu Link',
                        content: (
                            <CardLinkField
                                to={{
                                    pathname: `https://alia.iugu.com/receive/invoices/${paymentDetails?.providerId}`,
                                }}
                            >
                                {paymentDetails?.providerId}
                            </CardLinkField>
                        ),
                    },
                    {
                        title: 'Final Price paid by Buyer',
                        content: (
                            <TextField>
                                {MonetaryField(
                                    paymentDetails.totalPaid,
                                    sale.currency
                                )}
                            </TextField>
                        ),
                    },
                ],
            })
        }
    }
    return renderRows
}
