import { KTSVG } from '../../../../../_metronic/helpers'
import React from 'react'

export function InstitutionalComponent(isInstitutional: boolean) {
    if (isInstitutional)
        return (
            <>
                <KTSVG
                    path="/media/svg/others/building.svg"
                    className="svg-icon svg-icon-1x svg-icon-success booleanField"
                />
            </>
        )
    return <></>
}
