import { ReactChild, ReactChildren } from 'react'

type Props = {
    status: string
}

export const GRADE = [
    'Brand New',
    'Mint',
    'Very Good',
    'Good',
    'Fair',
    'Needs Repair',
    'Like New',
]

export const ProductCondition = ({ status }: Props) => {
    let statusComp = null

    switch (status) {
        case GRADE[6]:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case GRADE[0]:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case GRADE[1]:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case GRADE[2]:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case GRADE[3]:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case GRADE[4]:
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case GRADE[5]:
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{status}</Label>
            break
        }
    }

    return <>{statusComp}</>
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <>
            <span className={`badge ${css} fs-7 fw-bolder`}>{children}</span>
        </>
    )
}
