import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
interface ActionColumnContainerProps {
    children: ReactNode
}

export const ActionColumnContainer = (props: ActionColumnContainerProps) => {
    const { pathname, search } = useLocation()
    return (
        <div className="d-flex justify-content-start">
            <Link
                to={pathname + search}
                className="btn btn-bg-white btn-sm show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="top-end"
            >
                <span>
                    <i className="bi bi-three-dots fs-5" />
                </span>
            </Link>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4"
                data-kt-menu="true"
            >
                {props.children}
            </div>
        </div>
    )
}
