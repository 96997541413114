import { ReactNode, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'
interface ApproveActionButtonProps {
    modalTitle: string
    action?: () => Promise<void>
    buttonText: string
    modalText: string
    modalBody?: ReactNode
}

export const ApproveActionButton = ({
    modalTitle,
    action,
    buttonText,
    modalText,
    modalBody,
}: ApproveActionButtonProps) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
    }

    const handlePrimaryClick = () => {
        handleClose()
        if (action) action()
    }
    return (
        <div className="menu-item px-3">
            <div className="menu-link px-md-4" onClick={handleShow}>
                <KTSVG
                    path={'/media/icons/duotune/arrows/arr016.svg'}
                    className="svg-icon-1 svg-icon-success me-1"
                />
                {buttonText}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <p>{modalText}</p>
                    {modalBody}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {!!action && (
                        <Button variant="primary" onClick={handlePrimaryClick}>
                            Confirm
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
