import { useEffect, useState } from 'react'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { MenuItem } from '../../common/components/data/CardUtil'
import { getRows } from './RenderRows'
import { httpHelper } from '../../common/HttpCommon'
import {
    DECOUPLE_ROUTE,
    PAYMENTS_ROUTE,
    SALE_ROUTE,
} from '../../common/RouteConstants'
import SpecificSale from '../data/SpecificSale'
import { getButtons } from './ButtonsComponent'
import Decouple from '../../decouple/data/Decouple'
import { useLocation } from 'react-router-dom'
import Analysis from '../../fraudanalysis/data/Analysis'
import { FraudAnalysisServiceImpl } from '../../fraudanalysis/service/impl/FraudAnalysisServiceImpl'
import PaymentDetails from '../data/PaymentDetails'

function menuRules(sale: SpecificSale | undefined, pathname: string) {
    let list = []

    list.push({ title: 'SUMMARY', to: `${pathname}/summary` })
    if (!sale) return list
    if (sale.productDetail)
        list.push({ title: 'PRODUCT', to: `${pathname}/product` })
    if (sale.paymentDetails)
        list.push({ title: 'PAYMENT', to: `${pathname}/payment` })
    if (sale.buyerShipmentDetails || sale.sellerShipmentDetails)
        list.push({ title: 'SHIPMENTS', to: `${pathname}/shipments` })

    list.push({ title: 'TRANSACTIONS', to: `${pathname}/transactions` })
    // list.push({ title: 'CHANGES HISTORY', to: `${pathname}/changes-history` })
    list.push({ title: 'FRAUD ANALYSIS', to: `${pathname}/fraud-analysis` })
    if (sale.decoupleId)
        list.push({ title: 'DECOUPLE', to: `${pathname}/decouple` })
    if (sale.returningRequestIds && sale.returningRequestIds.length > 0)
        list.push({
            title: 'RETURN',
            to: `/return/requests/${sale.returningRequestIds[0]}/sale`,
        })

    return list
}

export function SaleView() {
    const [sale, setSale] = useState<SpecificSale>()
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>()
    const [fraudAnalysis, setFraudAnalysis] = useState<Analysis>()
    const [loading, setLoading] = useState(false)
    const { pathname: currentPath } = useLocation()
    const fraudAnalysisService = new FraudAnalysisServiceImpl()

    async function fetchData() {
        setLoading(true)
        try {
            await httpHelper
                .get<SpecificSale>(
                    `${SALE_ROUTE}/${saleId}?useCacheOnShipment=false`
                )
                .then((resp) => {
                    setSale(resp)
                })

            setFraudAnalysis(await fraudAnalysisService.getAnalysis(saleId))
        } finally {
            setLoading(false)
        }
    }

    async function fetchPaymentDetails() {
        setLoading(true)
        try {
            await httpHelper
                .get<PaymentDetails>(
                    `${PAYMENTS_ROUTE}/${sale?.paymentDetails?.paymentId}`
                )
                .then((resp) => {
                    setPaymentDetails(resp)
                })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPaymentDetails()
    }, [sale])

    useEffect(() => {
        fetchData()
    }, [currentPath])

    useEffect(() => {
        if (sale?.decoupleId && !sale.decouple) {
            httpHelper
                .get<Decouple>(`${DECOUPLE_ROUTE}/${sale.decoupleId}`)
                .then((resp) => {
                    setSale({ ...sale, decouple: resp })
                })
        }
    }, [sale])

    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()
    const saleId = addressPage[2]

    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/product', '')
        .replace('/payment', '')
        .replace('/shipments', '')
        .replace('/transactions', '')
        .replace('/dashboard', '')
        .replace('/changes-history', '')
        .replace('/decouple', '')
        .replace('/fraud-analysis', '')

    const menuItems: Array<MenuItem> = menuRules(sale, pathname)
    const buttons = getButtons(sale)
    const rows = getRows(currentSubMenu, sale, fraudAnalysis, paymentDetails)

    return (
        <DynamicCard
            title={`Sale`}
            subtitle={`#${saleId}`}
            menuItems={menuItems}
            buttons={buttons}
            rows={rows}
            address={currentSubMenu}
            loading={loading}
        />
    )
}
