import { ReactChild, ReactChildren } from 'react'

type Props = {
    status: string
}

export const SELLER_STATUS = [
    'PENDING_CHECKOUT',
    'PENDING_BUYER_CHECKOUT',
    'PROCESSING_PAYMENT',
    'PENDING_PAYMENT_3DS',
    'PAYMENT_REJECTED',
    'CHECKOUT_EXPIRED',
    'CHECKOUT_3DS_EXPIRED',
    'CHECKOUT_CANCELLED',
    'WAITING_SHIPMENT_INFO',
    'WAITING_SHIPMENT_COLLECTION',
    'WAITING_SHIPMENT_DROPOFF',
    'FAILED_SHIPMENT_DROPOFF',
    'FAILED_SHIPMENT_COLLECTION',
    'IN_TRANSIT',
    'GRADE_VERIFICATION',
    'COMPLETED',
    'GRADE_REJECTED',
    'DELIVERY_EXPIRED',
    'CANCELLED',
    'DECOUPLED',
]

export const SellerStatus = ({ status }: Props) => {
    let statusComp = null

    switch (status) {
        case 'PENDING_CHECKOUT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PENDING_BUYER_CHECKOUT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PROCESSING_PAYMENT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PENDING_PAYMENT_3DS':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'PAYMENT_REJECTED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_3DS_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CHECKOUT_CANCELLED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'WAITING_SHIPMENT_INFO':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'WAITING_SHIPMENT_COLLECTION':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'WAITING_SHIPMENT_DROPOFF':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'FAILED_SHIPMENT_DROPOFF':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'FAILED_SHIPMENT_COLLECTION':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'IN_TRANSIT':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'GRADE_VERIFICATION':
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case 'COMPLETED':
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case 'GRADE_REJECTED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'DELIVERY_EXPIRED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'CANCELLED':
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case 'DECOUPLED':
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{status}</Label>
            break
        }
    }

    return <>{statusComp}</>
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <>
            <span className={`badge ${css} fs-7 fw-bolder`}>{children}</span>
        </>
    )
}
