import { Label } from './Label'
import { RefundStatus } from '../../requests/data/ReturningRequest'

type RefundStatusBadgeProps = {
    status: RefundStatus
}

export const RefundStatusBadge = ({ status }: RefundStatusBadgeProps) => {
    switch (status) {
        case RefundStatus.COMPLETED:
            return <Label css="badge-success">{status}</Label>

        case RefundStatus.PENDING_REFUND_TRANSACTION:
            return <Label css="badge-light-warning">{status}</Label>

        case RefundStatus.PENDING_REFUND_PAYMENT:
            return <Label css="badge-warning">{status}</Label>

        case RefundStatus.FAILED:
            return <Label css="badge-light-danger">{status}</Label>

        default:
            return <Label css="badge-light-dark">{status}</Label>
    }
}
