import { CustomRow } from '../../../common/components/data/CardUtil'
import SpecificSale from '../../data/SpecificSale'
import React from 'react'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { SALE_CHANGES_HISTORY_ROUTE } from '../../../common/RouteConstants'
import Moment from 'react-moment'
import ChangeHistory from '../../data/ChangeHistory'
import { Link } from 'react-router-dom'
import { ProductCondition } from '../label/Condition'

export function ChangesHistoryView(sale: SpecificSale): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    const updatedColumn = (data: ChangeHistory) => {
        return (
            <div>
                <span className="text-muted fw-bold d-block fs-8">
                    Created at
                </span>
                <span className="text-dark fw-bolder d-block fs-7">
                    <Moment format="YYYY-MM-DD HH:mm">{data.created}</Moment>
                </span>
            </div>
        )
    }

    const idColumn = (data: ChangeHistory) => {
        return <span>{data.saleHistoryChangeId}</span>
    }

    const oldProductColumn = (data: ChangeHistory) => {
        if (data.productChange === null || data.productChange === undefined)
            return <></>
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex justify-content-start flex-column">
                    <Link
                        to="#"
                        className="text-dark fw-bolder text-hover-primary"
                    >
                        {data.productChange.oldProductName}
                        {data.productChange.oldGradeDescription !== null ? (
                            <span className="text-muted fw-bold text-muted fs-7">
                                <ProductCondition
                                    status={
                                        data.productChange.oldGradeDescription
                                    }
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                    </Link>
                    {data.productChange.oldProductId}
                </div>
            </div>
        )
    }

    const newProductColumn = (data: ChangeHistory) => {
        if (data.productChange === null || data.productChange === undefined)
            return <></>
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex justify-content-start flex-column">
                    <Link
                        to="#"
                        className="text-dark fw-bolder text-hover-primary"
                    >
                        {data.productChange.newProductName}
                        {data.productChange.newGradeDescription !== null ? (
                            <span className="text-muted fw-bold text-muted fs-7">
                                <ProductCondition
                                    status={
                                        data.productChange.newGradeDescription
                                    }
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                    </Link>
                    {data.productChange.newProductId}
                </div>
            </div>
        )
    }

    const descriptionColumn = (data: ChangeHistory) => {
        return <div>{data.description}</div>
    }

    const columns = [
        {
            header: 'Id',
            render: idColumn,
        },
        {
            header: 'Updated',
            render: updatedColumn,
        },
        {
            header: 'old Product',
            render: oldProductColumn,
        },
        {
            header: 'New Product',
            render: newProductColumn,
        },
        {
            header: 'Description',
            render: descriptionColumn,
        },
    ]

    renderRows.push(
        { column: [] },
        { column: [] },
        {
            column: [
                {
                    title: '',
                    content: (
                        <DynamicTable
                            title={'Changes History'}
                            description={'List of Sale Changes History'}
                            resource={`${SALE_CHANGES_HISTORY_ROUTE}/${sale.saleId}`}
                            columns={columns}
                            filters={[]}
                        />
                    ),
                },
            ],
        }
    )
    return renderRows
}
