import { useState, useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import IUser from '../../data/User'
import { httpHelper } from '../../../common/HttpCommon'
import { IMPORT_ORDERS_ROUTE } from '../../../common/RouteConstants'
import { DropzoneField } from '../../../common/components/fields/DropzoneField'

type uploadError = {
    productId?: string
    side?: string
    message?: string
    code?: Number
    error?: string
}
type uploadResponse = {
    asks: Object[]
    bids: Object[]
    errors: uploadError[]
    numberOfAsks: Number
    numberOfBids: Number
    userId: string
}

interface UploadOrdersCsvProps {
    user: IUser
}

export const UploadOrdersCsv = ({ user }: UploadOrdersCsvProps) => {
    const [show, setShow] = useState(false)
    const [canUpload, setCanUpload] = useState(true)
    const [selectedFile, setSelectedFile] = useState<File>()
    const [uploadSuccess, setUploadSuccess] = useState<uploadResponse>()
    const [uploadErrors, setUploadErrors] = useState<uploadError[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        if (!loading) {
            setShow(false)
            setSelectedFile(undefined)
            setUploadSuccess(undefined)
            setUploadErrors([])
            setErrorMessage('')
        }
    }
    const handleShow = () => {
        setShow(true)
    }

    const onFrontDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((currentFile: File) => {
            const reader = new FileReader()
            reader.onload = () => {
                setSelectedFile(currentFile)
                setUploadSuccess(undefined)
                setUploadErrors([])
                setErrorMessage('')
            }
            reader.readAsArrayBuffer(currentFile)
            setCanUpload(true)
        })
    }, [])
    const onDropRejected = useCallback(() => {
        setSelectedFile(undefined)
        setUploadSuccess(undefined)
        setUploadErrors([])
        setErrorMessage('')
        setErrorMessage('Invalid File')
    }, [])

    async function action(fileToUpload: File | undefined) {
        if (fileToUpload === undefined) {
            handleClose()
        } else {
            setCanUpload(false)
            setLoading(true)
            const formData = new FormData()
            formData.append('file', fileToUpload)
            formData.append('userId', user.userId)

            await httpHelper
                .post<uploadResponse>(`${IMPORT_ORDERS_ROUTE}`, formData)
                .then((response) => {
                    let parseResponse = { ...response }
                    setUploadSuccess(parseResponse)

                    if (parseResponse?.errors.length > 0) {
                        setUploadErrors(parseResponse?.errors)
                    } else {
                        window.setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    }
                })
                .catch((error) => {
                    let parseError = { ...error }
                    if (parseError?.response?.data?.message) {
                        setErrorMessage(parseError?.response?.data?.message)
                    }
                    if (parseError?.response?.data?.errors) {
                        setUploadErrors(parseError.response.data.errors)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const buttonLogo = '/media/icons/duotune/finance/fin006.svg'

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG path={buttonLogo} className="svg-icon-primary" />{' '}
                    {`Upload Orders`}
                </div>
            </Link>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>Upload Orders</Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <DropzoneField
                        logo={buttonLogo}
                        onDrop={onFrontDrop}
                        onDropRejected={onDropRejected}
                        selectedFile={selectedFile}
                        acceptedFileTypes=".csv"
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a
                            href="https://order-import-doji.sfo2.digitaloceanspaces.com/templates/Doji-Admin-Template-CSV.csv"
                            rel="noreferrer"
                            target="_blank"
                            className="download-template"
                        >
                            Download Spreadsheet Template
                        </a>
                    </div>
                </Modal.Body>
                <div style={{ padding: '0px 5px' }}>
                    {uploadSuccess && (
                        <>
                            <div
                                style={{
                                    borderTop: '1px solid #cecece',
                                    margin: '5px 0px',
                                }}
                            />
                            <div
                                style={{
                                    borderRadius: '0.1rem',
                                    background: '#E8FFF3',
                                    padding: '0px 5px',
                                    color: '#50CD89',
                                }}
                            >
                                <strong>
                                    File uploaded successfully with{' '}
                                    {uploadSuccess.numberOfAsks} ask
                                    {uploadSuccess.numberOfAsks !== 1 &&
                                        's'}{' '}
                                    and {uploadSuccess.numberOfBids} bid
                                    {uploadSuccess.numberOfBids !== 1 &&
                                        's'}{' '}
                                    added.
                                    <br />
                                    {uploadErrors.length} error
                                    {uploadErrors.length !== 1 && 's'} found.
                                </strong>
                            </div>
                        </>
                    )}
                    {uploadErrors.length ? (
                        uploadErrors[0].hasOwnProperty('side') ? (
                            <>
                                <div
                                    style={{
                                        borderTop: '1px solid #cecece',
                                        margin: '5px 0px',
                                    }}
                                />
                                <div
                                    style={{
                                        borderRadius: '0.1rem',
                                        background: '#FFF5F8',
                                        padding: '0px 5px',
                                        color: '#F1416C',
                                    }}
                                >
                                    <ul>
                                        {uploadErrors.map(
                                            (error: uploadError) => {
                                                const errorSKUData =
                                                    error.productId ? (
                                                        <>
                                                            <strong color="#F1416C">
                                                                SKU:
                                                            </strong>{' '}
                                                            {error.productId} |
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                const errorMessageData =
                                                    error.message ? (
                                                        <>
                                                            <strong color="#F1416C">
                                                                {' '}
                                                                Error:
                                                            </strong>{' '}
                                                            {
                                                                error.message
                                                            } |{' '}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                const errorSideData =
                                                    error.side ? (
                                                        <>
                                                            <strong color="#F1416C">
                                                                {' '}
                                                                Side:
                                                            </strong>{' '}
                                                            {error.side}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )

                                                return (
                                                    <li>
                                                        {errorSKUData}
                                                        {errorMessageData}
                                                        {errorSideData}
                                                        <br />
                                                    </li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        borderTop: '1px solid #cecece',
                                        margin: '5px 0px',
                                    }}
                                />
                                <div
                                    style={{
                                        borderRadius: '0.1rem',
                                        background: '#FFF5F8',
                                        padding: '5px',
                                        color: '#F1416C',
                                    }}
                                >
                                    <strong>
                                        Upload failed. {uploadErrors.length}{' '}
                                        error{uploadErrors.length !== 1 && 's'}{' '}
                                        found.
                                        <br />
                                        <p>
                                            {errorMessage
                                                ? errorMessage
                                                : 'Error information:'}
                                        </p>
                                    </strong>

                                    <ul>
                                        {uploadErrors.map(
                                            (error: uploadError) => {
                                                return (
                                                    <li>
                                                        <strong>
                                                            Code {error.code}:{' '}
                                                        </strong>
                                                        {error.error}
                                                        <br />
                                                    </li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </div>
                            </>
                        )
                    ) : (
                        errorMessage && (
                            <>
                                <div
                                    style={{
                                        borderTop: '1px solid #cecece',
                                        margin: '5px 0px',
                                    }}
                                />
                                <div
                                    style={{
                                        borderRadius: '0.1rem',
                                        background: '#FFF5F8',
                                        padding: '0px 5px',
                                        color: '#F1416C',
                                    }}
                                >
                                    <strong style={{ color: '#F1416C' }}>
                                        Error message:
                                    </strong>
                                    <br />
                                    {errorMessage}
                                </div>
                            </>
                        )
                    )}
                    {loading && (
                        <>
                            <div
                                style={{
                                    borderTop: '1px solid #cecece',
                                    margin: '5px 0px',
                                }}
                            />
                            <div
                                style={{
                                    borderRadius: '0.1rem',
                                    padding: '0px 5px',
                                }}
                            >
                                Your file is being processed...
                            </div>
                        </>
                    )}
                </div>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!canUpload}
                        onClick={() => {
                            action(selectedFile)
                        }}
                    >
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
