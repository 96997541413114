import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../../common/style/modal.css'
import {
    RequiredInformationTypeOptions,
    ReturnTypeOptions,
} from '../../../data/Returning'
import './../../style/update.css'
import Select from 'react-select'
import { httpHelper } from '../../../../common/HttpCommon'
import { RETURNING_ROUTE } from '../../../../common/RouteConstants'
import '../../style/create.css'

interface Options {
    value: string
    label: string
}

const CreateButton = () => {
    const [defective, setDefective] = useState(false)
    const { success, danger } = useAlert()
    const [title, setTitle] = useState('')
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [requiredOptions, setRequiredOptions] = useState(Array<Options>())
    const [returnTypeOptions, setReturnTypeOptions] = useState(Array<Options>())
    const [disabled, setDisabled] = useState(true)
    const [invalidTitle, setInvalidTitle] = useState(true)
    const [invalidReturnType, setInvalidReturnType] = useState(true)

    const handleShow = () => {
        setShow(true)
        setDefective(false)
        setTitle('')
        setRequiredOptions(Array<Options>())
        setReturnTypeOptions(Array<Options>())
        setDisabled(true)
        setInvalidTitle(true)
    }

    return (
        <div className="create-button">
            <Link to="#" className="btn btn-primary" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen041.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Create
                </div>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        <strong>Create Return Reason</strong>
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        <b>*Title and return type cannot be empty.</b>
                    </p>
                    <Form.Control
                        type={'text'}
                        placeholder={'Title'}
                        value={title}
                        isInvalid={invalidTitle}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            if (e.target.value !== '') {
                                setInvalidTitle(false)
                                if (!invalidReturnType) setDisabled(false)
                            } else {
                                setInvalidTitle(true)
                                setDisabled(true)
                            }
                        }}
                    />
                    <div
                        className="form-check form-switch form-check-custom form-check-solid"
                        id={'defective-checkbox'}
                    >
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchDefault"
                        >
                            <div className="fw-bold text-muted">Defective</div>
                        </label>
                        <input
                            className="form-check-input h-20px"
                            type="checkbox"
                            defaultChecked={defective}
                            onChange={(e) => {
                                setDefective(e.target.checked)
                            }}
                            id="flexSwitchDefault"
                        />
                    </div>
                    <div className="form-check" id={'active-checkbox'} />

                    <div className="mb-5 col-md-12 mt-3">
                        <label className="form-label fw-bold">
                            {'Required Options'}
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select2"
                                isMulti={true}
                                defaultValue={requiredOptions}
                                options={RequiredInformationTypeOptions}
                                onChange={(e) => {
                                    setRequiredOptions(e as Array<Options>)
                                }}
                            />
                        </div>
                    </div>

                    <div className="mb-5 col-md-12 mt-3">
                        <label className="form-label fw-bold">
                            {'Return Type'}
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select2"
                                isMulti={true}
                                defaultValue={returnTypeOptions}
                                options={ReturnTypeOptions}
                                onChange={(e) => {
                                    setReturnTypeOptions(e as Array<Options>)
                                    if ((e as Array<Options>) && e.length > 0) {
                                        setInvalidReturnType(false)
                                        if (!invalidTitle) setDisabled(false)
                                    } else {
                                        setInvalidReturnType(true)
                                        setDisabled(true)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={disabled}
                        onClick={() => {
                            create().then()
                            handleClose()
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function create() {
        let body = {
            title: title,
            isDefective: defective,
            requiredInformation: requiredOptions.map((option) => option.value),
            acceptedReturnTypes: returnTypeOptions.map(
                (option) => option.value
            ),
        }

        await httpHelper.post(`${RETURNING_ROUTE}`, body).then((response) => {
            if (response === undefined || response === null)
                danger('Error: could not updated returning!')
            else {
                success('Returning was updated!')
                window.setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
        })
    }
}

export default CreateButton
