const getLanguageByCurrency = (currency: String) => {
    switch (currency) {
        case 'GBP':
            return 'en-GB'
        case 'BRL':
            return 'pt-BR'
        default:
            return 'en-GB'
    }
}

export const MonetaryField = (
    number: Number | null | undefined,
    currency: string = 'GBP'
) => {
    if (!number) return <span className="text-dark fw-bold fs-7">-</span>
    return (
        <span className="text-dark fw-bold fs-7">
            {number.toLocaleString(getLanguageByCurrency(currency), {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
            })}
        </span>
    )
}
