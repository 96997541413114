import { Link } from 'react-router-dom'
import { ellipsisLimit } from '../../utils/functions'

interface TableIdFieldProps {
    children?: string
    to?: string
}

export const TableIdField = (props: TableIdFieldProps) => (
    <Link to={props.to || '#'}>
        <span
            className="fw-bold fs-7"
            data-bs-toggle="tooltip"
            data-bs-delay-hide="5000"
            data-bs-placement="top"
            title={props.children}
        >
            {ellipsisLimit(props.children, 8)}
        </span>
    </Link>
)
