import { Label } from './Label'
import { ReturningRequestStatus } from '../../requests/data/ReturningRequest'

type RequestStatusBadgeProps = {
    status: ReturningRequestStatus
}

export const RequestStatusBadge = ({ status }: RequestStatusBadgeProps) => {
    switch (status) {
        case ReturningRequestStatus.COMPLETED:
            return <Label css="badge-success">{status}</Label>

        case ReturningRequestStatus.PENDING_REPAIR:
            return <Label css="badge-success">{status}</Label>

        case ReturningRequestStatus.PENDING_REQUEST_APPROVAL:
            return <Label css="badge-light-warning">{status}</Label>

        case ReturningRequestStatus.PENDING_RETURN_APPROVAL:
            return <Label css="badge-light-dark">{status}</Label>

        case ReturningRequestStatus.PENDING_RETURN_METHOD:
            return <Label css="badge-light-dark">{status}</Label>

        case ReturningRequestStatus.PENDING_RETURN_SHIPPING:
            return <Label css="badge-light-dark">{status}</Label>

        case ReturningRequestStatus.PENDING_SHIPPING_BACK:
            return <Label css="badge-light-dark">{status}</Label>

        case ReturningRequestStatus.REJECTED:
            return <Label css="badge-light-danger">{status}</Label>

        case ReturningRequestStatus.SHIPPING_EXPIRED:
            return <Label css="badge-light-danger">{status}</Label>

        default:
            return <Label css="badge-light-dark">{status}</Label>
    }
}
