import { useDropzone } from 'react-dropzone'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button } from 'react-bootstrap-v5'

interface DropzoneFieldProps {
    onDrop: (arg: any) => void
    onDropRejected: (arg: any) => void
    logo: string
    selectedFile?: File
    acceptedFileTypes: string | string[]
    minSize?: number
    maxSize?: number
}

export const DropzoneField = ({
    onDrop,
    onDropRejected,
    logo,
    selectedFile,
    acceptedFileTypes,
    minSize,
    maxSize,
}: DropzoneFieldProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDrop,
        onDropRejected: onDropRejected,
        accept: acceptedFileTypes,
        minSize: minSize ? minSize : 0,
        maxSize: maxSize ? maxSize : 20000000,
    })

    return (
        <div
            style={{
                minHeight: '100px',
                border: '1px dashed #b6b6b6',
                borderRadius: '0.4rem',
                position: 'relative',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            {...getRootProps()}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    background: 'rgba(0,0,0,0.7)',
                    opacity: `${isDragActive ? '100%' : '0%'}`,
                    transition: 'opacity 0.5s',
                    color: '#ffffff',
                    borderRadius: '0.4rem',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <strong>Drop File here</strong>
            </div>

            <input {...getInputProps()} />

            <KTSVG path={logo} className="svg-icon-primary svg-icon-4x" />

            {!selectedFile ? (
                <>
                    <p>Drag & Drop your file here or</p>
                    <div>
                        <Button>Browse</Button>
                    </div>
                    <p>Accepted file types: {acceptedFileTypes}</p>
                </>
            ) : (
                <>
                    <p style={{ textAlign: 'center' }}>
                        <span>
                            <strong>{selectedFile.name}</strong>
                        </span>
                        <br />
                        <span>was selected</span>
                    </p>
                    <></>
                    <span
                        style={{ textDecoration: 'underline', color: 'blue' }}
                    >
                        Choose different file
                    </span>
                </>
            )}
        </div>
    )
}
