import React from 'react'
interface TextFieldProps {
    children?: React.ReactNode
}

export const TextField = (props: TextFieldProps) => {
    return (
        <span className="fw-bold text-muted fs-7">
            {props.children ? props.children : '-'}
        </span>
    )
}
