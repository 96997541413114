import { httpHelper } from '../../../common/HttpCommon'
import { FRAUD_ANALYSIS_BASE_ROUTE } from '../../../common/RouteConstants'
import Analysis from '../../data/Analysis'
import { FraudAnalysisService } from '../FraudAnalysisService'

export class FraudAnalysisServiceImpl implements FraudAnalysisService {
    async getAnalysis(saleId: string): Promise<Analysis> {
        const request = await httpHelper.get<Analysis>(
            `${FRAUD_ANALYSIS_BASE_ROUTE}?saleId=${saleId}`
        )

        return request
    }

    async createAnalysis(saleId: string): Promise<Analysis> {
        const body = {
            saleId,
        }
        const request = await httpHelper.post<Analysis>(
            `${FRAUD_ANALYSIS_BASE_ROUTE}`,
            body
        )

        return request
    }
}
