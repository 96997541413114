import { Label } from './Label'
import { ReturnType } from '../../data/Returning'

type ReturnTypeBadgeProps = {
    type: ReturnType
}

export const ReturnTypeBadge = ({ type }: ReturnTypeBadgeProps) => {
    switch (type) {
        case ReturnType.EXCHANGE:
            return <Label css="badge-warning">{type}</Label>
        case ReturnType.RESEND:
            return <Label css="badge-secondary">{type}</Label>
        case ReturnType.RETURN:
            return <Label css="badge-light-primary">{type}</Label>
        default:
            return <Label css="badge-light-dark">{type}</Label>
    }
}
