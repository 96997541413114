import { useState } from 'react'
import { DynamicFilterEnum } from './DynamicFilterEnum'
import { DynamicFilterDate } from './DynamicFilterDate'
import { DynamicFilterRemote, ResourceType } from './DynamicFilterRemote'
import { Button, Modal } from 'react-bootstrap-v5'
import { DynamicFilterBoolean } from './DynamicFilterBoolean'
import { DynamicFilterSwitch } from './DynamicFilterSwitch'
import {
    CustomSwitchValues,
    DynamicFilterCustomSwitch,
} from './DynamicFilterCustomSwitch'

export enum Type {
    ENUM = 'ENUM',
    ENUM_MULTI = 'ENUM_MULTI',
    DATE = 'DATE',
    DATE_START = 'DATE_START',
    DATE_END = 'DATE_END',
    TEXT = 'TEXT',
    REMOTE = 'REMOTE',
    BOOLEAN = 'BOOLEAN',
    SWITCH = 'SWITCH',
    CUSTOM_SWITCH = 'CUSTOM_SWITCH',
}

export type Filter = {
    title: string
    queryParam: string
    type: Type
    enumValues?: string[] | null
    defaultValue?: string | null
    termQueryParam?: string | null
    resourceUrl?: string | null
    resourceType?: ResourceType
    customSwitchValues?: CustomSwitchValues
}

export type FilterValue = {
    queryParam: string
    value: string
}

type Props = {
    filters: Filter[]
    onApplyFiltersCallback: (filterValues: FilterValue[]) => void
    onResetFiltersCallback: (filterValues: FilterValue[]) => void
}

export const DynamicFilter = ({
    filters,
    onApplyFiltersCallback,
    onResetFiltersCallback,
}: Props) => {
    let filterValuesInit: { [key: string]: string } = {}

    filters.forEach((f) => {
        if (f.defaultValue) filterValuesInit[f.queryParam] = f.defaultValue
    })

    const [filterValues, setFilterValues] = useState<{ [key: string]: string }>(
        filterValuesInit
    )

    const [show, setShow] = useState<boolean>(false)

    function handleShow() {
        setShow(true)
    }

    function handleClose() {
        setShow(false)
    }

    function onChangeFilter(newValue: string, queryParam: string) {
        filterValues[queryParam] = newValue
        setFilterValues(filterValues)
    }

    function onApplyFilters(e: any) {
        e.preventDefault()
        setShow(false)
        onApplyFiltersCallback(convertFilterValues(filterValues))
    }

    function onResetFilters(e: any) {
        e.preventDefault()
        setShow(false)
        setFilterValues(filterValuesInit)
        onResetFiltersCallback(convertFilterValues(filterValues))
    }

    function convertFilterValues(filterValues: {
        [key: string]: string
    }): FilterValue[] {
        return Object.keys(filterValues).map((key) => {
            return { queryParam: key, value: filterValues[key] }
        })
    }

    return (
        <>
            <Button
                variant={''}
                className="btn btn-sm btn-bg-light btn-active-color-primary"
                onClick={handleShow}
            >
                <i className="bi bi-filter fs-3" /> Filters
            </Button>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Filter options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-7 py-5 row">
                        {filters.map((f) => {
                            if (
                                (f.type === Type.ENUM ||
                                    f.type === Type.ENUM_MULTI) &&
                                f.enumValues != null
                            )
                                return (
                                    <DynamicFilterEnum
                                        onChangeCallback={(newValue) => {
                                            if (newValue === 'EMPTY')
                                                newValue = ''
                                            onChangeFilter(
                                                newValue,
                                                f.queryParam
                                            )
                                        }}
                                        isMulti={f.type === Type.ENUM_MULTI}
                                        title={f.title}
                                        enumValues={f.enumValues}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (f.type === Type.DATE)
                                return (
                                    <DynamicFilterDate
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                `${newValue}T03:00:01.000Z`,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (f.type === Type.DATE_START)
                                return (
                                    <DynamicFilterDate
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                `${newValue}T00:00:00.000Z`,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (f.type === Type.DATE_END)
                                return (
                                    <DynamicFilterDate
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                `${newValue}T23:59:59.999Z`,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (f.type === Type.REMOTE && f.resourceUrl != null)
                                return (
                                    <DynamicFilterRemote
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                newValue,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        resourceUrl={f.resourceUrl}
                                        resourceType={f.resourceType}
                                    />
                                )
                            if (f.type === Type.BOOLEAN)
                                return (
                                    <DynamicFilterBoolean
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                newValue,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (f.type === Type.SWITCH)
                                return (
                                    <DynamicFilterSwitch
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                newValue,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                    />
                                )
                            if (
                                f.type === Type.CUSTOM_SWITCH &&
                                f.customSwitchValues
                            )
                                return (
                                    <DynamicFilterCustomSwitch
                                        onChangeCallback={(newValue) => {
                                            onChangeFilter(
                                                newValue,
                                                f.queryParam
                                            )
                                        }}
                                        title={f.title}
                                        defaultValue={
                                            filterValues[f.queryParam]
                                        }
                                        values={f.customSwitchValues}
                                    />
                                )
                            return <></>
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={onResetFilters}
                        type="reset"
                        className="btn btn-sm btn-white btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                    >
                        Reset
                    </button>

                    <button
                        onClick={onApplyFilters}
                        type="submit"
                        className="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                    >
                        Apply
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
