import React from 'react'
import { Link } from 'react-router-dom'

import { DynamicTable } from '../../common/components/table/DynamicTable'
import { Type } from '../../common/components/table/DynamicFilter'
import Sale from '../data/Sale'
import Moment from 'react-moment'
import { InstitutionalComponent } from '../../order/component/label/InstitutionalComponent'
import {
    LOST_PARCELS_ROUTE_V2,
    SALE_ROUTE_V2,
} from '../../common/RouteConstants'
import { KTSVG } from '../../../../_metronic/helpers'
import { TableIdField } from '../../common/components/table/TableIdField'
import { TableLinkField } from '../../common/components/table/TableLinkField'
import { ellipsisLimit } from '../../common/utils/functions'
import { TableTextField } from '../../common/components/table/TableTextField'
import { TableBoldTextField } from '../../common/components/table/TableBoldTextField'
import { TableCurrencyField } from '../../common/components/table/TableCurrencyField'
import { DownloadCsvButton } from '../../common/components/table/DownloadCsvButton'
import { TableActionsMenu } from '../../common/components/table/TableActionsButton'
import AcceptGradeButton from '../component/buttons/AcceptGradeButton'
import BuyerToCollectedButton from '../component/buttons/buyer/BuyerToCollectedButton'
import BuyerToDeliveredButton from '../component/buttons/buyer/BuyerToDeliveredButton'
import DownloadBuyerInvoice from '../component/buttons/buyer/DownloadBuyerInvoice'
import DownloadBuyerLabel from '../component/buttons/buyer/DownloadBuyerLabel'
import CancelButton from '../component/buttons/CancelButton'
import DecoupleButton from '../component/buttons/DecoupleButton'
import DownloadSellerInvoice from '../component/buttons/seller/DownloadSellerInvoice'
import DownloadSellerLabel from '../component/buttons/seller/DownloadSellerLabel'
import SellerToCollectedButton from '../component/buttons/seller/SellerToCollectedButton'
import SellerToDeliveredButton from '../component/buttons/seller/SellerToDeliveredButton'
import SellerToFailedButton from '../component/buttons/seller/SellerToFailedButton'
import ToggleFlagButtonButton from '../component/buttons/ToggleFlagButton'
import { BuyerStatus } from '../component/label/BuyerStatus'
import { ProductCondition } from '../component/label/Condition'
import { SellerStatus } from '../component/label/SellerStatus'
import { ActionColumnContainer } from '../../common/components/table/ActionsColumnContainer'

const createdColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableTextField>Created at</TableTextField>
            <TableBoldTextField>
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </TableBoldTextField>
        </>
    )
}

const idColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <TableIdField to={`/sale/${data.saleId}/summary`}>
            {data.saleId}
        </TableIdField>
    )
}

const sellerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.sellerDetail?.userId}/summary`}>
                {ellipsisLimit(data.sellerDetail?.fullName, 30)}
                {InstitutionalComponent(data.isFromInstitutional)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.sellerDetail?.email, 35)}
            </TableBoldTextField>
            <SellerStatus status={data.sellerStatus} />
        </>
    )
}

const buyerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.buyerDetail?.userId}/summary`}>
                {ellipsisLimit(data.buyerDetail?.fullName, 30)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.buyerDetail?.email, 35)}
            </TableBoldTextField>
            <BuyerStatus status={data.buyerStatus} />
        </>
    )
}

const productColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <div className="symbol symbol-45px me-5">
                <img src={data?.productDetail?.thumbnail?.url} alt="" />
            </div>
            <div className="d-flex justify-content-start flex-column">
                <TableBoldTextField>
                    {data?.productDetail?.title}
                </TableBoldTextField>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    <ProductCondition
                        status={data.productDetail?.grade.description}
                    />
                </span>
                <TableCurrencyField
                    price={data.price}
                    currency={data.currency}
                />
            </div>
        </div>
    )
}
const ActionColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <ActionColumnContainer>
            <DecoupleButton sale={data} />
            <CancelButton sale={data} />
            <AcceptGradeButton sale={data} />
            <BuyerToCollectedButton
                sale={data}
                title={'Mark Buyer as Collected'}
            />
            <BuyerToDeliveredButton
                sale={data}
                title={'Mark Buyer as Delivered'}
            />
            <SellerToCollectedButton
                sale={data}
                title={'Mark Seller as Collected'}
            />
            <SellerToDeliveredButton
                sale={data}
                title={'Mark Seller as Delivered'}
            />
            <SellerToFailedButton sale={data} />
            <DownloadBuyerLabel sale={data} />
            <DownloadSellerLabel sale={data} />
            <DownloadBuyerInvoice sale={data} />
            <DownloadSellerInvoice sale={data} />
            <ToggleFlagButtonButton sale={data} />
        </ActionColumnContainer>
    )
}

const columns = [
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Seller',
        render: sellerColumn,
    },
    {
        header: 'Buyer',
        render: buyerColumn,
    },
    {
        header: 'Product',
        render: productColumn,
    },
    {
        header: 'Action',
        render: ActionColumn,
    },
]
const LostParcels: React.FC = () => (
    <>
        <TableActionsMenu>
            <DownloadCsvButton />
        </TableActionsMenu>
        <DynamicTable
            title={'Sale'}
            description={'List of sales with lost parcel warning'}
            resource={LOST_PARCELS_ROUTE_V2}
            columns={columns}
            filters={[]}
        />
    </>
)

export { LostParcels, columns }
