import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/dark.css'
import moment from 'moment-timezone'

type Props = {
    title: string
    defaultValue?: string
    onChangeCallback: (newValue: string) => void
}

export const DynamicFilterDate = ({
    title,
    defaultValue,
    onChangeCallback,
}: Props) => {
    function onChange(date: any, dateStr: any) {
        onChangeCallback(dateStr)
    }

    const value = defaultValue
        ? moment(defaultValue).tz('Europe, London').utc().format('YYYY-MM-DD')
        : undefined

    return (
        <div className="mb-5 col-md-6">
            <label className="form-label fw-bold">{title}:</label>
            <div>
                <Flatpickr
                    value={value}
                    onChange={onChange}
                    options={{ static: true }}
                    className="form-control form-control-solid"
                />
            </div>
        </div>
    )
}
