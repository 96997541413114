import { getDojiEnv } from '../Env'
import { colours } from './style/colours'

const dojiEnv = getDojiEnv()

const envs = {
    dev: 'DEVELOPMENT',
    prod: 'PRODUCTION',
}

const environments = {
    development: {
        backgroundColor: colours.purple,
        favicon: 'favicon-staging.ico',
    },
    production: {
        backgroundColor: colours.green,
        favicon: 'favicon-prod.ico',
    },
}

export const dojiEnvVariables =
    dojiEnv === envs.prod ? environments.production : environments.development
