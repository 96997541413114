import React from 'react'
import ReactJson from 'react-json-view'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { Label } from '../../../returning/component/badge/Label'
import Analysis from '../../../fraudanalysis/data/Analysis'
import { DataField } from '../../../common/components/fields/DataField'

export function FraudAnalysisView(
    fraudAnalysis: Analysis | undefined
): Array<CustomRow> {
    const jsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' },
    }

    return [
        {
            column: [
                {
                    title: 'Code',
                    content: fraudAnalysis?.saleId ?? 'NOT ANALYZED',
                },
                {
                    title: 'Status',
                    content: fraudAnalysis?.status ?? '',
                },
            ],
        },
        {
            column: [
                {
                    title: 'Score',
                    content: fraudAnalysis?.score ?? '',
                },
                {
                    title: 'Provider',
                    content: (
                        <Label css="badge-light-success">
                            {fraudAnalysis?.provider ?? ''}
                        </Label>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Created',
                    content: DataField(fraudAnalysis?.createdAt),
                },
                {
                    title: 'Updated',
                    content: DataField(fraudAnalysis?.updatedAt),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Sent Log',
                    content: fraudAnalysis?.providerSentJson ? (
                        <ReactJson
                            src={JSON.parse(fraudAnalysis?.providerSentJson)}
                            collapsed={false}
                        />
                    ) : (
                        ''
                    ),
                },
                {
                    title: '',
                    content: '',
                },
            ],
        },
    ]
}
