import { CustomRow } from '../../../common/components/data/CardUtil'
import { BoldTextField } from '../../../common/components/fields/BoldTextField'
import { BooleanField } from '../../../common/components/fields/BooleanField'
import { DateField } from '../../../common/components/fields/DateField'
import { TextField } from '../../../common/components/fields/TextField'
import IUser from '../../data/User'
import { KycStatusBadge } from '../badges/KycStatusbadge'

export function SummaryView(user: IUser): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push(
        {
            column: [
                {
                    title: 'First Name',
                    content: <BoldTextField>{user.firstName}</BoldTextField>,
                },
                {
                    title: 'Last Name',
                    content: <BoldTextField>{user.lastName}</BoldTextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'User Id',
                    content: <TextField>{user.userId}</TextField>,
                },
                {
                    title: 'Email',
                    content: <BoldTextField>{user.email}</BoldTextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Status',
                    content: <TextField>{user.status}</TextField>,
                },
                {
                    title: 'Institutional',
                    content: <BooleanField condition={user.institutional} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Phone Number',
                    content: <BoldTextField>{user.phoneNumber}</BoldTextField>,
                },
                {
                    title: 'Last Login',
                    content: <DateField date={user.lastLogin} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'KYC Status',
                    content: <KycStatusBadge status={user.kycStatus} />,
                },
                {
                    title: 'Fee',
                    content: <TextField>{user.standardFee}%</TextField>,
                },
            ],
        }
    )
    return renderRows
}
