export enum SellerStatus {
    PENDING_CHECKOUT = 'PENDING_CHECKOUT',
    PENDING_BUYER_CHECKOUT = 'PENDING_BUYER_CHECKOUT',
    PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
    PENDING_PAYMENT_3DS = 'PENDING_PAYMENT_3DS',
    PAYMENT_REJECTED = 'PAYMENT_REJECTED',
    CHECKOUT_EXPIRED = 'CHECKOUT_EXPIRED',
    CHECKOUT_3DS_EXPIRED = 'CHECKOUT_3DS_EXPIRED',
    CHECKOUT_CANCELLED = 'CHECKOUT_CANCELLED',
    WAITING_SHIPMENT_INFO = 'WAITING_SHIPMENT_INFO',
    WAITING_SHIPMENT_COLLECTION = 'WAITING_SHIPMENT_COLLECTION',
    WAITING_SHIPMENT_DROPOFF = 'WAITING_SHIPMENT_DROPOFF',
    FAILED_SHIPMENT_DROPOFF = 'FAILED_SHIPMENT_DROPOFF',
    FAILED_SHIPMENT_COLLECTION = 'FAILED_SHIPMENT_COLLECTION',
    IN_TRANSIT = 'IN_TRANSIT',
    GRADE_VERIFICATION = 'GRADE_VERIFICATION',
    COMPLETED = 'COMPLETED',
    GRADE_REJECTED = 'GRADE_REJECTED',
    DELIVERY_EXPIRED = 'DELIVERY_EXPIRED',
    CANCELLED = 'CANCELLED',
    DECOUPLED = 'DECOUPLED',
}
