import { Link } from 'react-router-dom'
import { useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'

interface RejectActionButtonProps {
    buttonText: string
    modalTitle: string
    modalText: string
    action: (description: string) => Promise<void>
}

export const RejectActionButton = ({
    buttonText,
    modalTitle,
    modalText,
    action,
}: RejectActionButtonProps) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [description, setDescription] = useState<string>('')
    const handleShow = () => {
        setShow(true)
    }
    const handleConfirm = () => {
        handleClose()
        action(description)
    }

    return (
        <>
            <div className="menu-item px-3">
                <Link to="#" className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/arrows/arr015.svg'}
                            className="svg-icon-1 svg-icon-danger"
                        />{' '}
                        {buttonText}
                    </div>
                </Link>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>{modalTitle}</Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{modalText}</p>
                        <Form.Control
                            type={'text'}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleConfirm}
                            disabled={!description}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
