import React from 'react'
import Moment from 'react-moment'

interface DateFieldProps {
    date: Date
}
export const DateField = (props: DateFieldProps) => {
    return (
        <span className="fw-bold text-muted fs-7">
            {props.date ? (
                <Moment format="YYYY-MM-DD HH:mm">{props.date}</Moment>
            ) : (
                '-'
            )}
        </span>
    )
}
