import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../_metronic/helpers'

const CancelDescriptionModal = (props: {
    show: Boolean
    onClose: () => void
    onConfirm: (cancelReason: string, description: string) => void
}) => {
    const [disabledButton, setDisabledButton] = useState(true)
    const [description, setDescription] = useState<string>('')
    const [cancelReason, setCancelReason] = useState<string>('')
    useEffect(() => {
        if (cancelReason !== '' && description !== '') {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [cancelReason, description])

    useEffect(() => {
        setDisabledButton(true)
        setDescription('')
    }, [props.show])

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton className={'modal-header'}>
                <Modal.Title>
                    You are about to <strong>cancel</strong> a sale
                </Modal.Title>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                    onClick={props.onClose}
                    aria-label="Close"
                >
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                </div>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Please <strong>specify reason</strong> for sale
                    cancellation.
                </p>
                <Form.Control
                    size="lg"
                    // defaultValue="INTERNAL"
                    as={Form.Select}
                    value={cancelReason}
                    onChange={(e) => {
                        setCancelReason(e.target.value)
                    }}
                >
                    <option value="">Select a reason...</option>
                    <option value="REQUESTED_BUYER">Requested by Buyer</option>
                    <option value="REQUESTED_SELLER">
                        Requested by Seller
                    </option>
                    <option value="DELIVERY_EXPIRED">Delivery Expired</option>
                    <option value="FRAUD_SUSPICION">Suspected Fraud</option>
                    <option value="INTERNAL">Custom reason</option>
                </Form.Control>
                <br />
                <Form.Control
                    type={'text'}
                    placeholder={'Description'}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => props.onConfirm(cancelReason, description)}
                    disabled={disabledButton}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelDescriptionModal
