import { ReturnMethod, ReturnType } from '../../data/Returning'
import { ReturningReason } from '../../reason/data/ReturningReason'

export enum ReturningRequestAction {
    APPROVE_REQUEST = 'APPROVE_REQUEST',
    REJECT_REQUEST = 'REJECT_REQUEST',
    APPROVE_RETURN = 'APPROVE_RETURN',
    REJECT_RETURN = 'REJECT_RETURN',
    CONFIRM_RETURN_METHOD = 'CONFIRM_RETURN_METHOD',
    CONFIRM_REPAIR = 'CONFIRM_REPAIR',
    REJECT_REPAIR = 'REJECT_REPAIR',
    CHARGE_REFUND_FROM_SELLER = 'CHARGE_REFUND_FROM_SELLER',
    COMPLETE_REQUEST = 'COMPLETE_REQUEST',
    CONFIRM_BUYER_SHIPMENT_DETAILS = 'CONFIRM_BUYER_SHIPMENT_DETAILS',
}

export enum ReturningRequestStatus {
    PENDING_REQUEST_APPROVAL = 'PENDING_REQUEST_APPROVAL',
    REJECTED = 'REJECTED',
    PENDING_RETURN_SHIPPING = 'PENDING_RETURN_SHIPPING',
    SHIPPING_EXPIRED = 'SHIPPING_EXPIRED',
    PENDING_RETURN_APPROVAL = 'PENDING_RETURN_APPROVAL',
    PENDING_RETURN_METHOD = 'PENDING_RETURN_METHOD',
    PENDING_REPAIR = 'PENDING_REPAIR',
    PENDING_SHIPPING_BACK = 'PENDING_SHIPPING_BACK',
    PENDING_REFUND = 'PENDING_REFUND',
    COMPLETED = 'COMPLETED',
    PENDING_BUYER_SHIPMENT_DETAILS = 'PENDING_BUYER_SHIPMENT_DETAILS',
}
interface User {
    userId: string
    email: string
    firstName: string
    lastName: string
    institutional: false
    phoneNumber: string //TODO: create phone number type
}

interface RejectDetails {
    description: string
}

export enum SellerChargedStatus {
    CHARGED = 'CHARGED',
    NOT_CHARGED = 'NOT_CHARGED',
}

export enum RefundStatus {
    PENDING_REFUND_PAYMENT = 'PENDING_REFUND_PAYMENT',
    PENDING_REFUND_TRANSACTION = 'PENDING_REFUND_TRANSACTION',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}
export interface BuyerShipmentDetails {
    fee: number
    type: string
    shipmentId: string
    address: Address
    addressId: string
    deliveryOptionId: string
}

interface Address {
    street: string
    locality: string
    town: string
    country: string
    postCode: string
    countryCode: string
}

interface Refund {
    refundId: string
    requestId: string
    currency: string
    buyerTotal: number
    sellerTotal: number
    saleId: string
    sellerId: string
    status: RefundStatus
    sellerChargedStatus: SellerChargedStatus
}

interface ReturningRequestImage {
    imageId: string
    type: string //TODO: map types
    userId: string
    url: string
}

export interface IReturningRequest {
    requestId: string
    returningReason: ReturningReason
    userId: string
    saleId: string
    returnMethod: ReturnMethod
    currentStatus: ReturningRequestStatus
    statusHistory: ReturningRequestStatus[]
    type: ReturnType
    textDescription: string
    images: ReturningRequestImage[]
    created: Date
    updated: Date
    user: User
    refund?: Refund
    rejectDetails?: RejectDetails
    getStatusUpdateActions(): ReturningRequestAction[]
    userHasConfirmedAddress(): boolean
    buyerShipmentDetails?: BuyerShipmentDetails
}
export class ReturningRequest implements IReturningRequest {
    requestId: string
    returningReason: ReturningReason
    userId: string
    saleId: string
    returnMethod: ReturnMethod
    currentStatus: ReturningRequestStatus
    statusHistory: ReturningRequestStatus[]
    type: ReturnType
    textDescription: string
    images: ReturningRequestImage[]
    created: Date
    updated: Date
    user: User
    refund?: Refund | undefined
    rejectDetails?: RejectDetails | undefined
    buyerShipmentDetails?: BuyerShipmentDetails

    constructor(request: IReturningRequest) {
        this.requestId = request.requestId
        this.returningReason = request.returningReason
        this.userId = request.userId
        this.saleId = request.saleId
        this.returnMethod = request.returnMethod
        this.currentStatus = request.currentStatus
        this.statusHistory = request.statusHistory
        this.type = request.type
        this.textDescription = request.textDescription
        this.images = request.images
        this.created = request.created
        this.updated = request.updated
        this.user = request.user
        this.refund = request.refund
        this.rejectDetails = request.rejectDetails
        this.buyerShipmentDetails = request.buyerShipmentDetails
    }

    getStatusUpdateActions(): ReturningRequestAction[] {
        const actions: ReturningRequestAction[] = []
        const status = this.currentStatus
        switch (status) {
            case ReturningRequestStatus.PENDING_REQUEST_APPROVAL:
                actions.push(ReturningRequestAction.APPROVE_REQUEST)
                actions.push(ReturningRequestAction.REJECT_REQUEST)
                break
            case ReturningRequestStatus.PENDING_RETURN_APPROVAL:
                actions.push(ReturningRequestAction.APPROVE_RETURN)
                actions.push(ReturningRequestAction.REJECT_RETURN)
                break
            case ReturningRequestStatus.PENDING_RETURN_SHIPPING:
                actions.push(ReturningRequestAction.APPROVE_RETURN)
                actions.push(ReturningRequestAction.REJECT_RETURN)
                break
            case ReturningRequestStatus.PENDING_RETURN_METHOD:
                actions.push(ReturningRequestAction.CONFIRM_RETURN_METHOD)
                break
            case ReturningRequestStatus.PENDING_REPAIR:
                actions.push(ReturningRequestAction.CONFIRM_REPAIR)
                actions.push(ReturningRequestAction.REJECT_REPAIR)
                break
            case ReturningRequestStatus.PENDING_SHIPPING_BACK:
                actions.push(ReturningRequestAction.COMPLETE_REQUEST)
                break
            case ReturningRequestStatus.PENDING_BUYER_SHIPMENT_DETAILS:
                actions.push(
                    ReturningRequestAction.CONFIRM_BUYER_SHIPMENT_DETAILS
                )
                break
            case ReturningRequestStatus.COMPLETED:
                // Note: this is commented for now, while we fix some problems on the "Charge Refund From Seller"
                // if (this.refund?.sellerChargedStatus === SellerChargedStatus.NOT_CHARGED) {
                //   actions.push(ReturningRequestAction.CHARGE_REFUND_FROM_SELLER);
                // }
                break
        }
        return actions
    }

    userHasConfirmedAddress(): boolean {
        const completedStatuses = this.statusHistory.slice(0, -1)
        return completedStatuses.includes(
            ReturningRequestStatus.PENDING_BUYER_SHIPMENT_DETAILS
        )
    }
}
