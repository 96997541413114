import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Sale from '../../../data/Sale'
import { isSellerWaitingShipment } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { httpHelper } from '../../../../common/HttpCommon'
import { SHIPMENT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../../data/SpecificSale'
import './../../../../common/style/modal.css'
import { Button, Form, Modal } from 'react-bootstrap-v5'

const IncrementShipmentDaysButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const [disabledButton, setDisabledButton] = useState(false)
    const [days, setDays] = useState('1')
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        setDisabledButton(false)
        setDays('1')
    }

    if (!isSellerWaitingShipment(sale)) return <></>

    return (
        <>
            <div className="menu-item px-3">
                <Link to="#" className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/general/gen035.svg'}
                            className="svg-icon-1"
                        />{' '}
                        Shipment Days
                    </div>
                </Link>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>
                            You are about to <strong>increment shipment</strong>{' '}
                            in business days
                        </Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Please enter with{' '}
                            <strong>numbers of extra days</strong> (remember
                            that will be calculated using only business days)
                        </p>
                        <Form.Control
                            type={'number'}
                            placeholder={'Days'}
                            value={days}
                            onChange={(e) => {
                                setDays(e.target.value)
                                if (
                                    e.target.value === '' ||
                                    Number(e.target.value) < 1
                                )
                                    setDisabledButton(true)
                                else setDisabledButton(false)
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                update().then()
                                handleClose()
                            }}
                            disabled={disabledButton}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

    async function update() {
        let trackingNumber = ''

        if (sale.sellerShipmentDetails?.postLocationDetails)
            trackingNumber =
                sale.sellerShipmentDetails.postLocationDetails.shipmentId

        await httpHelper
            .put(
                `${SHIPMENT_ROUTE}/${trackingNumber}/increment-expiration?days=${days}`
            )
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could not update sale!')
                else {
                    success(
                        `Expiration Date incremented in ${days} day${
                            Number(days) > 1 ? 's' : ''
                        }`
                    )
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default IncrementShipmentDaysButton
