import { CustomRow } from '../../../../common/components/data/CardUtil'
import { BoldTextField } from '../../../../common/components/fields/BoldTextField'
import { BooleanField } from '../../../../common/components/fields/BooleanField'
import { TextField } from '../../../../common/components/fields/TextField'
import { ReturningRequest } from '../../data/ReturningRequest'

export function UserView(request: ReturningRequest): Array<CustomRow> {
    const { email, firstName, institutional, lastName, phoneNumber, userId } =
        request.user

    let renderRows: Array<CustomRow> = []

    renderRows.push(
        {
            column: [
                {
                    title: 'User ID',
                    content: <BoldTextField>{userId}</BoldTextField>,
                },
                {
                    title: 'Institutional',
                    content: <BooleanField condition={institutional} />,
                },
            ],
        },
        {
            column: [
                {
                    title: 'First name',
                    content: <TextField>{firstName}</TextField>,
                },
                {
                    title: 'last name',
                    content: <TextField>{lastName}</TextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Email',
                    content: <TextField>{email}</TextField>,
                },
                {
                    title: 'Phone number',
                    content: <TextField>{phoneNumber}</TextField>,
                },
            ],
        },
        {
            column: [
                {
                    title: 'Confirmed Address',
                    content: (
                        <BooleanField
                            condition={request.userHasConfirmedAddress()}
                        />
                    ),
                },
                {
                    title: '',
                    content: <div />,
                },
            ],
        }
    )
    return renderRows
}
