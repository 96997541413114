import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { httpHelper } from '../../../../common/HttpCommon'
import { RETURNING_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Button, Modal } from 'react-bootstrap-v5'
import './../../../../common/style/modal.css'
import Returning from '../../../data/Returning'

const DisableButton = (props: { returning: Returning }) => {
    const returning = props.returning
    const { success, danger } = useAlert()
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const handleShow = () => {
        setShow(true)
    }

    if (!returning.active) return <></>

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen042.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Inactive
                </div>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        <strong>Inactive</strong> #{returning.title}
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            disable(returning.returningReasonId).then()
                            handleClose()
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function disable(returningReasonId: string) {
        let body = {
            active: false,
        }

        await httpHelper
            .put(`${RETURNING_ROUTE}/${returningReasonId}`, undefined, body)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could not updated returning!')
                else {
                    success('The return has been inactivated!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default DisableButton
