import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { CustomRow } from '../../../common/components/data/CardUtil'
import { DynamicTable } from '../../../common/components/table/DynamicTable'
import { WALLETS_ROUTE } from '../../../common/RouteConstants'
import { CurrencyWallet } from '../../data/CurrencyWallet'
import IUser from '../../data/User'

const createdColumn = (data: CurrencyWallet) => {
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Created at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </span>
        </div>
    )
}
const updatedColumn = (data: CurrencyWallet) => {
    return (
        <div>
            <span className="text-muted fw-bold d-block fs-8">Updated at</span>
            <span className="text-dark fw-bolder d-block fs-7">
                <Moment format="DD/MM/YYYY HH:mm">{data.updated}</Moment>
            </span>
        </div>
    )
}

const idColumn = (data: CurrencyWallet) => {
    return (
        <Link to={`#`}>
            <span
                className="fw-bold"
                data-bs-toggle="tooltip"
                data-bs-delay-hide="5000"
                data-bs-placement="top"
                title={data.id}
            >
                {data?.id}
            </span>
        </Link>
    )
}

const availableBalanceColumn = (data: CurrencyWallet) => (
    <span className="text-dark fw-bolder fs-5">
        {data.availableBalance}{' '}
        <span className="text-muted fw-bold">{'  ' + data.currency}</span>
    </span>
)
const lockedBalanceColumn = (data: CurrencyWallet) => (
    <span className="text-dark fw-bolder fs-5">
        {data.lockedBalance}{' '}
        <span className="text-muted fw-bold">{'  ' + data.currency}</span>
    </span>
)
const totalBalanceColumn = (data: CurrencyWallet) => (
    <span className="text-dark fw-bolder fs-5">
        {data.totalBalance}{' '}
        <span className="text-muted fw-bold">{'  ' + data.currency}</span>
    </span>
)
const columns = [
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Available Balance',
        render: availableBalanceColumn,
    },
    {
        header: 'Locked Balance',
        render: lockedBalanceColumn,
    },
    {
        header: 'Total Balance',
        render: totalBalanceColumn,
    },
    {
        header: 'Updated',
        render: updatedColumn,
    },
]

export function WalletsView(
    user: IUser,
    currentScreen: string
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    renderRows.push({
        column: [
            {
                title: '',
                content: (
                    <DynamicTable
                        title="Currency wallets"
                        description="List of wallets"
                        resource={`${WALLETS_ROUTE}/?userId=${user?.userId}`}
                        columns={columns}
                        filters={[]}
                        currentScreen={currentScreen}
                    />
                ),
            },
        ],
    })
    return renderRows
}
