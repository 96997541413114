import { ReactChild, ReactChildren } from 'react'
import { KycStatus } from '../../data/User'

type KycStatusBadgeProps = {
    status: KycStatus
}

export const KycStatusBadge = ({ status }: KycStatusBadgeProps) => {
    switch (status) {
        case KycStatus.NOT_VERIFIED:
            return <Label css="badge-warning">{status}</Label>
        case KycStatus.VERIFIED:
            return <Label css="badge-light-success">{status}</Label>
        default:
            return <Label css="badge-light-primary">{status}</Label>
    }
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => <span className={`badge ${css} fs-7 fw-bolder me-3`}>{children}</span>
