import React, { FC, useEffect, useState } from 'react'
import { httpHelper } from '../../common/HttpCommon'
import { EVENT_ROUTE } from '../../common/RouteConstants'
import SpecificEvent from '../data/SpecificEvent'
import { DynamicCard } from '../../common/components/card/DynamicCard'
import { CustomRow, MenuItem } from '../../common/components/data/CardUtil'
import { BooleanField } from '../../common/components/fields/BooleanField'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import useBackofficeUser, {
    getUserPermissions,
} from '../../common/secure/useBackofficeUser'
import { menuRules } from '../data/MenuRules'

export let specificEvent: SpecificEvent | null = null

const EventView: FC = () => {
    const [event, setEvent] = useState<SpecificEvent[]>(Array<SpecificEvent>())
    const addressPage = window.location.pathname.split('/')
    const currentSubMenu = addressPage[addressPage.length - 1].toString()
    const eventId = addressPage[2]
    const [rows, setRows] = useState<CustomRow[]>(Array<CustomRow>())
    const pathname = window.location.pathname
        .replace('/summary', '')
        .replace('/notification', '')
        .replace('/push', '')
        .replace('/email', '')
        .replace('/sms', '')
    const menuItems: Array<MenuItem> = menuRules(event[0], pathname, false)
    const { backofficeUser } = useBackofficeUser()
    const userPermissions = getUserPermissions(backofficeUser)

    async function fetchData() {
        // TODO: move this api call to a service
        await httpHelper
            .get<SpecificEvent>(`${EVENT_ROUTE}/${eventId}`)
            .then((resp: SpecificEvent) => {
                setEvent([resp])
                specificEvent = resp
                setRows(getRows(resp))
            })
    }

    useEffect(() => {
        fetchData().then()
    }, [])
    useEffect(() => {
        setRows(getRows(event[0]))
    }, [currentSubMenu])

    return (
        <>
            <DynamicCard
                title={'Event'}
                subtitle={`#${eventId}`}
                rows={rows}
                menuItems={menuItems}
                address={currentSubMenu}
                buttons={editButton()}
            />
        </>
    )

    function getRows(event: SpecificEvent) {
        switch (currentSubMenu) {
            case 'summary':
                return SummaryView(event)
            case 'notification':
                return notificationRows(event)
            case 'push':
                return pushRows(event)
            case 'email':
                return emailRows(event)
            case 'sms':
                return smsRows(event)
            default:
                return []
        }
    }

    function editButton() {
        let canEdit = false
        userPermissions.forEach((permission) => {
            if (permission === 'PUT') canEdit = true
        })

        if (event === undefined || !canEdit) return undefined

        return [
            <div className="menu-item px-3">
                <Link
                    to={`/event-edit/${eventId}/${currentSubMenu}`}
                    id={''}
                    className={'menu-link px-3'}
                >
                    <KTSVG
                        path={'/media/icons/duotune/general/gen055.svg'}
                        className="svg-icon-1"
                    />{' '}
                    EDIT
                </Link>
            </div>,
        ]
    }
}

function smsRows(event: SpecificEvent | undefined) {
    let renderRows: Array<CustomRow> = []
    if (
        event === undefined ||
        event.configuration === null ||
        event.configuration.smsConfiguration === null
    )
        return renderRows

    renderRows.push({
        column: [
            {
                title: 'Body',
                content: (
                    <span className="fw-bolder fs-6">
                        {event.configuration.smsConfiguration.message}
                    </span>
                ),
            },
        ],
    })

    return renderRows
}

function emailRows(event: SpecificEvent | undefined) {
    let renderRows: Array<CustomRow> = []

    if (
        event === undefined ||
        event.configuration === null ||
        event.configuration.emailConfiguration === null
    )
        return renderRows
    renderRows.push({
        column: [
            {
                title: 'Title',
                content: (
                    <span className="fw-bolder fs-6">
                        {event.configuration.emailConfiguration.subject}
                    </span>
                ),
            },
            {
                title: 'Is Html',
                content: (
                    <BooleanField
                        condition={event.configuration.emailConfiguration.html}
                    />
                ),
            },
        ],
    })
    if (event.configuration.emailConfiguration.bbc !== null)
        event.configuration.emailConfiguration.bbc.forEach((bbc) => {
            renderRows.push({
                column: [
                    {
                        title: 'BCC',
                        content: bbc.email,
                    },
                    bbc.alias
                        ? {
                              title: 'Alias',
                              content: bbc.alias,
                          }
                        : { title: '', content: '' },
                ],
            })
        })
    else
        renderRows.push({
            column: [
                {
                    title: 'BCC',
                    content: 'Empty',
                },
                { title: '', content: '' },
            ],
        })
    renderRows.push({
        column: [
            {
                title: 'Body',
                content: (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `<br><br>${event.configuration?.emailConfiguration?.body}`,
                        }}
                    />
                ),
            },
        ],
    })
    return renderRows
}

function pushRows(event: SpecificEvent | undefined) {
    let renderRows: Array<CustomRow> = []
    if (
        event === undefined ||
        event.configuration === null ||
        event.configuration.pushConfiguration === null
    )
        return renderRows

    renderRows.push(
        {
            column: [
                {
                    title: 'Title',
                    content: (
                        <span className="fw-bolder fs-6">
                            {event.configuration.pushConfiguration.title}
                        </span>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Body',
                    content: (
                        <span className="fw-bolder fs-6">
                            {event.configuration.pushConfiguration.body}
                        </span>
                    ),
                },
            ],
        }
    )

    return renderRows
}

function notificationRows(event: SpecificEvent | undefined) {
    let renderRows: Array<CustomRow> = []
    if (
        event === undefined ||
        event.configuration === null ||
        event.configuration.notificationConfiguration === null
    )
        return renderRows

    renderRows.push(
        {
            column: [
                {
                    title: 'Title',
                    content: (
                        <span className="fw-bolder fs-6">
                            {
                                event.configuration.notificationConfiguration
                                    .title
                            }
                        </span>
                    ),
                },
            ],
        },
        {
            column: [
                {
                    title: 'Body',
                    content: (
                        <span className="fw-bolder fs-6">
                            {event.configuration.notificationConfiguration.body}
                        </span>
                    ),
                },
            ],
        }
    )

    return renderRows
}

function SummaryView(event: SpecificEvent | undefined) {
    let renderRows: Array<CustomRow> = []
    if (event === undefined) return renderRows

    renderRows.push({
        column: [
            { title: 'Topic', content: event.topic },
            { title: 'Type', content: event.type },
        ],
    })
    if (event.configuration) {
        renderRows.push(
            {
                column: [
                    {
                        title: 'Configured',
                        content: <BooleanField condition={event.configured} />,
                    },
                    event.configuration
                        ? {
                              title: 'Send Interval (minutes)',
                              content:
                                  event.configuration.sendingIntervalInMinutes,
                          }
                        : { title: '', content: '' },
                ],
            },
            {
                column: [
                    {
                        title: 'Enabled',
                        content: <BooleanField condition={!event.disabled} />,
                    },
                    {
                        title: 'Send Push',
                        content: (
                            <BooleanField
                                condition={event.configuration?.sendPush}
                            />
                        ),
                    },
                ],
            },
            {
                column: [
                    {
                        title: 'Send Email',
                        content: (
                            <BooleanField
                                condition={event.configuration?.sendEmail}
                            />
                        ),
                    },
                    {
                        title: 'Send SMS',
                        content: (
                            <BooleanField
                                condition={event.configuration?.sendSms}
                            />
                        ),
                    },
                ],
            }
        )
    }
    return renderRows
}

export default EventView
