import { IProduct } from '../../data/Product'

type Props = {
    product: IProduct
}
export const ProductTags = ({ product }: Props) => {
    return (
        <>
            {product.tags.map((tag) => (
                <>
                    <span
                        style={{ marginRight: '15px' }}
                        className={`badge badge-light-success fs-7 fw-bolder`}
                    >
                        {tag}
                    </span>
                </>
            ))}
        </>
    )
}
