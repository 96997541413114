import React from 'react'
import { Link } from 'react-router-dom'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import Decouple from '../data/Decouple'
import Moment from 'react-moment'
import { Type } from '../../common/components/table/DynamicFilter'

const Decouples: React.FC = () => {
    const idColumn = (data: Decouple) => {
        return (
            <Link to={`decouple/${data.decoupleId}/summary`}>
                <span className="fw-bold">
                    {data.decoupleId.substr(0, 8)}..
                </span>
            </Link>
        )
    }

    const createdColumn = (data: Decouple) => {
        return (
            <div>
                <span className="text-muted fw-bold d-block fs-8">
                    Created at
                </span>
                <span className="text-dark fw-bolder d-block fs-7">
                    <Moment format="YYYY-MM-DD HH:mm">{data.created}</Moment>
                </span>
            </div>
        )
    }

    const rootSaleColumn = (data: Decouple) => {
        return (
            <Link to={`/sale/${data.rootSaleId}/summary`}>
                <span className="fw-bold">{data.rootSaleId}</span>
            </Link>
        )
    }

    const buyerSaleColumn = (data: Decouple) => {
        return (
            <Link to={`/sale/${data.newBuyerSaleId}/summary`}>
                <span className="fw-bold">{data.newBuyerSaleId}</span>
            </Link>
        )
    }

    const sellerSaleColumn = (data: Decouple) => {
        return (
            <Link to={`/sale/${data.newSellerSaleId}/summary`}>
                <span className="fw-bold">{data.newSellerSaleId}</span>
            </Link>
        )
    }

    const columns = [
        {
            header: 'Created',
            render: createdColumn,
        },
        {
            header: 'Id',
            render: idColumn,
        },
        {
            header: 'Root Sale',
            render: rootSaleColumn,
        },
        {
            header: 'Buyer Sale',
            render: buyerSaleColumn,
        },
        {
            header: 'Seller Sale',
            render: sellerSaleColumn,
        },
    ]

    const filters = [
        {
            title: '',
            queryParam: '',
            type: Type.ENUM,
        },
    ]

    return (
        <>
            <DynamicTable
                title="Order"
                description="List of orders"
                columns={columns}
                filters={filters}
                resource="admin/v1/decouple"
            />
        </>
    )
}
export { Decouples }
