import Sale from '../../../data/Sale'
import SpecificSale from '../../../data/SpecificSale'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { downloadFile } from '../../../../common/utils/DownloadFile'
import { SALE_ROUTE } from '../../../../common/RouteConstants'
import { isSellerCompleted } from '../../../data/ButtonsRules'

const DownloadSellerInvoice = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    if (!isSellerCompleted(sale)) return <></>

    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    const download = () => {
        const url = `${SALE_ROUTE}/${sale.saleId}/invoice?side=SELL`
        const filename = `invoice-seller-${sale.saleId}.pdf`
        downloadFile(url, filename, options)
    }

    return (
        <div className="menu-item px-3">
            <div className="menu-link px-md-4" onClick={download}>
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/files/fil017.svg'}
                        className="svg-icon-1"
                    />{' '}
                    Seller Invoice
                </div>
            </div>
        </div>
    )
}

export default DownloadSellerInvoice
