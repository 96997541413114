import React, { useState } from 'react'
import 'flatpickr/dist/themes/dark.css'

type Props = {
    title: string
    defaultValue?: string
    values: CustomSwitchValues
    onChangeCallback: (newValue: string) => void
}

export type CustomSwitchValues = {
    active: string
    notActive: string
}
export type CustomSwitchStatus = 'active' | 'notActive'

export const DynamicFilterCustomSwitch = ({
    title,
    defaultValue = '',
    values,
    onChangeCallback,
}: Props) => {
    const [value, setValue] = useState<string>(defaultValue)
    const onChange = () => {
        const newValue =
            value === values.active ? values.notActive : values.active
        onChangeCallback(newValue)
        setValue(newValue)
    }

    return (
        <div className="form-check form-switch form-check-custom form-check-solid">
            <label className="form-check-label" htmlFor="flexSwitchDefault">
                <div className="fw-bold text-muted">{title}</div>
            </label>
            <input
                className="form-check-input h-20px"
                type="checkbox"
                defaultChecked={defaultValue === values.active}
                onChange={onChange}
                id="flexSwitchDefault"
            />
        </div>
    )
}
