import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Sale from '../../../data/Sale'
import { isUpdatedProduct } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { httpHelper } from '../../../../common/HttpCommon'
import { UPDATE_PRODUCT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../../data/SpecificSale'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import './../../../../common/style/modal.css'

const UpdateProductButton = (props: { sale: Sale | SpecificSale }) => {
    const sale = props.sale
    const [disabledButton, setDisabledButton] = useState(true)
    const [description, setDescription] = useState('')
    const [productId, setProductId] = useState('')
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        setDisabledButton(true)
        setProductId('')
        setDescription('')
    }

    if (!isUpdatedProduct(sale)) return <></>

    return (
        <>
            <div className="menu-item px-3">
                <Link to="#" className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/general/gen055.svg'}
                            className="svg-icon-1"
                        />{' '}
                        Update Product
                    </div>
                </Link>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>
                            You are about to <strong>update</strong> product
                        </Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Control
                            type={'text'}
                            placeholder={'New Product id'}
                            value={productId}
                            onChange={(e) => {
                                setProductId(e.target.value)
                                if (e.target.value === '')
                                    setDisabledButton(true)
                                else setDisabledButton(false)
                            }}
                        />
                        <Form.Control
                            type={'text'}
                            placeholder={'Description'}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                update(sale.saleId).then()
                                handleClose()
                            }}
                            disabled={disabledButton}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

    async function update(saleId: string) {
        let body = {}

        if (description === '')
            body = {
                productId: productId,
            }
        else
            body = {
                productId: productId,
                description: description,
            }

        await httpHelper
            .put(`${UPDATE_PRODUCT_ROUTE}/${saleId}`, undefined, body)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: Could not updated product!')
                else {
                    success('The product has been updated!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default UpdateProductButton
