import { ReturnType } from '../../data/Returning'
import { ReturnTypeBadge } from './ReturnTypeBadge'

type ReturnTypeBadgesListProps = {
    types: ReturnType[]
}

export const ReturnTypeBadgesList = ({ types }: ReturnTypeBadgesListProps) => {
    return (
        <>
            {types.map((t) => (
                <ReturnTypeBadge type={t} />
            ))}
        </>
    )
}
