import Moment from 'react-moment'
import { KTSVG } from '../../../../../_metronic/helpers'
import { MonetaryField } from '../../../common/components/fields/MonetaryField'
import { TableBoldTextField } from '../../../common/components/table/TableBoldTextField'
import { TableIdField } from '../../../common/components/table/TableIdField'
import { TableLinkField } from '../../../common/components/table/TableLinkField'
import { TableTextField } from '../../../common/components/table/TableTextField'
import { ellipsisLimit } from '../../../common/utils/functions'
import { InstitutionalComponent } from '../../../order/component/label/InstitutionalComponent'
import { BuyerStatus } from '../../../sale/component/label/BuyerStatus'
import { SellerStatus } from '../../../sale/component/label/SellerStatus'
import Sale from '../../../sale/data/Sale'

const createdColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableTextField>Created at</TableTextField>
            <TableBoldTextField>
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </TableBoldTextField>
        </>
    )
}

const idColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <TableIdField to={`/sale/${data.saleId}/summary`}>
            {data.saleId}
        </TableIdField>
    )
}

const flagColumn = (data: Sale) => {
    if (![data.buyerStatus, data.sellerStatus].includes('IN_TRANSIT'))
        return <></>
    const flagged =
        !!data.buyerShipmentDetails?.shipment?.lostParcelWarning &&
        [data.buyerStatus, data.sellerStatus].includes('IN_TRANSIT')
    if (!flagged) return <></>
    return (
        <span
            className="fw-bold"
            data-bs-toggle="tooltip"
            data-bs-delay-hide="1000"
            data-bs-placement="top"
            title="Parcel suspected to be lost"
        >
            <KTSVG path={`/media/flags/red_flag.svg`} className="svg-icon-1" />
        </span>
    )
}

const sellerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.sellerDetail?.userId}/summary`}>
                {ellipsisLimit(data.sellerDetail?.fullName, 30)}
                {InstitutionalComponent(data.isFromInstitutional)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.sellerDetail?.email, 35)}
            </TableBoldTextField>
            <SellerStatus status={data.sellerStatus} />
        </>
    )
}

const buyerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.buyerDetail?.userId}/summary`}>
                {ellipsisLimit(data.buyerDetail?.fullName, 30)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.buyerDetail?.email, 35)}
            </TableBoldTextField>
            <BuyerStatus status={data.buyerStatus} />
        </>
    )
}

const productColumn = (data: Sale) => {
    if (!data) return <></>
    return MonetaryField(data.amount, data.currency)
}

export const saleColumns = [
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Seller',
        render: sellerColumn,
    },
    {
        header: 'Buyer',
        render: buyerColumn,
    },
    {
        header: 'Amount',
        render: productColumn,
    },
    {
        header: '',
        render: flagColumn,
    },
]
