import Moment from 'react-moment'
import React from 'react'

export const DataField = (data: string | null | undefined) => {
    if (data === null || data === undefined)
        return <span className="fw-bold text-muted fs-7">-</span>
    return (
        <span className="fw-bold text-muted fs-7">
            <Moment format="DD/MM/YYYY HH:mm:ss">{data}</Moment>
        </span>
    )
}
