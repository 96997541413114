import { useState } from 'react'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { Modal, Button } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import IUser from '../../data/User'
import { UserServiceImpl } from '../../service/rest/UserServiceImpl'
interface UpdateUserInstitutionalButtonProps {
    user: IUser
}

export const UpdateUserInstitutionalButton = ({
    user,
}: UpdateUserInstitutionalButtonProps) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
    }
    const { success, danger } = useAlert()
    const userService = new UserServiceImpl()

    async function action() {
        const response = await userService.updateUserInstitutional(
            user.userId,
            !user.institutional
        )
        if (response) {
            success('User type updated!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not update User type!')
        }
    }

    const currentType = user.institutional ? 'Institutional' : 'Individual'
    const newType = user.institutional ? 'Individual' : 'Institutional'
    const buttonLogo = user.institutional
        ? '/media/icons/duotune/communication/com006.svg'
        : '/media/svg/others/building.svg'

    return (
        <div className="menu-item px-3">
            <Link to="#" className="menu-link px-3" onClick={handleShow}>
                <div>
                    <KTSVG path={buttonLogo} className="svg-icon-primary" />{' '}
                    {`Set to ${newType}`}
                </div>
            </Link>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        You are about to change the User type
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        The User type is currently{' '}
                        <strong>{currentType}</strong>. Are you sure you want to
                        change it to <strong>{newType}</strong>?
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleClose()
                            action()
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
