import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useIntl } from "react-intl";
import { useMenuButtonsMapper, MenuItemWithSub, MenuItemProps } from "../../../../app/backoffice/common/MenuRoutes";
import { useLocation } from "react-router-dom";

export function MenuInner() {
  const intl = useIntl();
  const { getMenuButtons } = useMenuButtonsMapper();
  const menuButtons = getMenuButtons();
  const {pathname} = useLocation()
  const currentMenu = '/' + pathname?.split('/')?.[1]

  function menuRender(menuItemWithSub: MenuItemWithSub) {
    const active = menuItemWithSub?.subItems?.map((m: MenuItemProps) => m.to)?.includes(currentMenu)

    return (
      <MenuInnerWithSub
        title={menuItemWithSub.title}
        to={menuItemWithSub.to ? menuItemWithSub.to : "/crafted"}
        menuPlacement="bottom-start"
        menuTrigger="click"
        active={active}
      >
        {menuItemWithSub.subItems.map((menuItem: MenuItemProps) => (
          <MenuItem
            title={menuItem.title}
            to={menuItem.to}
            icon={menuItem.icon}
          />
        ))}
      </MenuInnerWithSub>
    );
  }

  return (
    <>
      <MenuItem
        title="Search"
        to="/search"
      />
      {/* <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      /> */}
      <MenuItem
        title='Users'
        to="/user"
      />
      <MenuItem
        title='Products'
        to="/product"
      />
      {menuButtons.map((m) => menuRender(m))}
    </>
  );
}
