import { Link } from 'react-router-dom'
import React from 'react'
import Sale from '../../../data/Sale'
import { isPaymentApproval } from '../../../data/ButtonsRules'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { httpHelper } from '../../../../common/HttpCommon'
import { APPROVAL_PAYMENT_ROUTE } from '../../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificSale from '../../../data/SpecificSale'

const ApprovePaymentButton = (props: {
    sale: Sale | SpecificSale
    title?: string
}) => {
    const sale = props.sale
    const title = props.title
    const { success, danger } = useAlert()

    if (!isPaymentApproval(sale)) return <></>

    return (
        <div className="menu-item px-3">
            <Link
                to="#"
                className="menu-link px-3"
                onClick={() => {
                    approve(sale.saleId).then()
                }}
            >
                <div>
                    <KTSVG
                        path={'/media/icons/duotune/general/gen037.svg'}
                        className="svg-icon-1 me-1"
                    />
                    {title || 'Approve Payment'}
                </div>
            </Link>
        </div>
    )

    async function approve(saleId: string) {
        await httpHelper
            .put(`${APPROVAL_PAYMENT_ROUTE}/${saleId}`)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: could approve sale!')
                else {
                    success('Sale approval executed!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default ApprovePaymentButton
