import { useAlert } from 'react-bootstrap-hooks-alert'
import { IReturningRequest } from '../../requests/data/ReturningRequest'
import { ReturningRequestServiceImpl } from '../../service/rest/ReturningRequestServiceImpl'
import { ApproveActionButton } from '../../../common/components/button/ApproveActionButton'
interface CompleteRequestButtonProps {
    request: IReturningRequest
}

export const CompleteRequestButton = ({
    request,
}: CompleteRequestButtonProps) => {
    const returningRequestService = new ReturningRequestServiceImpl()
    const { success, danger } = useAlert()

    async function complete() {
        const response = await returningRequestService.completeRequest(
            request.requestId
        )
        if (response) {
            success('Request completed!')
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            danger('Error: could not complete the request!')
        }
    }

    return (
        <ApproveActionButton
            buttonText="Complete"
            modalTitle="You are about to complete this Request"
            modalText="Are you sure you want to complete this request?"
            action={complete}
        />
    )
}
