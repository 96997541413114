import { useState } from 'react'
import { httpHelper } from '../../../common/HttpCommon'
import { ORDER_ROUTE } from '../../../common/RouteConstants'
import { CancelAllOrdersProps, OrderService } from '../OrderService'
type RequestStatus = 'SUCCESS' | 'ERROR' | 'INITIAL' | 'TIMEOUT'

export class OrderServiceImpl implements OrderService {
    cancelAllOrders(props: CancelAllOrdersProps): Promise<string> {
        const params = new URLSearchParams(`sides=${props.side}`)
        return httpHelper.delete<string>(ORDER_ROUTE, params, undefined, {
            'user-id': props.userId,
        })
    }
}

export const useOrderService = () => {
    const orderService = new OrderServiceImpl()
    const [loading, setLoading] = useState(false)
    const [requestStatus, setRequestStatus] = useState<RequestStatus>('INITIAL')
    const cancelAllOrders = async (
        props: CancelAllOrdersProps
    ): Promise<string> => {
        setLoading(true)
        setRequestStatus('INITIAL')
        try {
            const response = await orderService.cancelAllOrders(props)
            setRequestStatus('SUCCESS')
            return response
        } catch (e: any) {
            if (e?.response?.status === 504) setRequestStatus('TIMEOUT')
            else setRequestStatus('ERROR')
            throw e
        } finally {
            setLoading(false)
        }
    }

    return { cancelAllOrders, loading, requestStatus }
}
