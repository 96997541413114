import React from 'react'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import { Type } from '../../common/components/table/DynamicFilter'
import Sale from '../data/Sale'
import Moment from 'react-moment'
import { SELLER_STATUS, SellerStatus } from './label/SellerStatus'
import { BUYER_STATUS, BuyerStatus } from './label/BuyerStatus'
import DecoupleButton from './buttons/DecoupleButton'
import CancelButton from './buttons/CancelButton'
import ForceCompleteSale from './buttons/ForceCompleteSale'
import DownloadSellerLabel from './buttons/seller/DownloadSellerLabel'
import DownloadBuyerLabel from './buttons/buyer/DownloadBuyerLabel'
import { ProductCondition } from './label/Condition'
import { InstitutionalComponent } from '../../order/component/label/InstitutionalComponent'
import BuyerToDeliveredButton from './buttons/buyer/BuyerToDeliveredButton'
import BuyerToCollectedButton from './buttons/buyer/BuyerToCollectedButton'
import SellerToCollectedButton from './buttons/seller/SellerToCollectedButton'
import SellerToDeliveredButton from './buttons/seller/SellerToDeliveredButton'
import AcceptGradeButton from './buttons/AcceptGradeButton'
import SellerToFailedButton from './buttons/seller/SellerToFailedButton'
import { SALE_ROUTE_V2 } from '../../common/RouteConstants'
import DownloadBuyerInvoice from './buttons/buyer/DownloadBuyerInvoice'
import DownloadSellerInvoice from './buttons/seller/DownloadSellerInvoice'
import { KTSVG } from '../../../../_metronic/helpers'
import { TableIdField } from '../../common/components/table/TableIdField'
import { TableLinkField } from '../../common/components/table/TableLinkField'
import { ellipsisLimit } from '../../common/utils/functions'
import { TableTextField } from '../../common/components/table/TableTextField'
import { TableBoldTextField } from '../../common/components/table/TableBoldTextField'
import { TableCurrencyField } from '../../common/components/table/TableCurrencyField'
import ToggleFlagButton from './buttons/ToggleFlagButton'
import { DownloadCsvButton } from '../../common/components/table/DownloadCsvButton'
import { TableActionsMenu } from '../../common/components/table/TableActionsButton'
import { ActionColumnContainer } from '../../common/components/table/ActionsColumnContainer'
import { ResourceType } from '../../common/components/table/DynamicFilterRemote'
import RejectGradeButton from './buttons/RejectGradeButton'

const createdColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableTextField>Created at</TableTextField>
            <TableBoldTextField>
                <Moment format="DD/MM/YYYY HH:mm">{data.created}</Moment>
            </TableBoldTextField>
        </>
    )
}

const idColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <TableIdField to={`/sale/${data.saleId}/summary`}>
            {data.saleId}
        </TableIdField>
    )
}

const flagColumn = (data: Sale) => {
    if (![data.buyerStatus, data.sellerStatus].includes('IN_TRANSIT'))
        return <></>
    const flagged =
        !!data.buyerShipmentDetails?.shipment?.lostParcelWarning &&
        [data.buyerStatus, data.sellerStatus].includes('IN_TRANSIT')
    if (!flagged) return <></>
    return (
        <span
            className="fw-bold"
            data-bs-toggle="tooltip"
            data-bs-delay-hide="1000"
            data-bs-placement="top"
            title="Parcel suspected to be lost"
        >
            <KTSVG path={`/media/flags/red_flag.svg`} className="svg-icon-1" />
        </span>
    )
}

const sellerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.sellerDetail?.userId}/summary`}>
                {ellipsisLimit(data.sellerDetail?.fullName, 30)}
                {InstitutionalComponent(data.isFromInstitutional)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.sellerDetail?.email, 35)}
            </TableBoldTextField>
            <SellerStatus status={data.sellerStatus} />
        </>
    )
}

const buyerColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <>
            <TableLinkField to={`/user/${data.buyerDetail?.userId}/summary`}>
                {ellipsisLimit(data.buyerDetail?.fullName, 30)}
            </TableLinkField>
            <TableBoldTextField>
                {ellipsisLimit(data.buyerDetail?.email, 35)}
            </TableBoldTextField>
            <BuyerStatus status={data.buyerStatus} />
        </>
    )
}

const productColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <div className="d-flex align-items-center">
            <div className="symbol symbol-45px me-5">
                <img src={data?.productDetail?.thumbnail?.url} alt="" />
            </div>
            <div className="d-flex justify-content-start flex-column">
                <TableBoldTextField>
                    {data?.productDetail?.title}
                </TableBoldTextField>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                    <ProductCondition
                        status={data.productDetail?.grade.description}
                    />
                </span>
                <TableCurrencyField
                    price={data.price}
                    currency={data.currency}
                />
            </div>
        </div>
    )
}
const ActionColumn = (data: Sale) => {
    if (!data) return <></>
    return (
        <ActionColumnContainer>
            <DecoupleButton sale={data} />
            <CancelButton sale={data} />
            <ForceCompleteSale sale={data} />
            <RejectGradeButton sale={data} />
            <AcceptGradeButton sale={data} />
            <BuyerToCollectedButton
                sale={data}
                title={'Mark Buyer as Collected'}
            />
            <BuyerToDeliveredButton
                sale={data}
                title={'Mark Buyer as Delivered'}
            />
            <SellerToCollectedButton
                sale={data}
                title={'Mark Seller as Collected'}
            />
            <SellerToDeliveredButton
                sale={data}
                title={'Mark Seller as Delivered'}
            />
            <SellerToFailedButton sale={data} />
            <DownloadBuyerLabel sale={data} />
            <DownloadSellerLabel sale={data} />
            <DownloadBuyerInvoice sale={data} />
            <DownloadSellerInvoice sale={data} />
            <ToggleFlagButton sale={data} />
        </ActionColumnContainer>
    )
}

const saleColumns = [
    {
        header: 'Created',
        render: createdColumn,
    },
    {
        header: 'Id',
        render: idColumn,
    },
    {
        header: 'Seller',
        render: sellerColumn,
    },
    {
        header: 'Buyer',
        render: buyerColumn,
    },
    {
        header: 'Product',
        render: productColumn,
    },
    {
        header: '',
        render: flagColumn,
    },
    {
        header: 'Action',
        render: ActionColumn,
    },
]
const filters = [
    {
        title: 'Created from',
        queryParam: 'startDate',
        type: Type.DATE_START,
    },
    {
        title: 'Created to',
        queryParam: 'endDate',
        type: Type.DATE_END,
    },
    // {
    //     title: 'Updated from',
    //     queryParam: 'updated',
    //     type: Type.DATE,
    // },
    // {
    //     title: 'Updated to',
    //     queryParam: 'updated',
    //     type: Type.DATE,
    // },
    {
        title: 'Seller status',
        queryParam: 'sellerStatuses',
        type: Type.ENUM_MULTI,
        enumValues: SELLER_STATUS,
    },
    {
        title: 'Buyer status',
        queryParam: 'buyerStatuses',
        type: Type.ENUM_MULTI,
        enumValues: BUYER_STATUS,
    },
    {
        title: 'Seller',
        queryParam: 'sellerId',
        type: Type.REMOTE,
        resourceUrl: '/admin/v1/users/term',
    },
    {
        title: 'Buyer',
        queryParam: 'buyerId',
        type: Type.REMOTE,
        resourceUrl: '/admin/v1/users/term',
    },
    {
        title: 'Product',
        queryParam: 'productId',
        type: Type.REMOTE,
        resourceUrl: `public/v1/indexed-products/search`,
        resourceType: 'PRODUCT' as ResourceType,
    },
    {
        title: 'Is from Institutional',
        queryParam: 'isFromInstitutional',
        type: Type.BOOLEAN,
    },
]
const Sales: React.FC = () => (
    <>
        <TableActionsMenu>
            <DownloadCsvButton />
        </TableActionsMenu>
        <DynamicTable
            title={'Sale'}
            description={'List of sales'}
            resource={SALE_ROUTE_V2}
            columns={saleColumns}
            filters={filters}
        />
    </>
)

export { Sales, saleColumns, filters }
