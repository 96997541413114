import { CustomRow } from '../../common/components/data/CardUtil'
import SpecificSale from '../data/SpecificSale'
import { SummaryView } from './view/SummaryView'
import { ProductView } from './view/ProductView'
import { PaymentView } from './view/PaymentView'
import { TransactionsView } from './view/TransactionsView'
import { ChangesHistoryView } from './view/ChangesHistoryView'
import { DecoupleView } from './view/DecouplesView'
import { FraudAnalysisView } from './view/FraudAnalysisView'
import Analysis from '../../fraudanalysis/data/Analysis'
import PaymentDetails from '../data/PaymentDetails'
import { ShipmentView } from './view/ShipmentView'

export function getRows(
    currentPage: string,
    sale: SpecificSale | undefined,
    fraudAnalysis: Analysis | undefined,
    paymentDetails: PaymentDetails | undefined
): Array<CustomRow> {
    let renderRows: Array<CustomRow> = []

    if (!sale) return renderRows

    switch (currentPage) {
        case 'summary':
            return SummaryView(sale, paymentDetails)
        case 'product':
            return ProductView(sale)
        case 'payment':
            return PaymentView(sale, paymentDetails)
        case 'shipments':
            return ShipmentView(sale)
        case 'transactions':
            return TransactionsView(sale)
        case 'changes-history':
            return ChangesHistoryView(sale)
        case 'decouple':
            return DecoupleView(sale, currentPage)
        case 'fraud-analysis':
            return FraudAnalysisView(fraudAnalysis)
    }
    return renderRows
}
