import { Link } from 'react-router-dom'
import { BooleanField } from '../../common/components/fields/BooleanField'
import { DynamicTable } from '../../common/components/table/DynamicTable'
import { TableBoldTextField } from '../../common/components/table/TableBoldTextField'
import { TableIdField } from '../../common/components/table/TableIdField'
import { TableTextField } from '../../common/components/table/TableTextField'
import { USER_ROUTE } from '../../common/RouteConstants'
import IUser from '../data/User'
import UpdateTransactionFeeButton from './buttons/UpdateTransactionFeeButton'
import { useHistory } from 'react-router-dom'
import { DynamicSearch } from '../../common/components/search/DynamicSearch'
import { httpHelper } from '../../common/HttpCommon'
import RestResponse from '../../common/RestResponse'
import { FormatOptionLabel, ValueOptionLabel } from './UserSearchLabels'

const idColumn = (data: IUser) => (
    <TableIdField to={`/user/${data.userId}/summary`}>
        {data.userId}
    </TableIdField>
)

const userColumn = (data: IUser) => (
    <>
        <TableTextField>Name</TableTextField>
        <TableBoldTextField>{`${data.firstName} ${data.lastName}`}</TableBoldTextField>
    </>
)

const emailColumn = (data: IUser) => (
    <>
        <TableTextField>Email</TableTextField>
        <TableBoldTextField>{data.email}</TableBoldTextField>
    </>
)

const feeColumn = (data: IUser) => (
    <>
        <TableBoldTextField>
            {data.standardFee ? `${data.standardFee}%` : '-'}
        </TableBoldTextField>
    </>
)

const phoneNumberColumn = (data: IUser) => (
    <>
        <TableTextField>Phone Number</TableTextField>
        <TableBoldTextField>{data.phoneNumber}</TableBoldTextField>
    </>
)

const institutionalColumn = (data: IUser) => (
    <BooleanField condition={data.institutional} />
)

const actionColumn = (user: IUser) => {
    if (!user.standardFee) return <></>
    return (
        <>
            <Link
                to={'#'}
                className="btn btn-bg-white btn-sm show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
            >
                <span>
                    <i className="bi bi-three-dots fs-5" />
                </span>
            </Link>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4"
                data-kt-menu="true"
            >
                <UpdateTransactionFeeButton user={user} />
            </div>
        </>
    )
}

export const userColumns = [
    {
        header: 'User ID',
        render: idColumn,
    },
    {
        header: 'User',
        render: userColumn,
    },
    {
        header: 'Email',
        render: emailColumn,
    },
    {
        header: 'Phone Number',
        render: phoneNumberColumn,
    },
    {
        header: 'Standard Fee',
        render: feeColumn,
    },
    {
        header: 'Institutional',
        render: institutionalColumn,
    },
]

export const Users = () => {
    const history = useHistory()

    const loadOptions = async (inputValue: string) => {
        const data = await httpHelper.get<RestResponse<IUser[]>>(
            `${USER_ROUTE}/term?term=${inputValue}`
        )
        return data
    }

    const handleSelect = (user: IUser) => {
        history.push(`/user/${user.userId}/summary`)
    }

    return (
        <>
            <DynamicSearch
                title="User"
                subtitle="Search by name or email"
                fetchData={loadOptions}
                formatOptionLabel={FormatOptionLabel}
                ValueOptionLabel={ValueOptionLabel}
                onSelect={handleSelect}
            />
            <DynamicTable
                description={'List of users'}
                resource={USER_ROUTE}
                columns={userColumns}
                filters={[]}
            />
        </>
    )
}
