import Select from 'react-select'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useUserPreferences } from '../../Preferences'

interface Option {
    value: string
    label: any
}
const options: Option[] = [
    {
        value: 'GB',
        label: <KTSVG path="/media/flags/united-kingdom.svg" />,
    },
    {
        value: 'BR',
        label: <KTSVG path="/media/flags/brazil.svg" />,
    },
]

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#eef3f7',
        height: '25px',
        borderColor: '#eef3f7',
    }),
}

const LocationSelector = () => {
    const { location, setLocation } = useUserPreferences()
    const getDefaultValue = () => {
        return (
            options.find((option) => option.value === location.country) ||
            options[0]
        )
    }
    const handleChange = (e: any) => {
        setLocation(e.value)
        window.setTimeout(() => {
            window.location.reload()
        }, 300)
    }
    return (
        <Select
            isMulti={false}
            options={options}
            defaultValue={getDefaultValue()}
            styles={customStyles}
            onChange={handleChange}
        />
    )
}

export { LocationSelector }
