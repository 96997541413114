import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { httpHelper } from '../../../common/HttpCommon'
import { ORDER_ROUTE } from '../../../common/RouteConstants'
import { useAlert } from 'react-bootstrap-hooks-alert'
import SpecificOrder, { OrderStatus } from '../../data/SpecificOrder'
import './style/cancel.css'
import Order from '../../data/Order'
import { Button, Modal } from 'react-bootstrap-v5'
import './../../../common/style/modal.css'

const CancelButton = (props: {
    order: SpecificOrder | Order
    isMenu: boolean
}) => {
    const order = props.order
    const isMenu = props.isMenu
    const { success, danger } = useAlert()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
    }

    if (order.status !== OrderStatus.OPEN) return <></>

    let buttonClass = 'btn btn-primary'
    let buttonId = 'cancel-button'

    if (isMenu) {
        buttonId = ''
        buttonClass = 'menu-link px-3'
    }

    return (
        <div className="menu-item px-3">
            <Link
                to="#"
                id={buttonId}
                className={buttonClass}
                onClick={handleShow}
            >
                {isMenu ? (
                    <KTSVG
                        path={'/media/icons/duotune/general/gen027.svg'}
                        className="svg-icon-1"
                    />
                ) : (
                    <> </>
                )}{' '}
                Cancel
            </Link>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'modal-header'}>
                    <Modal.Title>
                        You are about to <strong>cancel</strong> an order
                    </Modal.Title>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                        onClick={handleClose}
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            cancel(order.orderId).then()
                            handleClose()
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    async function cancel(orderId: string) {
        await httpHelper
            .delete(`${ORDER_ROUTE}/${orderId}`)
            .then((response) => {
                if (response === undefined || response === null)
                    danger('Error: Could not cancel order!')
                else {
                    success('Order cancelled!')
                    window.setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
}

export default CancelButton
