import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAlert } from 'react-bootstrap-hooks-alert'
import { IProduct } from '../../data/Product'
import Creatable from 'react-select/creatable'
import { ProductServiceImpl } from '../../service/rest/ProductServiceImpl'
import { update, updateRequest } from '../../service/ProductService'

interface EditProductButtonProps {
    product: IProduct | undefined
}
const EditProductButton = ({ product }: EditProductButtonProps) => {
    const productService = new ProductServiceImpl()

    const [disabledButton, setDisabledButton] = useState(false)
    const { success, danger } = useAlert()
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState<Option[]>([])
    const [tags, setTags] = useState<Option[]>([])

    const handleClose = () => setShow(false)
    const handleShow = () => {
        const tagsList =
            product?.tags != null
                ? product!.tags.map((tag: string) => createOption(tag))
                : []
        setTags(tagsList)
        setShow(true)
        setDisabledButton(false)
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#eef3f7',
            minHeight: '45px',
            borderColor: '#eef3f7',
        }),
    }

    interface Option {
        readonly label: string
        readonly value: string
    }

    const createOption = (label: string) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    })

    const handleCreate = (inputValue: string) => {
        setIsLoading(true)
        const newOption = createOption(inputValue)
        setOptions((prev) => [...prev, newOption])
        setTags([...tags, newOption])
        setIsLoading(false)
    }

    const onChange = (tags: any) => {
        setTags(tags)
    }

    async function editProduct() {
        try {
            const updateData: updateRequest = {
                tags: tags.map((tag: Option) => {
                    return tag.value
                }),
            }
            productService.update(product!.productId, updateData)
            window.setTimeout(() => {
                window.location.reload()
            }, 1500)
            success(`Edit product was successful!`)
        } catch {
            danger('Could not edit product')
        }
    }
    return (
        <>
            <div className="menu-item px-3">
                <Link to="#" className="menu-link px-3" onClick={handleShow}>
                    <div>
                        <KTSVG
                            path={'/media/icons/duotune/art/art005.svg'}
                            className="svg-icon-primary"
                        />{' '}
                        Edit Product
                    </div>
                </Link>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className={'modal-header'}>
                        <Modal.Title>Edit Product</Modal.Title>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2 modal-close"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Tags:</p>
                        <Creatable
                            isMulti
                            isClearable
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            onChange={onChange}
                            onCreateOption={handleCreate}
                            options={options}
                            value={tags}
                            styles={customStyles}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                editProduct()
                            }}
                            disabled={disabledButton}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default EditProductButton
