import { ReactChild, ReactChildren } from 'react'
import { OrderSide, OrderStatus, OrderType } from '../../data/SpecificOrder'

type Props = {
    status: OrderStatus
}
type PropsSide = {
    side: OrderSide
}
type PropsType = {
    type: OrderType
}

export const OrderStatusComponent = ({ status }: Props) => {
    let statusComp = null

    switch (status) {
        case OrderStatus.OPEN:
            statusComp = <Label css="badge-light-primary">{status}</Label>
            break
        case OrderStatus.FILLED:
            statusComp = <Label css="badge-success">{status}</Label>
            break
        case OrderStatus.PARTIAL_FILLED:
            statusComp = <Label css="badge-light-success">{status}</Label>
            break
        case OrderStatus.REJECTED:
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case OrderStatus.CANCELLED:
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case OrderStatus.EXPIRED:
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case OrderStatus.CHECKOUT_CANCELLED:
            statusComp = <Label css="badge-light-danger">{status}</Label>
            break
        case OrderStatus.CHECKOUT_EXPIRED:
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        case OrderStatus.DELIVERY_EXPIRED:
            statusComp = <Label css="badge-light-warning">{status}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{status}</Label>
            break
        }
    }

    return <>{statusComp}</>
}

const Label = ({
    children,
    css,
}: {
    children: ReactChild | ReactChildren
    css: string
}) => {
    return (
        <>
            <span className={`badge ${css} fs-7 fw-bolder`}>{children}</span>
        </>
    )
}

export const OrderTypeComponent = ({ type }: PropsType) => {
    let statusComp = null

    switch (type) {
        case OrderType.MARKET:
            statusComp = <Label css="badge-light-primary">{type}</Label>
            break
        case OrderType.LIMIT:
            statusComp = <Label css="badge-light-info">{type}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{type}</Label>
            break
        }
    }
    return <>{statusComp}</>
}

export const OrderSideComponent = ({ side }: PropsSide) => {
    let statusComp = null

    switch (side) {
        case OrderSide.BID:
            statusComp = <Label css="badge-light-success">{side}</Label>
            break
        case OrderSide.ASK:
            statusComp = <Label css="badge-light-primary">{side}</Label>
            break
        default: {
            statusComp = <Label css="badge-light-dark">{side}</Label>
            break
        }
    }
    return <>{statusComp}</>
}
